@import "./variable.scss";
/*mixins declared below*/
//425 px
@mixin xxsMobile {
  @media (max-width: #{$xxs-min-width}) {
    @content;
  }
}
//475px
@mixin smMobile {
  @media (max-width: #{$xs-min-width}) {
    @content;
  }
}
@mixin above991 {
  @media (min-width: #{$md-min-width}) {
    @content;
  }
}
//576px
@mixin midmobile {
  @media (max-width: #{$xxs-max-width}) {
    @content;
  }
}
@mixin smallmobile {
  @media (max-width: #{$xxxs-max-width}) {
    @content;
  }
}
// upTo 767px breakpoint
@mixin mobile {
  @media (max-width: #{$xs-max-width}) {
    @content;
  }
}
// tablet view 991
@mixin tablet {
  @media (max-width: #{$sm-max-width}) {
    @content;
  }
}
//max-1440px
@mixin midlarge {
  @media (max-width:#{$lg-max-width}) {
    @content;
  }
}
@mixin max-width-1300 {
  @media (max-width:#{$max-width-1300}) {
    @content;
  }
}
// from 767px - 1024px
@mixin tabletNew {
  @media (min-width: #{$sm-min-width}) and (max-width:#{$md-max-width}) {
    @content;
  }
}
@mixin desktopsmall {
  @media (max-width: #{$mdd-min-width}) {
    @content;
  }
}
@mixin gridfix {
  @media (max-width: #{$md-max-width}) {
    @content;
  }
}
// 1200px
@mixin desktop {
  @media (min-width: #{$lg-min-width}) {
    @content;
  }
}
@mixin desktopMax {
  @media (max-width: #{$lg-min-width}) {
    @content;
  }
}
// 1440px breakpoint
@mixin desktoplarge {
  @media (min-width: #{$xlg-min-width}) {
    @content;
  }
}
@mixin minwidth577 {
  @media (min-width: 577px) {
    @content;
  }
}
// extra large desktops
@mixin desktopxtralarge {
  @media (min-width: #{$xxlg-min-width}) {
    @content;
  }
}
// extra extra large desktops 1920
@mixin desktopxxtralarge {
  @media (min-width: #{$xxlg-max-width}) {
    @content;
  }
}
//largee screen
@mixin largescreen {
  @media (max-width: #{$xlg-max-width}) {
    @content;
  }
}

//1290px
@mixin largescreenDesktop {
  @media (max-width: #{$mid-max-width}) {
    @content;
  }
}

//***** all the font sizes *****//
// font mixin creation
@mixin flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin error {
  text-align: left;
  color: red;
  font-size: 12px;
}

@mixin fontStyle {
  font-size: 16px;
  font-weight: 400;
}

@import "../../../App.scss";
$mainpink: #DF1881;
$subpink: #FF5FB3;
$maingray: #CECECE;
$subgray: #6E7072;

$startpointmobile: 769px;
$startpointtablet: 991px;
$medpointtablet: 1199px;
$smallscreen: 1200px;
$mediumscreen: 1400px;

.SupplierListingWrap {
  // max-height: 360px;
  overflow-x: scroll;

  .grid_2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
    margin-top: 10px;

    .checkbox_wrapper {
      label {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        border: 1px solid #989697;
        border-radius: 9999px;
        cursor: pointer;
        width: 100%;

        input[type="checkbox"] {
          appearance: none;
          width: 20px;
          height: 20px;
          border: 1px solid $subgray;
          border-radius: 4px;
          background-color: $maingray;
          cursor: pointer;
          transition: background-color 0.3s ease, border-color 0.3s ease;

          &:checked {
            background-color: $subpink;
          }
        }

        span {
          font-weight: 400;
          color: $subgray;
        }
      }
    }
  }

  .SupplierScrollContainer {
    overflow: auto;
    white-space: nowrap;
    padding: 5px 20px 5px 20px;
    background: transparent;
    height: 100%;
    margin-bottom: 10px;

    .SupplierGridScroll {

      .SupplierTitlesWrap {
        display: grid;
        grid-template-columns: 75% 25%;
        grid-column-gap: 10px;
        grid-row-gap: 10px;

        .SupplierName {
          margin-right: 5px;
          margin-left: 15px;
          color: #9A1F60;
          background: transparent;
        }

        .InputFieldBox {
          p {
            font-size: 16px;
            font-weight: 600;
            margin: 0;
            padding-right: 25px;
            color: #9A1F60;
            text-align: left;
            cursor: pointer;

            a {
              color: #9A1F60;
              text-decoration: none;
            }
          }
        }
      }

      .SupplierWrap {
        display: grid;
        grid-template-columns: 75% 25%;
        grid-column-gap: 10px;
        // grid-row-gap: 10px;
        border: none;
        // background-color: $btn-color;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 400;
        color: #1a1818 !important;
        padding: 0px 20px;
        width: 100%;
        // margin-bottom: 10px;
        background-color: #e7e7e7;


        .SupplierName {
          margin-right: 5px;
          margin-left: 15px;
          color: #9A1F60;
          background: transparent;
        }

        .InputFieldBox {

          .radioCustom {
            opacity: 0;
            position: absolute;
          }

          .radioCustom,
          .radioCustomLabel {
            display: inline-block;
            vertical-align: middle;
            margin-right: 7px;
            cursor: pointer;
          }

          .radioCustomLabel {
            position: relative;
          }

          .radioCustom+.radioCustomLabel:before {
            content: "";
            background: #fff;
            border: 1.5px solid #595f72;
            display: inline-block;
            vertical-align: middle;
            border-radius: 2px;
            padding: 7px;
            text-align: center;
          }

          .radioCustom+.radioCustomLabel:before {
            border-radius: 5px;
          }

          .radioCustom:checked+.radioCustomLabel:before {
            background: url(../../../assets/icons/RightIcon.svg);
            background-size: 10px;
            background-repeat: no-repeat;
            background-position: center;
          }

          p {
            font-size: 16px;
            font-weight: 600;
            margin: 0;
            padding-right: 25px;
            color: #9A1F60;
            text-align: right;
            cursor: pointer;

            a {
              color: #9A1F60;
              text-decoration: none;
            }
          }

          input {
            border: none;
            background-color: $btn-color;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 400;
            color: $primaryBlack-color !important;
            padding: 0px 20px;
            width: 100%;
          }

          input:focus {
            outline: none;
          }

          select {
            background-color: #f0dfe8;
            border: none;
            border-radius: 5px;
            color: #9A1F60;
            font-size: 16px;
            font-weight: 400;
            // margin-bottom: 10px;
            padding: 10px 20px;
            width: 100%;
          }
        }
      }

      .SupplierCard {
        min-width: 475px;
        padding: 15px 0px;
        align-items: center;
        margin-top: -28px;
        margin-right: 15px;
        // margin-bottom: 15px;
        padding-right: 15px;
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 10px;

        @include smMobile {
          flex-wrap: wrap;
        }


        .CardSelect {
          margin-right: 10px;

          .radioCustom {
            opacity: 0;
            position: absolute;
          }

          .radioCustom,
          .radioCustomLabel {
            display: inline-block;
            vertical-align: middle;
            margin: 5px;
            cursor: pointer;
          }

          .radioCustomLabel {
            position: relative;
          }

          .radioCustom+.radioCustomLabel:before {
            content: "";
            background: #fff;
            border: 1.5px solid #595f72;
            display: inline-block;
            vertical-align: middle;
            border-radius: 2px;
            padding: 7px;
            text-align: center;
          }

          .radioCustom+.radioCustomLabel:before {
            border-radius: 5px;
          }

          .radioCustom:checked+.radioCustomLabel:before {
            background: url(../../../assets/icons/RightIcon.svg);
            background-size: 10px;
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        .SupplierPreview {
          cursor: pointer;
          // max-width: 748px;
          // width: 748px;
          // height: 110px;
          border-radius: 5px;
          background-color: #e7e7e7;
          border: 1px solid #e7e7e7;
          padding: 10px;
          padding-bottom: 5px !important;

          @include smMobile {
            min-width: 100%;
            min-height: 100%;
            margin-bottom: 15px;
          }

          img {
            max-width: 728px;
            max-height: 90px;
            border-radius: 5px;
          }

          iframe {
            pointer-events: none;
          }
        }

        .SupplierPreviewSelected {
          cursor: pointer;
          // max-width: 748px;
          // width: 748px;
          // height: 110px;
          border-radius: 5px;
          background-color: #e7e7e7;
          border: 2px solid $pink-color;
          padding: 10px;
          padding-bottom: 5px !important;

          @include smMobile {
            min-width: 100%;
            min-height: 100%;
            margin-bottom: 15px;
          }

          img {
            max-width: 728px;
            max-height: 90px;
            border-radius: 5px;
          }

          iframe {
            pointer-events: none;
          }
        }

        .MainContentWrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 20px;
          width: 100%;

          @include xxsMobile {
            padding: 0px;
          }

          .iconWrap {
            cursor: pointer;

            svg {
              font-size: 25px;
              color: #505050;
            }

            .EditIconWrap {
              display: flex;
              flex-direction: column;

              img {
                width: 25px;
                height: 25px;

                @include xxsMobile {
                  width: 20px;
                  height: auto;
                }
              }

              svg {
                font-size: 25px;
                color: #505050;
              }
            }
          }

          .PropertyContent {
            @include smMobile {
              margin: 0;
            }

            h5 {
              font-size: 16px;
              font-weight: 400;
              margin: 0;
              cursor: pointer;
            }

            span {
              font-size: 16px;
              font-weight: 400;
              cursor: pointer;
            }

            .PriceText {
              margin: 10px 0px;
            }

            .PropertySize {
              display: flex;

              .Size {
                @include flex;
                margin-right: 20px;

                img {
                  width: 26px;
                  height: 20px;
                  margin-right: 10px;
                }

                p {
                  font-size: 16px;
                  font-weight: 300;
                  color: $sizetext-color;
                  margin: 0;
                }
              }

              .Capacity {
                @include flex;

                img {
                  width: 26px;
                  height: 20px;
                  margin-right: 10px;
                }

                p {
                  font-size: 16px;
                  font-weight: 300;
                  color: $sizetext-color;
                  margin: 0;
                }
              }
            }

            .CardLink {
              font-size: 16px;
              font-weight: 400;
              color: $pink-color;
              margin: 0;
              cursor: pointer;

              @include smMobile {
                margin-top: 20px;
              }
            }
          }
        }
      }

    }
  }

  .InvisibleCard {
    opacity: 50%;
    display: none !important;
  }
}
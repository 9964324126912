@import "../../../App.scss";

.footer_container {
  background-color: $primaryBlack-color;

  .footer_section {
    box-shadow: 0px 3px 2px -1px rgba(189, 187, 189, 0.54);
    padding: 50px 100px;
    display: flex;

    @include mobile {
      padding: 50px;
    }

    flex-wrap: wrap;

    .footer_box {
      width: 250px;
      padding-top: 20px;

      .footer_heading {
        h4 {
          color: $White-color;
          font-weight: 700;
          font-size: 1rem;
          padding: 20px 0;
          margin-bottom: 0;
        }
      }

      .footer_pages {
        p {
          color: $White-color;
          font-weight: 500;
          font-size: 1rem;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }

      .footer_user_info_pages {
        a {
          color: $White-color;
          font-weight: 500;
          font-size: 1rem;
          padding-bottom: 10px;
          text-decoration: none;
        }
      }
    }
  }

  .socialFooter_section {
    padding: 20px 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include tablet {
      flex-direction: column;
      padding: 20px 50px;
    }

    .sociali_links_icons {
      display: flex;
      cursor: pointer;

      .social_icons {
        width: 50px;
        height: 50px;
        padding: 5px;
        border-radius: 5px;
        background-color: $White-color;
        margin-right: 10px;
        margin-bottom: 10px;

        &:last-child {
          margin-right: unset;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      @include tablet {
        padding-bottom: 20px;
        flex-wrap: wrap;
      }
    }

    .copyRight_containt {
      display: flex;
      align-items: center;

      @include mobile {
        flex-direction: column;
      }

      p {
        padding-right: 10px;
        color: $White-color;
        font-size: 1rem;
        margin-bottom: 0;
        -ms-word-break: break-word;
        word-break: break-word;
        white-space: pre-wrap;

        @include tablet {
          padding: 15px 5px;
        }
      }
    }

    .language_containt {
      display: flex;
      align-items: center;

      @include tablet {
        padding-top: 20px;
      }

      p {
        margin-bottom: 0;
        color: $White-color;
        font-size: 1rem;
        margin-right: 20px;
      }

      select {
        background: transparent;
        border: none;
        color: $White-color;
        width: 80px;
        font-size: 1rem;
        -webkit-appearance: none;
        background-image: url(../../../assets/icons/DropdownWhiteIcon.svg);
        background-position: right 0px center;
        background-repeat: no-repeat;

        option {
          color: $black-color;
          text-align: center;
        }
      }
    }
  }
}
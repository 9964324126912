@import "../../App.scss";
.JoinOrganisationContainer {
  .JoinOrganisationHeading {
    margin-bottom: 15px;
    h1 {
      font-size: 32px;
      font-weight: 400;
      color: $primaryBlack-color;
    }
  }
  .JoinOrganisationDescription {
    max-width: 938px;
    width: 100%;
    border-bottom: 1px solid $borderBottom-color;
    padding-bottom: 20px;
    .InputContainer {
      max-width: 400px;
      p {
        @include fontStyle;
        margin: 0;
        margin-top: 4%;
        color: $gray-color-font;
      }
      input {
        border-radius: 5px;
        margin-top: 4%;
        background: $btn-color;
        max-width: 601px;
        height: 40px;
        width: 100%;
        border: none;
        padding: 12px;
        font-size: 16px;
        font-weight: 300;
        color: $primaryBlack-color;
      }
      input:focus {
        outline: none;
      }
    }
  }
  .TextContainer {
    .NoResultFound {
      @include fontStyle;
      color: $pink-color;
      margin-top: 3%;
    }
    p {
      @include fontStyle;
      margin: 0;
      margin-bottom: 4%;
      max-width: 472px;
      span {
        @include fontStyle;
        color: $pink-color;
      }
    }
    .SearchData {
      .searchMainContent {
        cursor: pointer;
        max-width: 400px;
        width: 100%;
        height: 131px;
        border-color: $pink-color;
        padding: 12px;
        margin-top: 4%;
        @include fontStyle;
        border-radius: 5px;
        background-color: $btn-color;
        color: $light-black-color;
        resize: none !important;
        cursor: pointer;
      }
      .ActiveContent {
        border: 1px solid $pink-color;
        cursor: pointer;
      }
    }
    textarea:focus {
      outline: none;
    }
  }
  .JoinOrganisationBtn {
    margin-top: 38px;
    .SearchButton {
      padding: 2% 2%;
      border-radius: 5px;
      background: $pink-color;
      border: none;
      @include fontStyle;
      color: $White-color;
      cursor: pointer;
    }
    .Active {
      padding: 2% 2%;
      border-radius: 5px;
      background: $pink-color;
      border: none;
      @include fontStyle;
      color: $White-color;
      cursor: pointer;
    }
    .InActive {
      padding: 2% 2%;
      border-radius: 5px;
      background: $btn-text-color;
      border: none;
      @include fontStyle;
      color: $White-color;
      cursor: pointer;
    }
  }
}

@import "../../App.scss";
.LoaderWrap {
  height: 80vh;
  width: 100%;
  @include flex;
}
.MembershipSection {
  padding: 80px 0px;
  .PayNowActive {
    margin-top: 15px;
    background-color: $pink-color;
    color: $White-color;
    padding: 20px 0px;
    min-width: 140px;
    border: none;
    border-radius: 5px;
  }
  .PayNow {
    margin-top: 15px;
    background-color: $btn-color;
    color: $black-color;
    padding: 20px 0px;
    min-width: 140px;
    border: none;
    border-radius: 5px;
  }
  .selectorg {
    max-width: 300px;
    select {
      border: none;
      background-color: $btn-color;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 400;
      color: $primaryBlack-color !important;
      padding: 10px 20px;
      width: 100%;
      -webkit-appearance: none;
      background-image: url(../../assets/icons/DropdownIcon.svg);
      background-position: right 15px center;
      background-repeat: no-repeat;
    }
  }

  .SubscriptionOverview {
    width: 100%;
    h1 {
      font-size: 32px;
      font-family: $Poppins-SemiBold;
      margin-bottom: 20px;
      @include mobile {
        font-size: 26px;
        -ms-word-break: break-word;
        word-break: break-word;
        white-space: pre-wrap;
      }
    }
    .SelectRunTimeText {
      font-size: 20px;
      text-align: left;
      font-family: $Poppins-SemiBold;
      margin: 0;
      margin-bottom: 15px;
    }
    span {
      font-size: 16px;
      text-align: left;
      font-family: $Poppins-Regular;
    }
    // .SubscriptionCard {
    // 	min-width: 235px;
    // 	max-width: 235px;
    // 	padding: 40px;
    // 	box-shadow: 3px 3px 10px #00000029;
    // 	border-radius: 15px;
    // 	margin-bottom: 20px;
    // 	img {
    // 		width: 47px;
    // 		height: 57px;
    // 		margin-bottom: 10px;
    // 	}
    // 	h6 {
    // 		font-size: 16px;
    // 		font-family: $Poppins-Medium;
    // 		margin-bottom: 10px;
    // 	}
    // 	p {
    // 		font-size: 16px;
    // 		font-weight: 400;
    // 		margin: 0;
    // 	}
    // }
    p {
      font-size: 16px;
      font-weight: 400;
      -ms-word-break: break-word;
      word-break: break-word;
      white-space: pre-wrap;
      max-width: 985px;
      width: 100%;
    }
  }
  .SubscriptionForm {
    width: 100%;
    max-width: 600px;
    h1 {
      font-size: 32px;
      font-family: $Poppins-SemiBold;
      margin-bottom: 20px;
      @include mobile {
        font-size: 26px;
        -ms-word-break: break-word;
        word-break: break-word;
        white-space: pre-wrap;
      }
    }
    .InputFildBox {
      p {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        margin-bottom: 10px;
        color: $light-black-color;
      }
      input {
        border: none;
        background-color: $btn-color;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 400;
        color: $primaryBlack-color !important;
        padding: 15px 20px;
        width: 100%;
        margin-bottom: 10px;
      }
      input:focus {
        outline: none;
      }
      select {
        border: none;
        background-color: $btn-color;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 400;
        color: $primaryBlack-color !important;
        padding: 15px 20px;
        max-width: 600px;
        width: 100%;
        -webkit-appearance: none;
        background-image: url(../../assets/icons/DropdownIcon.svg);
        background-position: right 15px center;
        background-repeat: no-repeat;
      }
      select:focus {
        outline: none;
      }
    }
    .flexInput {
      display: flex;
      width: 100%;
      margin-top: 15px;
      @include xxsMobile {
        flex-wrap: wrap;
      }
      .InputFildBox {
        width: 50%;
        @include xxsMobile {
          width: 100%;
        }
      }
      .InputFildBox:last-child {
        margin-left: 20px;
        @include xxsMobile {
          margin-left: unset;
        }
      }
    }
    button {
      margin-top: 15px;
      background-color: $pink-color;
      color: $White-color;
      padding: 20px 0px;
      min-width: 140px;
      border: none;
      border-radius: 5px;
    }
  }
}

@import "../../../App.scss";
.ForgotSection {
  display: flex;
  justify-content: center;
  .ForgotContaint {
    // width: 350px;
    padding: 20px;
    margin-bottom: 50px;
    max-width: 350px;
    width: 100%;
    @include smallmobile {
      width: 100%;
    }
    .ForgotHeading {
      text-align: center;
      display: flex;
      justify-content: center;
      h2 {
        background-image: url(../../../assets/icons/AccountCreatedBacgroundIcon.svg);
        background-repeat: no-repeat;
        background-position: center;
        //   width: 70px;
        color: $gray-color-font;
        padding: 30px 0;
        font-family: $Poppins-Bold;
        margin-bottom: 0;
        font-size: 1rem;
      }
    }
    .MainReceiveTect {
      text-align: left;
      border-bottom: 1px solid $border-color;
      p {
        font-size: 16px;
        font-weight: 500;
        color: $pink-color;
      }
    }
    p {
      margin-top: 15px;
    }
    .AccountCreatBtnWrap {
      padding: 15px 0;
      .AccountCreatBtn {
        padding: 20px 40px;
        border-radius: 5px;
        background-color: $pink-color;
        color: $White-color;
        border: none;
        font-size: 1rem;
        cursor: pointer;
        @include smallmobile {
          width: 100%;
        }
      }
    }
  }
}

@import "../../App.scss";

.ConnectionContainer {
  margin-left: 10px;

  .TitleWrap {
    display: flex;
    align-items: end;
    justify-content: space-between;

    @include mobile {
      display: unset;
      margin-bottom: 10px;
    }

    h1 {
      font-size: 32px;
      font-weight: 400;
    }
  }

  .InvoicesTitles {
    width: 100%;
    display: grid;
    grid-template-columns: 10% 50% 10% 10% 10% 1fr;
    grid-column-gap: 10px;
    justify-content: space-between;
    align-items: center;
  }

  .InvoiceContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 15% 15% 20% 15% 1fr;
    grid-column-gap: 10px;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    border-radius: 5px;
    border: 0.5pt solid #d2d2d2;
    margin-bottom: 5px;

    div {
      text-align: center;

      .paid {
        color: green !important;
      }

      p {
        color: black;
        font-weight: bold;
        margin-bottom: 5px;

      }

      p:first-child {
        color: $gray-color-font;
        font-weight: normal;
      }
    }

    button.download {
      background-color: #e7e7e7;
      border: none;
      border-radius: 5px;
      padding: 5px 15px;
      margin-right: 10px;
      max-width: 200px;
      float: right;
    }

    button.pay {
      background-color: #df1681;
      border: none;
      border-radius: 5px;
      padding: 5px 15px;
      margin-right: 10px;
      max-width: 200px;
      color: #ffffff;
    }

    .Container {
      cursor: pointer;
      margin-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 120px;
        height: 120px;
        object-fit: contain;

        @include xxsMobile {
          width: 90px;
          height: 90px;
        }
      }

      p {
        margin: 0;
        margin-left: 20px;
        max-width: 400px;
        color: $gray-color-font;
        font-size: 16px;
        -ms-word-break: break-word;
        word-break: break-word;
        white-space: pre-wrap;

        @include smMobile {
          margin-left: 10px;
          margin-top: 20px;
          width: 200px;
          -ms-word-break: break-word;
          word-break: break-word;
          white-space: pre-wrap;
        }

        @include xxsMobile {
          white-space: nowrap;
          width: 80px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .CrosIconContainer {
      display: flex;
      flex-direction: column;
      height: 100%;
      cursor: pointer;
      margin-right: 15px;

      @include smMobile {
        margin-right: unset;
      }

      img {
        width: 16px;
        height: 16px;
      }

      svg {
        font-size: 19px;
        color: $sizetext-color;
        margin-top: 10px;
      }
    }

    // border-bottom: 2px solid #d2d2d2;
  }

  .IconWrapper {
    margin-top: 5px;

    a {
      text-decoration: none;
      color: inherit;
    }

    .IconContainer {
      cursor: pointer;
      margin-top: 9px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      float: left;

      p {
        padding-left: 5px;
        margin-top: 3px;
        font-size: 18px;
        font-weight: 400;
        line-height: 18px;
        color: $pink-color;
        margin: 0;
      }
    }

    .HideContainer {
      margin-top: 9px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      p {
        padding-left: 5px;
        margin-top: 3px;
        font-size: 18px;
        font-weight: 400;
        line-height: 18px;
        color: $sizetext-color;
        margin: 0;
      }
    }
  }
}
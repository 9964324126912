@import "../../../App.scss";
.ViewImageWrap {
  .CrosIcon {
    padding-top: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: flex-end;
    @include xxsMobile {
      right: 15px;
    }

    svg {
      cursor: pointer;
      font-size: 20px;
      @include xxsMobile {
        font-size: 15px;
      }
    }
  }
  .ViewListingImgWarp {
    padding-bottom: 20px;
    .ImageWrap {
      height: 700px;
      width: 80%;
      margin: 0 auto;
      @include midlarge {
        height: 450px;
      }
      @include mobile {
        height: 350px;
      }
      @include smMobile {
        height: 80vh;
        width: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include smMobile {
          object-fit: contain;
        }
      }
      iframe {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include smMobile {
          object-fit: contain;
        }
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include smMobile {
          object-fit: contain;
        }
      }
    }
  }
}

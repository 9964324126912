@import "../../../App.scss";
$mainpink: #DF1881;
$subpink: #FF5FB3;
$maingray: #CECECE;
$subgray: #6E7072;

$startpointmobile: 769px;
$startpointtablet: 991px;
$medpointtablet: 1199px;
$smallscreen: 1200px;
$mediumscreen: 1400px;

section,
footer,
main,
.page_content {
  width: 100%;
  position: relative;
  display: grid;
}

.container {
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  box-sizing: border-box;
  margin: 0px auto;
  position: relative;
  z-index: 5;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
  margin: 0px auto;
  position: relative;
  z-index: 5;
}

.grid_2 {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
  margin: 0px auto;
  position: relative;
  z-index: 5;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}

.reverse {
  flex-direction: row-reverse;
}

li {
  list-style: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

#menuToggle {
  display: none;
}

@media screen and (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media screen and (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media screen and (min-width: 992px) {
  .container {
    max-width: 960px
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px
  }
}

@media screen and (min-width: 1400px) {
  .container {
    max-width: 1320px
  }
}

@media screen and (min-width: 1600px) {
  .container {
    max-width: 1620px
  }
}

@media screen and (min-width: 2400px) {
  .container {
    max-width: 1800px
  }
}

.modal_grid {
  display: flex;
  gap: 20px;
  flex: 1;
  overflow: hidden;

  .search_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .search {
      position: relative;
      display: flex;
      align-items: center;
      flex: 1;
      max-width: 350px;
      gap: 10px;

      input {
        border-radius: 9999px;
        padding: 12px 20px;
        font-size: 1em;
        font-weight: 600;
        border: none;
        outline: none;
        transition: background 0.3s ease, color 0.3s ease;
        flex: 1;
        box-sizing: border-box;
        border: 1px solid $subgray;
        padding-right: 48px;
        min-width: 0;
        height: 40px;

        &::placeholder {
          font-weight: 300;
        }
      }

      .search_button {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 40px;
        background: $mainpink;
        color: #ffffff;
        border: none;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1em;
        transition: background 0.3s ease;
        cursor: pointer;


        &:hover {
          background: darken($mainpink, 10%);
        }
      }
    }
  }

  .filter {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.9em;
    color: #333;
    cursor: pointer;

    i {
      font-size: 1em;
    }
  }
}

.modal_box {
  input[type="radio"] {
    appearance: none;
    flex-shrink: 0;
    width: 16px;
    height: 15px;
    border: 1px solid $subgray;
    border-radius: 4px;
    background-color: $maingray;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;

    &:checked {
      background-color: $mainpink;
    }
  }

  label {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  border: 1px solid #c0c0c0;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal_content_right {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
}

.small {
  flex: 1;
  height: 100%;
}

.large {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #c0c0c0;
  padding: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.scrollable_box {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  max-height: 500px;
  padding: 20px 10px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #989697;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #CECECE;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #CECECE;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}


body {
  font-family: 'Poppins-Regular', sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 1.2em;
  color: $subgray;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  margin: 0;
}

a {
  text-decoration: none !important;
  color: #ffffff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins-Regular', sans-serif;
  z-index: 2;
  position: relative;
  line-height: 1.3em;
  font-weight: 700;
}

h1 {
  font-size: 4em;
}

h2 {
  font-size: clamp(30px, 1.5vw, 32px);
  margin-bottom: 0.8em;
  color: #D31775;
}

h3 {
  font-size: 2.4em;
  margin-bottom: 0.8em;
}

h4 {
  font-size: 1.8em;
  margin-bottom: 0.8em;
}

// p {
//   padding: 3px 0 1rem 0;
// }

p {
  font-family: 'Poppins-Regular', sans-serif;
  font-weight: 400;
  line-height: 1.6em;
}

a {
  color: #ffffff;
  font-family: 'Poppins-Regular', sans-serif;
  font-weight: 400;
  text-decoration: none;

}

.button {
  border-radius: 9999px;
  border-width: 0;
  display: inline;
  padding: 10px 18px;
  background: $maingray;
  color: $subgray;
  font-size: 1em;
  font-weight: 600;
  transition: background 0.3s ease, color 0.3s ease;

  svg {
    margin-right: 5px;
  }

  &:hover {
    background: $mainpink;
    color: #ffffff;
  }
}

.pinkbutton {
  border-radius: 9999px;
  display: inline-block;
  padding: 15px 20px;
  background: $mainpink;
  color: #ffffff;
  font-weight: 600;
  transition: background 0.3s ease, color 0.3s ease;
  font-size: 1.25em;
  border: none;

  svg {
    margin-right: 5px;
  }

  &:hover {
    background: $subpink;
    color: #ffffff;
  }
}

.bigbutton {
  flex: 1 1 calc(25% - 15px);
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 25px 20px;
  background: $mainpink;
  color: #ffffff;
  font-weight: 800;
  transition: background 0.3s ease, color 0.3s ease;
  font-size: 1.20em;
  min-width: 150px;
  max-width: calc(25% - 15px);
  height: auto;

  svg {
    margin-right: 15px;
    font-size: 1.8em;
  }

  &:hover {
    background: $subpink;
  }
}

header {
  padding: 5px 0;
  box-shadow: 0 7px 72px 1px rgba(0, 0, 0, 0.2);

  .menu {
    a {
      color: $subpink;
    }
  }

  .account {
    display: flex;
    align-items: center;
    border: 1px solid $subpink;
    border-radius: 9999px;
    padding: 6px 12px;
    gap: 8px;

    a {
      display: flex;
      align-items: center;
      color: $subpink;

      svg {
        margin-right: 8px;
        width: 18px;
        height: 19px;
      }
    }
  }
}

section.subheader {
  padding-top: 50px;
  padding-bottom: 10px;

  .search {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 350px;

    input {
      border-radius: 9999px;
      padding: 12px 20px;
      font-size: 1em;
      font-weight: 600;
      border: none;
      outline: none;
      transition: background 0.3s ease, color 0.3s ease;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid $subgray;
      padding-right: 48px;
      min-width: 300px;
      height: 40px;

      &::placeholder {
        font-weight: 300;
      }
    }

    .search_button {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 40px;
      background: $mainpink;
      color: #ffffff;
      border: none;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1em;
      cursor: pointer;
      transition: background 0.3s ease;

      &:hover {
        background: darken($mainpink, 10%);
      }
    }
  }
}

section.eyecandy {
  aspect-ratio: 32/9;
  border-top: 1px solid $subgray;

  .image {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 32/9;
    }

    .container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .content {
        width: 35%;
        border-radius: 16px;
        background: rgba(255, 255, 255, 0.45);
        backdrop-filter: blur(7px);
        padding: 25px 35px;

        h2 {
          margin: 0;
        }

        p {
          color: $subgray;
        }
      }
    }
  }

  .buttons {
    position: absolute;
    z-index: 1000;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    padding: 23px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
    max-width: 90%;
    width: fit-content;
    align-items: stretch;
  }
}

section.woningcampagnes {
  padding-top: 75px;
  padding-bottom: 50px;

  h3 {
    font-size: 1.8em;
    color: $subpink;
    font-family: 'Poppins-Bold', sans-serif;
  }

  .grid {
    .addnewitem {
      background-color: #EDEDED;
      border-radius: 25px;
      box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.22);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 20px;

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        svg {
          font-size: 6em;
          color: $subgray;
        }

        p {
          margin: 0;
          font-size: 1.35em;
          color: $subgray;
          font-weight: 600;
        }
      }
    }

    .item {
      width: 100%;
      height: 100%;
      padding: 20px;
      border-radius: 25px;
      box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.12);

      .image {
        width: 100%;
        height: 80%;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          aspect-ratio: 3/2;
          border-radius: 8px;
        }

        .tags {
          position: absolute;
          bottom: 3%;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          gap: 5px;
          width: 100%;
          justify-content: center;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .tag {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            background-color: $subpink;
            color: #ffffff;
            padding: 5px 10px;
            border-radius: 4px;
            font-size: 0.9em;
            width: 25%;
            text-align: center;

            svg {
              font-size: 1.3em;
              margin-right: 5px;
            }

            span {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              text-align: left;
              width: 100%;

              .number {
                font-weight: bold;
                font-size: 1.1em;
              }

              .label {
                font-size: 0.9em;
              }
            }
          }
        }
      }

      .content {
        width: 100%;
        height: 20%;
        margin-top: 12px;

        .socialicons {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 15px;

          svg {
            font-size: 1.3em;
            color: $subgray;
          }
        }
      }
    }
  }
}

section.centertext {
  padding-top: 80px;

  .container {
    width: 60rem;

    h2 {
      color: #000;
      font-weight: 500;
      font-size: 1.8em;
    }
  }
}

section.box {
  padding: 25px 0;

  .container {
    width: 60rem;
    border-radius: 25px;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
    padding: 25px;

    span {
      margin-left: 15px;
      font-weight: 500;
    }

    .button {
      width: 100%;
      text-align: center;
      display: block;
    }

    h2 {
      color: #000;
      font-weight: 500;
      font-size: 1.8em;
      margin: 0 0 20px;
    }

    .grid_2 {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 20px;
      margin-top: 10px;

      .checkbox_wrapper {
        label {
          display: flex;
          align-items: center;
          padding: 10px 15px;
          border: 1px solid #989697;
          border-radius: 9999px;
          cursor: pointer;
          width: 100%;

          input[type="checkbox"] {
            appearance: none;
            width: 20px;
            height: 20px;
            border: 1px solid $subgray;
            border-radius: 4px;
            background-color: $maingray;
            cursor: pointer;
            transition: background-color 0.3s ease, border-color 0.3s ease;

            &:checked {
              background-color: $subpink;
            }
          }

          span {
            font-weight: 400;
            color: $subgray;
          }
        }
      }
    }

    .content {
      p {
        margin-bottom: 0;
      }

      .kaart {
        margin-bottom: 10px;
      }

      iframe {
        // width: 100%;
        // height: 100%;
        border-radius: 10px;
      }

      h2 {
        color: #000;
        font-weight: 500;
        font-size: 1.8em;
        margin: 0 0 20px;
      }

      .input_group {
        margin-bottom: 20px;

        span {
          display: block;
          margin-bottom: 5px;
          font-weight: 400;
          color: $subgray;
          margin-left: 15px;
        }

        input {
          padding: 10px 15px;
          border: 1px #989697 solid;
          border-radius: 9999px;
        }

        select {
          padding: 10px 15px;
          border: 1px #989697 solid;
          border-radius: 9999px;
        }
      }

      .full_width input {
        width: 100%;
      }

      .date_group {
        display: flex;
        justify-content: flex-start;
        gap: 20px;

        .half_width {
          width: 20%;

          input {
            width: 100%;
          }
        }
      }

      .new_group {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        >div {
          flex: 1;
          display: flex;
          flex-direction: column;

          &:first-child {
            flex: 2.75;
          }

          span {
            margin-bottom: 5px;
            font-weight: 500;
            color: $subgray;
            margin-left: 15px;
          }

          input {
            width: 100%;
            padding: 10px 15px;
            border: 1px #989697 solid;
            border-radius: 9999px;
          }
        }
      }
    }
  }
}

section.box.banner {
  .container {
    width: 65rem;

    .content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 20px;

      .banner {
        padding: 10px;
        border-radius: 8px;
        width: 100%;

        img {
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
        }

        &#banner-728x90 img {
          max-width: 728px;
          max-height: 90px;
        }

        &#banner-970x250 img {
          max-width: 970px;
          max-height: 250px;
        }

        &#banner-300x250 img {
          max-width: 300px;
          max-height: 250px;
        }

        &#banner-300x600 img {
          max-width: 300px;
          max-height: 600px;
        }

        &.fixed {
          justify-content: center;
          transition: justify-content 0.3s ease;
        }

        &.expanded {
          justify-content: flex-start;
        }

        &.hidden {
          display: none;
        }
      }

    }

    .extend {
      display: block;
      text-align: center;
      margin-top: 15px;
      color: $subgray;
      font-weight: 500;
    }
  }
}

footer {
  background-color: #1a1818;
  padding: 50px 0;
  border-bottom: 1px solid #ffffff;

  .container {
    justify-content: flex-start;
    align-items: baseline;
    gap: 70px;

    .block {
      h2 {
        font-size: 1em;
        color: #ffffff;
        font-weight: 600;
      }

      ul {
        padding: 0;

        a {
          font-size: 1em;
          line-height: 2.5em;
          font-weight: 300;
        }
      }
    }
  }
}

section.subfooter {
  background-color: #1a1818;
  padding: 35px 0;

  .container {
    align-items: center;

    p {
      margin: 0;
      padding: 0;
      color: #ffffff;
    }

    .copy {
      gap: 20px;
    }

    .socials {
      display: flex;
      gap: 15px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 40px;
        background-color: #ffffff;
        border-radius: 4px;
        transition: background-color 0.3s ease, transform 0.3s ease;

        svg {
          color: #000000;
          font-size: 1.2em;
        }

        &:hover {
          background-color: #f0f0f0;
          transform: scale(1.1);
        }
      }
    }

    .language {
      gap: 15px;
      display: flex;
    }
  }
}

.sidebar {
  position: fixed;
  top: 100px;
  right: 2rem;
  max-width: 280px;
  border-radius: 20px;
  padding: 25px;
  z-index: 100;
  height: fit-content;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
  background-color: #fff;

  h2 {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }

  span {
    display: block;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
  }

  p {
    font-size: 0.9rem;
    padding: 0;
    margin: 0;
    color: #555;
  }

  .campagneduur {
    padding: 5px 0;
  }

  .bereik {
    padding: 5px 0;
  }

  .sub {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    font-size: 1rem;

    p {
      margin: 0;
    }
  }

  .totaal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    p {
      margin: 0;
    }

    .main_price {
      color: #000;
      font-weight: bold;
      font-size: 1.2em;
    }

    .discount_price {
      color: $subpink;
      text-decoration: line-through;
    }
  }

  .pinkbutton {
    display: block;
    margin-top: 10px;
    text-align: center;
    color: #fff;
  }
}

.chosenProperty {
  display: flex;
  gap: 20px;
  padding: 20px;
  flex: 2;
  justify-content: center;

  img {
    width: 240px;
    height: 120px;
    flex-shrink: 0;
    border-radius: 8px;
  }

  .content {

    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;

    .address {
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .zipcode {
      color: #6E7072;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .price {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}

.MyBannerLayoutMain {
  .AddBannerOption {
    margin-bottom: 25px;

    p {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      margin-bottom: 10px;
      color: $light-black-color;
    }

    .AddBannerOptionBtnWrap {
      .MySelfBtn {
        background-color: $btn-color;
        border: none;
        border-radius: 5px;
        padding: 15px;
        margin-right: 10px;

        @include midmobile {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }

  .BannerFormMain {
    // max-width: 550px;
    width: 100%;

    .FloatingButton {
      max-width: 50%;
      position: relative;
      left: 75%;
      top: -70px;
      height: 0px;

      .saveButton {
        border-radius: 5px;
        background: $pink-color;
        border: none;
        color: $White-color;
        padding: 20px;
      }

    }

    .OrgSelect {
      margin-bottom: 25px;

      p {
        font-size: 16px;
        font-family: $Poppins-SemiBold;
        margin: 0;
        margin-bottom: 10px;
        color: $light-black-color;
      }

      .OrganizationContainer {
        cursor: pointer;

        .Org {
          font-size: 16px;
          font-weight: 400;
          margin: 0;
          margin-bottom: 10px;
          color: $light-black-color;
        }

        .OrgActive {
          font-size: 16px;
          font-weight: 400;
          margin: 0;
          margin-bottom: 10px;
          color: $pink-color;
        }
      }

      span {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        margin-bottom: 10px;
        color: $pink-color;
      }
    }

    .BannerDateWrap {
      margin-bottom: 20px;
      display: grid;
      grid-template-columns: 40% 40% 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 10px;

      // input[type="date"]::-webkit-calendar-picker-indicator {
      //   filter: invert(17%) sepia(59%) saturate(3425%) hue-rotate(309deg) brightness(88%) contrast(91%);
      // }

      @include midmobile {
        grid-template-columns: 1fr;
      }

      .BannerStartDate {
        margin-bottom: 20px;

        p {
          font-size: 16px;
          font-family: $Poppins-SemiBold;
          margin: 0;
          margin-bottom: 10px;
          color: $light-black-color;
        }

        input {
          border: 1px solid grey;
          // background-color: #f0dfe8;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 400;
          color: black !important;
          padding: 10px 20px;
          max-width: 200px;
          width: 100%;
          margin-bottom: 10px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
        }

        span {
          position: relative;
        }

        span[data-currency]::after {
          position: absolute;
          content: attr(data-currency);
          left: 1em;
          top: 50%;
          transform: translate(-100%, -50%);
        }


        input:focus {
          outline: none;
        }
      }
    }

    .BannerTitle {
      margin-bottom: 20px;

      p {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 10px;
        color: $light-black-color;
      }

      .InputFieldBox {
        p {
          font-size: 16px;
          font-weight: 400;
          margin: 0;
          margin-bottom: 10px;
          color: $light-black-color;
        }

        input {
          border: 1px solid grey;
          // background-color: #f0dfe8;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 400;
          color: black !important;
          padding: 10px 20px;
          width: 100%;
          margin-bottom: 10px;
        }

        input:focus {
          outline: none;
        }
      }

      .flexInput {
        display: flex;
      }

      .InputFildBox:last-child {
        margin-left: 20px;
      }
    }

    .BannerType {
      margin-bottom: 20px;

      p {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 10px;
        color: $light-black-color;
      }

      .BannerTypeLabel {
        display: flex;
        align-items: center;

        p {
          font-weight: 400;
          font-size: 16px;
          color: $primaryBlack-color;
          margin: 0;
        }

        .radioCustom {
          opacity: 0;
          position: absolute;
        }

        .radioCustom,
        .radioCustomLabel {
          display: inline-block;
          vertical-align: middle;
          margin: 5px;
          cursor: pointer;
        }

        .radioCustomLabel {
          position: relative;
        }

        .radioCustom+.radioCustomLabel:before {
          content: "";
          background: #fff;
          border: 1.5px solid #595f72;
          display: inline-block;
          vertical-align: middle;
          border-radius: 2px;
          padding: 7px;
          text-align: center;
        }

        .radioCustom+.radioCustomLabel:before {
          border-radius: 5px;
        }

        .radioCustom:checked+.radioCustomLabel:before {
          background: url(../../../assets/icons/RightIcon.svg);
          background-size: 10px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }

    .BannerRegionWrap {
      margin-bottom: 20px;
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 10px;

      p {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        color: $light-black-color;
      }

      @include midmobile {
        grid-template-columns: 1fr;
      }

      .select_control--is-focused {
        border: none !important;
      }

      .basic_single {
        // background-color: #f0dfe8 !important;
        border: 1px solid grey;
        border-radius: 5px;
        padding: 5px;

        div {
          border: 1px solid grey;
          border-radius: 5px;
          padding: 5px;

        }

        select {
          // background-color: #f0dfe8;
          border: 1px solid grey;
          border-radius: 5px;
          color: black !important;
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 10px;
          padding: 10px 20px;
          width: 100%;
        }
      }

      .InputFieldBox {
        p {
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          margin-bottom: 10px;
          color: $light-black-color;
        }

        input {
          border: none;
          background-color: $btn-color;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 400;
          color: $primaryBlack-color !important;
          padding: 10px 20px;
          width: 100%;
          margin-bottom: 10px;
        }

        input:focus {
          outline: none;
        }

        select {
          background-color: #e7e7e7;
          border: none;
          border-radius: 5px;
          color: #1a1818 !important;
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 10px;
          padding: 10px 20px;
          width: 100%;
        }
      }
    }

    .BannerListingTitleWrap {
      width: 150%;
      margin-bottom: 20px;
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 10px;

      @include midmobile {
        grid-template-columns: 1fr;
      }

      .InputFieldBox {
        p {
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          margin-bottom: 10px;
          color: $light-black-color;

          .BannerShowListings {
            cursor: pointer;
            color: $pink-color;
          }
        }
      }
    }

    .HideBannerListingTitleWrap {
      display: none;
    }

    .HideBannerListingWrap {
      display: none;
    }

    .HideBannerListingsWrap {
      display: none;
    }

    .BannerListingWrap {
      width: 150%;
      margin-bottom: 20px;
      display: grid;
      grid-template-columns: 80% 1fr;
      grid-column-gap: 25px;
      grid-row-gap: 10px;

      @include midmobile {
        grid-template-columns: 1fr;
      }

      .InputFieldBox {
        p {
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          margin-bottom: 10px;
          color: $light-black-color;
        }

        input {
          border: none;
          background-color: $btn-color;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 400;
          color: $primaryBlack-color !important;
          padding: 10px 20px;
          width: 100%;
          margin-bottom: 10px;
        }

        input:focus {
          outline: none;
        }

        .BannerListingSearchBtn {
          background-color: #df1681;
          border: none;
          border-radius: 5px;
          color: #fff;
          margin-right: 10px;
          padding: 10px;
          width: 100%;
        }

        .BannerListingOr {
          text-align: center;
          padding-top: 10px;
        }

        .BannerListingLatestBtn {
          background-color: $btn-color;
          border: none;
          border-radius: 5px;
          color: $primaryBlack-color !important;
          margin-right: 10px;
          padding: 10px;
          width: 100%;
        }
      }
    }

    .BannerListingsWrap {
      width: 150%;
      margin-bottom: 20px;
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 10px;

      @include midmobile {
        grid-template-columns: 1fr;
      }

      .BannerShowListings {
        cursor: pointer;
        color: $pink-color;
      }
    }

    .BannerTemplateWrap {
      width: 150%;
      margin-bottom: 20px;
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 10px;

      @include midmobile {
        grid-template-columns: 1fr;
      }

      .CustomContact {
        font-weight: normal;

        a {
          color: #df1681;
          text-decoration: none;
        }
      }


      .InputFieldBox {
        p {
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          margin-bottom: 10px;
          color: $light-black-color;
        }

        input {
          border: none;
          background-color: $btn-color;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 400;
          color: $primaryBlack-color !important;
          padding: 10px 20px;
          width: 100%;
          margin-bottom: 10px;
        }

        input:focus {
          outline: none;
        }

        .BannerListingSearchBtn {
          background-color: #df1681;
          border: none;
          border-radius: 5px;
          color: #fff;
          margin-right: 10px;
          padding: 10px;
          width: 100%;
        }

        .BannerListingOr {
          text-align: center;
          padding-top: 10px;
        }

        .BannerListingLatestBtn {
          background-color: $btn-color;
          border: none;
          border-radius: 5px;
          color: $primaryBlack-color !important;
          margin-right: 10px;
          padding: 10px;
          width: 100%;
        }
      }
    }

    .BannerSuppliersWrap {
      width: 150%;
      margin-bottom: 20px;
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 10px;

      @include midmobile {
        grid-template-columns: 1fr;
      }

      .CustomContact {
        font-weight: normal;

        a {
          color: #df1681;
          text-decoration: none;
        }
      }


      .InputFieldBox {
        p {
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          margin-bottom: 10px;
          color: $light-black-color;
        }

        input {
          border: none;
          background-color: $btn-color;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 400;
          color: $primaryBlack-color !important;
          padding: 10px 20px;
          width: 100%;
          margin-bottom: 10px;
        }

        input:focus {
          outline: none;
        }

        .BannerListingSearchBtn {
          background-color: #df1681;
          border: none;
          border-radius: 5px;
          color: #fff;
          margin-right: 10px;
          padding: 10px;
          width: 100%;
        }

        .BannerListingOr {
          text-align: center;
          padding-top: 10px;
        }

        .BannerListingLatestBtn {
          background-color: $btn-color;
          border: none;
          border-radius: 5px;
          color: $primaryBlack-color !important;
          margin-right: 10px;
          padding: 10px;
          width: 100%;
        }
      }
    }

    .BannerTemplatePreviewWrap {
      height: 160px;
      overflow-x: hidden;
      border: 1.5px solid #e7e7e7;
      background-color: #e7e7e7;
      border-radius: 5px;
      margin-bottom: 20px;
      width: 150%;
      display: table;

      .BannerPreviewInnerWrap {
        display: table-cell;
        vertical-align: middle;
        text-align: center;

        .BannerInnerFrame {
          font-size: 16px;
          font-weight: 600;
          color: #505050;
        }
      }
    }

    .BannerSpreadWrap {
      margin-bottom: 20px;

      .InputFieldBox {
        p {
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          margin-bottom: 10px;
          color: $light-black-color;
        }
      }

      .BannerSpreadSelectWrap {
        display: grid;
        grid-template-columns: 25% 25% 50%;
        grid-column-gap: 20px;
        grid-row-gap: 10px;

        @include midmobile {
          grid-template-columns: 1fr;
        }

        .BannerSpreadButton {
          box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.16);
          padding: 30px 15px;
          border-radius: 5px;
          background-color: #ffffff;
          font-size: 10px;
          color: #505050;
          text-align: center;
          height: 100px;
          width: 100px;
          cursor: pointer;
        }

        .BannerSpreadButtonSelected {
          box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.16);
          padding: 30px 15px;
          border-radius: 5px;
          border: 2px solid $pink-color;
          background-color: #ffffff;
          font-size: 10px;
          color: #505050;
          text-align: center;
          height: 100px;
          width: 100px;
          cursor: pointer;
        }

        .BannerSpreadText {
          font-size: 10px;
        }
      }
    }

    .BannerCreditWrap {
      display: grid;
      grid-template-columns: 50% 30% 20%;
      grid-column-gap: 20px;
      grid-row-gap: 10px;
      margin-bottom: 20px;

      .BannerSummaryWrap {
        width: 150%;
        display: grid;
        grid-template-columns: 30% 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 0px;
        background-color: #e7e7e7;
        padding: 10px 15px;
        margin-bottom: 15px;

        .RowBorder {
          border-top: 2px solid gray;
          grid-column: 1 / 5;
          /* this code makes the row stretch to entire width of the container */
        }

        @include midmobile {
          grid-template-columns: 1fr;
        }

        .CustomContact {
          font-weight: normal;

          a {
            color: #df1681;
            text-decoration: none;
          }
        }


        .InputFieldBox {
          p {
            font-size: 16px;
            font-weight: 600;
            margin: 0;
            margin-bottom: 10px;
            color: $light-black-color;
          }

          input {
            border: none;
            background-color: $btn-color;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 400;
            color: $primaryBlack-color !important;
            padding: 10px 20px;
            width: 100%;
            margin-bottom: 10px;
          }

          input:focus {
            outline: none;
          }

          .BannerListingSearchBtn {
            background-color: #df1681;
            border: none;
            border-radius: 5px;
            color: #fff;
            margin-right: 10px;
            padding: 10px;
            width: 100%;
          }

          .BannerListingOr {
            text-align: center;
            padding-top: 10px;
          }

          .BannerListingLatestBtn {
            background-color: $btn-color;
            border: none;
            border-radius: 5px;
            color: $primaryBlack-color !important;
            margin-right: 10px;
            padding: 10px;
            width: 100%;
          }
        }
      }

      @include midmobile {
        grid-template-columns: 1fr;
      }

      margin-bottom: 20px;

      .InputFieldBox {
        p {
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          margin-bottom: 10px;
          color: $light-black-color;
        }

        input {
          border: none;
          background-color: $btn-color;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 400;
          color: $primaryBlack-color !important;
          padding: 10px 20px;
          width: 100%;
          margin-bottom: 10px;
        }

        input:focus {
          outline: none;
        }

        .CreditInput {
          width: 75%;
          margin-left: 10px;
        }

        select {
          background-color: #e7e7e7;
          border: none;
          border-radius: 5px;
          color: #1a1818 !important;
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 10px;
          padding: 10px 20px;
          width: 100%;
        }
      }
    }

    .MinimumCreditsWrap {
      margin-bottom: 20px;

      p {
        font-size: 14px;
      }

      img {
        position: relative;
        top: -5px;
      }
    }
  }

  .saveButton {
    border-radius: 5px;
    background: $pink-color;
    border: none;
    color: $White-color;
    padding: 20px;
  }

  .disabledButton {
    cursor: not-allowed;
    border-radius: 5px;
    background: $grayBG-color;
    border: none;
    color: $White-color;
    padding: 20px;
  }

  .disabledButton>* {
    pointer-events: none;
  }

  .divWrap {
    margin-bottom: 20px;

    @include smallmobile {
      width: 100%;
    }

    .uploadMain {
      label {
        width: 100%;

        .PhotoUpload {
          width: 100%;
          height: 170px;
          border: none;
          background-color: $btn-color;
          border-radius: 5px;
          padding: 10px;
          overflow: auto;
          @include flex;

          .AllImgWrap {
            display: flex;
            flex-wrap: wrap;

            img {
              width: 60px;
              height: 60px;
              border-radius: 5px;
              margin-right: 10px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 14px;
      color: $light-black-color;
    }

    input {
      padding: 15px;
      border-radius: 5px;
      background-color: $btn-color;
      border: none;
      width: 100%;
    }

    input:focus {
      outline: none;
    }

    &:last-child {
      margin-left: 20px;

      @include smallmobile {
        margin-left: unset;
      }
    }
  }

  .SelectMainImg {
    margin-bottom: 20px;

    @include smallmobile {
      width: 100%;
    }

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 14px;
      color: $light-black-color;
    }

    .SelectImgWrap {
      width: 100%;
      height: 170px;
      border: none;
      background-color: $btn-color;
      border-radius: 5px;

      .MainImgWrap {
        display: flex;
        flex-wrap: wrap;

        .imgActive {
          width: 60px;
          height: 60px;
          border-radius: 5px;
          margin-right: 10px;
          margin-bottom: 10px;
          border: 1px solid $pink-color;
        }

        .imgDeactive {
          width: 60px;
          height: 60px;
          border-radius: 5px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.5;
          border: none !important;
        }
      }
    }
  }
}
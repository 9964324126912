@import "../../../App.scss";
.NeedHelpSection {
  margin-top: 50px;
  h1 {
    font-size: 32px;
    font-family: $Poppins-SemiBold;
    margin: 0;
    margin-bottom: 30px;
  }
  .HelpWrap {
    margin-bottom: 30px;
    .NeedHelpImageSection {
      display: flex;
      @include midmobile {
        flex-wrap: wrap;
      }
      .leftImg {
        cursor: pointer;
        margin-right: 20px;
        flex: 0.5;
        @include midmobile {
          margin-right: unset;
          margin-bottom: 20px;
          flex: unset;
        }
        .NeedImageWrap {
          width: 100%;
          img {
            border-radius: 20px;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .rigthImg {
        cursor: pointer;
        flex: 0.5;
        @include midmobile {
          flex: unset;
        }
        .NeedImageWrap {
          width: 100%;
          img {
            border-radius: 20px;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    s .NeedImageWrap {
      width: 100%;
      img {
        border-radius: 20px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@import "../../App.scss";
.OrgMainContainer {
  //   display: flex;
  //   flex-direction: column;

  .OrgBannerContainer {
    width: 100%;
    height: 412px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .AdsWrap {
    display: flex;
    justify-content: center;
    max-height: 600px; /* Set the maximum height */
  }

  .Banner300x600 {
    max-width: 300px; /* Set the maximum width for 300*600 */
    width: 100%;
    cursor: pointer;
  }

  .Banner160x600 {
    max-width: 160px; /* Set the maximum width for 160*600 */
    width: 100%;
    cursor: pointer;
  }
  .OrgContainer {
    display: flex;
    justify-content: space-between;
    @include midmobile {
      display: unset;
    }

    .OrgInfoContainer {
      
      .OrgContent {
    
        margin: 20px 0px;
        // box-shadow: 3px 3px 10px 5px rgba(0, 0, 0, 0.16);
        border-radius: 15px;
        //   padding: 20px;
        display: flex;
        width: 100%;
        @include tablet {
          flex-wrap: wrap;
          justify-content: unset;
        }
        .OrgLogoWrap {
          max-width: 150px;
          width: 100%;
          // height: 150px;
          padding: 10px;
          @include tablet {
            margin-bottom: 15px;
          }
          @include midmobile {
            padding: unset;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .OrgContentWrap {
          margin-left: 10px;
          @include tablet {
            margin-left: 10px;
          }
          h1 {
            font-size: 32px;
            font-weight: 400;
            margin: 0;
            margin-bottom: 15px;
            // -ms-word-break: break-word;
            // word-break: break-word;
            // white-space: pre-wrap;
            // max-width: 500px;
            max-width: 500px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @include smallmobile {
              font-size: 26px;
            }
            @include midmobile {
              max-width: 100px;
            }
          }
          p {
            font-size: 16px;
            font-weight: 500;
            margin: 0;
            font-family: $Poppins-Medium;
            color: $light-black-color;
            margin-bottom: 15px;
            max-width: 90%;
            width: 100%;
          }
          .OrgInfoWrap {
            display: flex;

            @include midlarge{
               flex-wrap: wrap;
               row-gap: 10px;
            }
           
            
  
            @include smallmobile {
              flex-wrap: wrap;
            }
            .OrgInfo {
              display: flex;
              align-items: center;
              margin-right: 30px;
              @include smallmobile {
                margin-right: unset;
                margin-bottom: 10px;
              }
              img,
              svg {
                width: 14px;
                height: 14px;
                margin-right: 10px;
              }
              a {
                text-decoration: unset;
                font-size: 16px;
                font-weight: 400;
                margin: 0;
                font-family: $Poppins-Regular;
                color: $light-black-color;
                -ms-word-break: break-word;
                word-break: break-word;
                white-space: pre-wrap;
              }
            }
          }
        }
      }
      .DescriptionWrap {
        // padding: 20px;
        h6 {
          font-size: 16px;
          font-weight: 400;
          margin: 0;
          margin-bottom: 10px;
          color: $light-black-color;
          font-family: $Poppins-SemiBold;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          margin: 0;
          max-width: 70%;
          font-family: $Poppins-Regular;
          @include mobile {
            width: 100%;
            max-width: unset;
          }
        }
      }
      .EmployeInfo {
        margin-top: 20px;
        h6 {
          font-size: 16px;
          font-weight: 400;
          margin: 0;
          margin-bottom: 10px;
          color: $light-black-color;
          font-family: $Poppins-SemiBold;
        }
        .EmployeInfoWrap {
          display: flex;
          flex-wrap: wrap;
          .SingleEmployeInfo {
            display: flex;
            margin-right: 30px;
            @include tablet {
              flex-wrap: wrap;
              margin-bottom: 20px;
            }
            .EmployeImg {
              width: 80px;
              height: 80px;
              border-radius: 50%;
              margin-right: 15px;
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
            }
            .EmployeDetails {
              .EmployeName {
                font-size: 14px;
                font-weight: 500;
                color: $pink-color;
                margin: 0;
              }
              p {
                font-size: 12px;
                font-weight: 400;
                margin: 0;
              }
            }
          }
        }
      }
    }
    .OrgMapContainer {
      margin-top: 20px;
  
      .MapContainer {
        // max-width: 500px;

        iframe{
          width: 350px;
          height: 250px;

          @include tablet {
            width: 100%;
            height: 250px;
          }

          @include mobile {
            width: 100%;
            height: 250px;
          }

        }
        
      }
      .OrgAddresContainer {
        h6 {
          margin-top: 10px;
        }
        p {
          margin: 0px;
        }
        a {
          text-decoration: unset;
          font-size: 16px;
          font-weight: 400;
          margin: 0;
          font-family: $Poppins-Regular;
          color: $light-black-color;
          -ms-word-break: break-word;
          word-break: break-word;
          white-space: pre-wrap;
        }
      }
    }
  }
  .SearchMainContainer {
    margin-top: 20px;
    background-color: $grayBG-color;
    .SearchBar {
      padding: 20px 0px;
    }
  }

  h6 {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 10px;
    color: $light-black-color;
    font-family: $Poppins-SemiBold;

    @include mobile {
      margin-top: 22px;
    }
  }

  .TopImageContainer {
    position: relative;
    width: calc((100% - 1236px) / 2);
    @include midlarge {
      width: calc((100% - 1026px) / 2);
    }
    @include desktopMax {
      display: none;
    }
    height: 150px;
    text-align: right;
    .BackgroundTop {
      margin-top: 57px;
      @include largescreenDesktop {
        margin-left: -47px;
      }
    }
  }

  .PropertyAndAdContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 50px;
    .propertyList {
      width: 68%;
      @include midmobile {
        width: unset;
      }
      .Pagination {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        .pageText {
          color: $btn-text-color;
          margin-right: 15px;
        }
        .PageWrap {
          display: flex;
          .page {
            margin-right: 10px;
            .Active {
              font-weight: 900;
              cursor: pointer;
            }
            .InActive {
              color: #1a1818;
              cursor: pointer;
            }
          }
        }
        .NextPageActive {
          font-weight: 900;
          cursor: pointer;
          cursor: pointer;
          margin-right: 10px;
          span {
            @include smMobile {
              display: none;
            }
          }
          :last-child {
            margin-right: unset;
          }
        }
        .NextPageInActive {
          color: #1a1818;
          cursor: pointer;
          margin-right: 10px;
          span {
            @include smMobile {
              display: none;
            }
          }
          :last-child {
            margin-right: unset;
          }
        }
      }
    }
    .AdsList {
      width: 32%;
      margin-bottom: 20px;
      @include midmobile {
        width: unset;
      }
    }
    @include midmobile {
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
    }
  }
}

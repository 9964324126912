@import "../../App.scss";
.AddEmployeeContainer {
	.AddEmployeeHeading {
		margin-bottom: 15px;
		h1 {
			font-size: 32px;
			font-weight: 400;
			color: $primaryBlack-color;
		}
	}
	.AddEmployeeDescription {
		max-width: 550px;
		width: 100%;
		.InputContainer {
			p {
				font-size: 16px;
				font-family: $Poppins-SemiBold !important;
				margin: 0;
				margin-top: 4%;
				color: $gray-color-font;
			}
			input {
				border-radius: 5px;
				margin-top: 15px;
				background: $btn-color;
				width: 100%;
				border: none;
				padding: 15px;
				font-size: 16px;
				font-weight: 300;
				color: $primaryBlack-color;
			}
			input:focus {
				outline: none;
			}
			select {
				border: none;
				margin-top: 15px;
				background-color: $btn-color;
				border-radius: 5px;
				font-size: 16px;
				font-weight: 400;
				color: $primaryBlack-color !important;
				padding: 10px 20px;
				width: 100%;
				-webkit-appearance: none;
				background-image: url(../../assets/icons/DropdownIcon.svg);
				background-position: right 15px center;
				background-repeat: no-repeat;
			}
		}
		.PhoneAndEmailContainer {
			display: flex;
			// justify-content: space-between;
			// flex-wrap: wrap;
			@include midmobile {
				display: unset;
			}
			.InputContainer {
				width: 50%;
				margin-right: 20px;
				@include midmobile {
					width: 100%;
					margin-right: unset;
				}
				&:last-child {
					margin-right: unset;
				}
			}
		}
	}
	.UploadPictureEmployee {
		p {
			font-size: 16px;
			font-family: $Poppins-SemiBold !important;
			margin: 0;
			margin-top: 4%;
			color: $gray-color-font;
		}
		.UploadImgWrap {
			width: 190px;
			position: relative;
			margin-top: 30px;
			.ImageWrap {
				width: 170px;
				height: 170px;
				border-radius: 50%;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			label {
				position: absolute;
				right: 6px;
				bottom: 0;
				cursor: pointer;
				img {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
	.AddEmployeeBtn {
		margin-top: 38px;
		button {
			padding: 10px 15px;
			border-radius: 5px;
			background: $pink-color;
			border: none;
			@include fontStyle;
			color: $White-color;
			min-width: 180px;
		}
	}
}

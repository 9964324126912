@import "../../App.scss";
.BackgroundTopContainer {
  position: relative;
  width: calc((100vw - 1236px) / 2);
  @include midlarge {
    width: calc((100vw - 1026px) / 2);
  }
  @include desktopMax {
    display: none;
  }

  height: 150px;
  text-align: right;
  .BackgroundTop {
    margin-top: 18px;
    // position: absolute;
    // top: 18px;
    // z-index: 1;
    // right: 2%;
    // bottom: -1px;
    // @include desktopMax {
    //   top: 38px;
    //   display: none;
    // }
    // @include midmobile {
    //   display: none;
    // }
  }
}

.ContainerMain {
  max-width: 1236px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 10px;
  @include midlarge {
    max-width: 1026px;
    padding: 0px 10px;
  }
  @include tabletNew {
    max-width: 940px;
    padding: 0px 10px;
  }
  @include mobile {
    max-width: 1026px;
    padding: 0px 10px;
  }
  @include midmobile {
    max-width: 1026px;
    padding: 0px 10px;
  }
}
.ContactAndAdsWrap {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  .ContactContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  @include midmobile {
    display: unset;
  }
  @include tabletNew {
    margin-top: 50px;
  }

  .ListingOrgImg {
    width: 90%;
    display: flex;
    justify-content: flex-end;
    padding: 50px 0px;
    background-color: $grayBG-color;
    border-radius: 0px 20px 20px 0px;
    padding-left: calc((100vw - 1236px) / 2);
    @include midlarge {
      padding-left: calc((100vw - 1026px) / 2);
    }
    @include tabletNew {
      padding-left: calc((100vw - 940px) / 2);
      width: unset !important;
    }

    .FormContainer {
      width: 90%;
      margin-right: 75px;
      h5 {
        font-size: 16px;
        font-family: $Poppins-SemiBold;
        word-break: break-word;
        white-space: pre-wrap;
      }
      @include mobile {
        margin-right: unset;
      }
      @include tabletNew {
        margin-left: 0px;
        padding: 30px 24px;
      }
      @include midmobile {
        margin-left: unset;
        padding: 14px;
        width: 100%;
      }
    }

    @include mobile {
      width: 50%;
      border-right: unset;
      padding: 0px 20px;
    }
    @include midmobile {
      width: 100%;
      margin: 20px 0px;
      padding: unset;
      border-radius: unset !important;
    }
    h5 {
      font-size: 16px;
      font-weight: 400;
      margin: 0;
      margin-bottom: 15px;
    }
    .Inputbox {
      margin-bottom: 10px;
      input {
        border: none;
        background-color: $light-white-color;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 400;
        color: $primaryBlack-color !important;
        padding: 10px;
        width: 100%;
      }
      input:focus {
        outline: none;
      }
    }

    button {
      background-color: $black-color;
      color: $White-color;
      padding: 10px 0px;

      width: 100%;
      max-width: 200px;
      border: none;
      border-radius: 5px;
      @include midmobile {
        max-width: unset;
      }
    }
  }
  .LeftBox {
    // width: 25%;
    padding-left: 10px;
    padding-right: calc((100% - 1236px) / 2);
    @include midlarge {
      padding-right: calc((100% - 1026px) / 2 + 10px);
    }
    @include tabletNew {
      padding-right: calc((100% - 940px) / 2 + 10px);
    }
    @include midmobile {
      padding-left: unset;
    }
  }
  .AdsContentWrap {
    min-width: 160px;
    max-height: 600px;

    .Banner300x600 {
      max-width: 300px;
      width: 100%;
      cursor: pointer;
    }

    .Banner160x600 {
      max-width: 160px;
      width: 100%;
      cursor: pointer;
    }
    .Banner336x280 {
      max-width: 336px;
      width: 100%;
      cursor: pointer;
    }

    @include midmobile {
      margin: 0px auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

@import "../../App.scss";
.FavouritePropretSection {
  padding-top: 50px;
  padding-bottom: 50px;
  .FavouritePropretWrap {
    display: flex;
    @include mobile {
      flex-wrap: wrap;
    }
    .LeftSide {
      width: 25%;
      margin-right: 30px;
      @include mobile {
        width: 100%;
        margin-right: unset;
      }
      .AccountMainWrap {
        @include mobile {
          display: none;
        }
      }
      .BtnWrap {
        display: none;
        @include mobile {
          @include flex;
          margin-bottom: 20px;
        }
        button {
          background-color: $grayBG-color;
          padding: 10px 20px;
          border: none;
          border-radius: 10px;
          margin-right: 10px;
          width: 50%;
          @include flex;
          svg {
            font-size: 20px;
          }
        }
      }
    }
    .Rightside {
      width: 75%;
      @include mobile {
        width: 100%;
      }
      .FavouriteSection {
        margin-bottom: 30px;
        box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.16);
        padding: 30px 20px;
        border-radius: 15px;
        background-color: $White-color;
        .TitleWrap {
          display: flex;
          align-items: end;
          justify-content: space-between;
          @include mobile {
            display: unset;
            margin-bottom: 10px;
          }
          h1 {
            font-size: 32px;
            font-family: $Poppins-SemiBold;
          }
          p {
            font-size: 16px;
            font-weight: 400;
            margin: 0;
          }
        }
        .LoaderWrap {
          @include flex;
          width: 100%;
          height: 100vh;
        }
        .Pagination {
          margin-top: 15px;
          display: flex;
          justify-content: space-between;
          .pageText {
            color: $btn-text-color;
            margin-right: 15px;
          }
          .PageWrap {
            display: flex;
            .page {
              margin-right: 10px;
              .Active {
                font-weight: 900;
                cursor: pointer;
              }
              .InActive {
                color: #1a1818;
                cursor: pointer;
              }
            }
          }
          .NextPageActive {
            font-weight: 900;
            cursor: pointer;
            cursor: pointer;
            margin-right: 10px;
            span {
              @include smMobile {
                display: none;
              }
            }
            :last-child {
              margin-right: unset;
            }
          }
          .NextPageInActive {
            color: #1a1818;
            cursor: pointer;
            margin-right: 10px;
            span {
              @include smMobile {
                display: none;
              }
            }
            :last-child {
              margin-right: unset;
            }
          }
        }
      }
    }
  }
}

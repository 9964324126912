@import "../../App.scss";

.SavedFilterSection {
  .TitleWrap {
    display: flex;
    align-items: end;
    justify-content: space-between;
    @include mobile {
      display: unset;
      margin-bottom: 10px;
    }
    h1 {
      font-size: 32px;
      font-family: $Poppins-SemiBold;
    }
    p {
      font-size: 16px;
      font-family: $Poppins-SemiBold;
      margin: 0;
    }
  }
  .BtnWarp {
    margin-top: 10px;
    .TypeOfListing {
      width: 100%;
      button {
        background-color: $btn-color;
        border: none;
        border-radius: 5px;
        padding: 15px 30px;
        margin-right: 10px;
        max-width: 200px;
        @include midmobile {
          width: 100%;
          max-width: unset;
          margin-bottom: 10px;
        }
        &:hover {
          background-color: $pink-color;
          color: $White-color;
        }
      }
      .SelectedBtn {
        background-color: $pink-color;
        color: $White-color;
      }
    }
  }
  .SavedFilterFormWrap {
    max-width: 550px;
    width: 100%;
    .divWrap {
      margin-top: 10px;
      input::-webkit-inner-spin-button {
        display: none;
      }
      input {
        width: 100%;
        padding: 15px;
        border-radius: 5px;
        background: #e7e7e7;
        border: none;
        &:focus {
          outline: none;
        }
      }
      .pricerangewrapp {
        display: flex;
        @include smallmobile {
          flex-wrap: wrap;
        }
        input::-webkit-inner-spin-button {
          display: none;
        }
        input {
          &:last-child {
            margin-left: 20px;
            @include smallmobile {
              margin-top: 10px;
              margin-left: unset;
            }
          }
        }
      }
      select {
        background-color: $btn-color;
        color: $black-color;
        border-radius: 5px;
        border: none;
        padding: 15px 10px;
        margin-right: 10px;
        @include smallmobile {
          margin: unset;
          width: 100%;
        }
      }

      hr {
        border: 1px solid $black-color;
      }
    }
    .PropertyselectOrption {
      .EnergyLabel {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        p {
          margin-top: 17px;
        }
        .radioCustom {
          opacity: 0;
          position: absolute;
        }
        .radioCustom,
        .radioCustomLabel {
          display: inline-block;
          vertical-align: middle;
          margin-right: 7px;
          cursor: pointer;
        }
        .radioCustomLabel {
          position: relative;
        }
        .radioCustom + .radioCustomLabel:before {
          content: "";
          background: #fff;
          border: 1.5px solid #595f72;
          display: inline-block;
          vertical-align: middle;
          border-radius: 2px;
          padding: 7px;
          text-align: center;
        }
        .radioCustom + .radioCustomLabel:before {
          border-radius: 5px;
        }
        .radioCustom:checked + .radioCustomLabel:before {
          background: url(../../assets/icons/RightIcon.svg);
          background-size: 10px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
  .MainTitle {
    font-size: 16px;
    font-family: $Poppins-SemiBold;
    line-height: 16px;
    margin: 0;
    margin: 15px 0px;
  }
  .SaveFilterBtnWrap {
    margin-top: 20px;

    .saveButton {
      border-radius: 5px;
      background: $pink-color;
      border: none;
      color: $White-color;
      padding: 20px;
      min-width: 180px;
      @include midmobile {
        margin-bottom: 15px;
        width: 100%;
      }
    }
    .searchButton {
      border-radius: 5px;
      background: $light-black-color;
      border: none;
      color: $White-color;
      padding: 20px;
      margin-left: 20px;
      @include midmobile {
        margin-left: unset;
        width: 100%;
      }
    }
  }
}

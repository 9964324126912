@import "../../../App.scss";
$mainpink: #DF1881;
$subpink: #FF5FB3;
$maingray: #CECECE;
$subgray: #6E7072;

$startpointmobile: 769px;
$startpointtablet: 991px;
$medpointtablet: 1199px;
$smallscreen: 1200px;
$mediumscreen: 1400px;

section.magazinetable {
  padding: 50px 0;
  justify-content: center;

  .container {
    width: 90%;

    h2 {

      font-size: 2em;
      margin-bottom: 1.5rem;
      margin-top: 0;
    }

    .pinkbutton {
      margin-top: 10px;
      float: right;
      border-radius: 9999px;
      border: none;
      padding: 10px 20px;
      background: $mainpink;
      color: #ffffff;
      font-weight: 600;
      transition: background 0.3s ease, color 0.3s ease;
      font-size: 1.25em;

      svg {
        margin-right: 5px;
      }

      &:hover {
        background: $subpink;
      }
    }

  }

  .info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    .data {
      display: flex;
      flex-direction: column;

      span:first-child {
        color: $subgray;
      }

      span:last-child {
        color: $subgray;
        font-weight: bold;
      }
    }

    .fill_summaries {
      .pinkbutton {
        margin-top: 0;
        margin-left: 10px;
        max-height: 40px;
        padding: 5px 20px;
      }
    }

    .search {
      position: relative;
      display: inline-block;
      width: 100%;
      max-width: 350px;

      input {
        border-radius: 9999px;
        padding: 12px 20px;
        font-size: 1em;
        font-weight: 600;
        border: none;
        outline: none;
        transition: background 0.3s ease, color 0.3s ease;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $subgray;
        padding-right: 48px;
        min-width: 300px;
        height: 40px;

        &::placeholder {
          font-weight: 300;
        }
      }

      .search_button {
        position: absolute;
        top: 0;
        right: 0;
        height: 2.5em;
        width: 40px;
        background: $mainpink;
        color: #ffffff;
        border: none;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1em;
        cursor: pointer;
        transition: background 0.3s ease;

        &:hover {
          background: darken($mainpink, 10%);
        }
      }
    }
  }

  .table {
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    overflow: hidden;
    padding: 1rem;
    background-color: #fff;

    .item {
      display: grid;
      grid-template-columns: 2fr 3fr 2fr 2fr 1fr;
      align-items: center;
      gap: 2rem;
      padding: 7px;

      .checkbox_dropdown {
        display: flex;
        align-items: center;
        gap: 1rem;
        width: 100%;

        div {
          p {
            display: none;
          }
        }

        .domain_checkbox {
          appearance: none;
          flex-shrink: 0;
          width: 16px;
          height: 16px;
          border: 1px solid $subgray;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s ease, border-color 0.3s ease;

          &:checked {
            background-color: $mainpink;
          }
        }

        .dropdown {
          padding: 8px 20px;
          border: 1px solid $maingray;
          border-radius: 9999px;
          flex-grow: 1;
          min-width: 200px;
        }
      }

      .address,
      .city,
      .price {
        font-size: 1rem;
        color: #333;
      }

      .icons {
        display: flex;
        justify-content: space-around;

        a {
          color: $subgray;

          i {
            cursor: pointer;
            font-size: 1.2rem;

            &:hover {
              color: $mainpink;
            }
          }
        }
      }
    }
  }

  .pagination {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pagination_numbers {
      display: flex;
      align-items: center;
      gap: 5px;

      button,
      .pagination_button {
        background-color: #fff;
        color: $subgray;
        border: 1px solid $subgray;
        border-radius: 9999px;
        padding: 5px 10px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        min-width: 31.2px;
        min-height: 36px;

        &.active {
          background-color: $maingray;
          border: $maingray;
        }

        &:hover:not(.active) {
          background-color: $maingray;
        }
      }
    }

    .pagination_buttons {
      display: flex;
      gap: 10px;

      .button {
        color: $subgray;
        background: #fff;
        border: 1px solid $subgray;
        border-radius: 9999px;
        padding: 5px 10px;
        text-decoration: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
        font-weight: 400;
      }
    }

  }

}

.DemoUpdateControls {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.DemoUpdateChart {
  margin-right: 1em;
}

.DemoUpdateChartCircle {
  transform: rotate(-90deg);
  transform-origin: center;
}

.DemoUpdateChartCharacters {
  font-size: 13px;
  font-weight: bold;
}

.DemoUpdateChartCircleLimitClose {
  transform: rotate(-90deg);
  transform-origin: center;
  stroke: hsl(30, 100%, 52%);
}

.DemoUpdateChartCircleLimitExceeded {
  transform: rotate(-90deg);
  transform-origin: center;
  stroke: hsl(0, 100%, 52%);
}

.ConnectionContainer {
  margin-left: 10px;

  .TitleWrap {
    display: flex;
    align-items: end;
    justify-content: space-between;

    @include mobile {
      display: unset;
      margin-bottom: 10px;
    }

    h1 {
      font-size: 32px;
      font-weight: 400;
    }
  }

  .MagazinesTitles {
    width: 100%;
    display: grid;
    grid-template-columns: 10% 30% 20% 10% 10% 10%;
    grid-column-gap: 10px;
    justify-content: space-between;
    align-items: center;
  }

  .AdKindRow:first-of-type {
    margin-top: 10px;
  }

  .AdKindRow {
    display: grid;
    grid-template-columns: 25% 25% 25% 1fr;
    grid-column-gap: 5px;
    font-weight: normal;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }


  .PropertyRow {
    display: grid;
    grid-template-columns: 5% 20% 25% 20% 25% 5% 1fr;
    grid-column-gap: 5px;
    font-size: 14px;

    div {
      img {
        cursor: pointer;
      }
    }

    .select {
      position: relative;
      top: -15px;
      font-size: 12px;
    }

    .radioCustom {
      opacity: 0;
      position: absolute;
    }

    .radioCustom,
    .radioCustomLabel {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
    }

    .radioCustomLabel {
      position: relative;
    }

    .radioCustom+.radioCustomLabel:before {
      content: "";
      background: #fff;
      border: 1.5px solid #595f72;
      display: inline-block;
      vertical-align: middle;
      border-radius: 2px;
      padding: 7px;
      text-align: center;
    }

    .radioCustom+.radioCustomLabel:before {
      border-radius: 5px;
    }

    .radioCustom:checked+.radioCustomLabel:before {
      background: url(../../../assets/icons/RightIcon.svg);
      background-size: 10px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .PropertyRowActive {
    display: grid;
    grid-template-columns: 5% 20% 25% 20% 25% 5% 1fr;
    grid-column-gap: 5px;
    font-size: 14px;
    color: green;
    font-weight: 600;

    div {
      img {
        filter: invert(30%) sepia(90%) saturate(5605%) hue-rotate(104deg) brightness(93%) contrast(106%);
        cursor: pointer;
      }
    }

    .select {
      position: relative;
      top: -15px;
      font-size: 12px;
    }

    .radioCustom {
      opacity: 0;
      position: absolute;
    }

    .radioCustom,
    .radioCustomLabel {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
    }

    .radioCustomLabel {
      position: relative;
    }

    .radioCustom+.radioCustomLabel:before {
      content: "";
      background: #fff;
      border: 1.5px solid #595f72;
      display: inline-block;
      vertical-align: middle;
      border-radius: 2px;
      padding: 7px;
      text-align: center;
    }

    .radioCustom+.radioCustomLabel:before {
      border-radius: 5px;
    }

    .radioCustom:checked+.radioCustomLabel:before {
      background: url(../../../assets/icons/RightIcon.svg);
      background-size: 10px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .PropertyRowOverflow {
    display: grid;
    grid-template-columns: 5% 20% 25% 20% 25% 5% 1fr;
    grid-column-gap: 5px;
    font-size: 14px;
    color: red;
    font-weight: 400;

    div {
      img {
        filter: invert(11%) sepia(95%) saturate(6090%) hue-rotate(357deg) brightness(98%) contrast(114%);
        cursor: pointer;
      }
    }

    .select {
      position: relative;
      top: -15px;
      font-size: 12px;
    }

    .radioCustom {
      opacity: 0;
      position: absolute;
    }

    .radioCustom,
    .radioCustomLabel {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
    }

    .radioCustomLabel {
      position: relative;
    }

    .radioCustom+.radioCustomLabel:before {
      content: "";
      background: #fff;
      border: 1.5px solid #595f72;
      display: inline-block;
      vertical-align: middle;
      border-radius: 2px;
      padding: 7px;
      text-align: center;
    }

    .radioCustom+.radioCustomLabel:before {
      border-radius: 5px;
    }

    .radioCustom:checked+.radioCustomLabel:before {
      background: url(../../../assets/icons/RightIcon.svg);
      background-size: 10px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .PropertiesContainer {
    width: 100%;
    font-size: 12px;
    border-radius: 5px;
    border: 0.5pt solid #d2d2d2;
    margin-bottom: 5px;
    padding: 5px;
  }

  .Pagination {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;

    .pageText {
      color: $btn-text-color;
      margin-right: 15px;
    }

    .PageWrap {
      display: flex;

      .page {
        margin-right: 10px;

        .Active {
          font-weight: 900;
          cursor: pointer;
        }

        .InActive {
          color: #1a1818;
          cursor: pointer;
        }
      }
    }

    .NextPageActive {
      font-weight: 900;
      cursor: pointer;
      cursor: pointer;
      margin-right: 10px;

      span {
        @include smMobile {
          display: none;
        }
      }

      :last-child {
        margin-right: unset;
      }
    }

    .NextPageInActive {
      color: #1a1818;
      cursor: pointer;
      margin-right: 10px;

      span {
        @include smMobile {
          display: none;
        }
      }

      :last-child {
        margin-right: unset;
      }
    }
  }


  .AdKindContainer {
    width: 100%;
    font-size: 12px;
    border-radius: 5px;
    border: 0.5pt solid #d2d2d2;
    margin-bottom: 5px;
    padding: 5px;
    font-weight: 600;

    span {
      cursor: pointer;
    }

    button.AddAdKind {
      background-color: #e7e7e7;
      border: none;
      border-radius: 5px;
      padding: 5px 15px;
      margin-right: 10px;
      max-width: 200px;
      float: right;
    }


  }

  .MagazineContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 30% 15% 15% 10% 10% 10% 1fr;
    grid-column-gap: 5px;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    border-radius: 5px;
    border: 0.5pt solid #d2d2d2;
    margin-bottom: 5px;
    padding-left: 5px;


    div {
      text-align: center;

      .paid {
        color: green !important;
      }

      p {
        color: black;
        font-weight: bold;
        margin-bottom: 5px;

      }

      p:first-child {
        color: $gray-color-font;
        font-weight: normal;
      }
    }

    button.download {
      background-color: #e7e7e7;
      border: none;
      border-radius: 5px;
      padding: 5px 15px;
      margin-right: 10px;
      max-width: 200px;
      float: right;
    }

    button.pay {
      background-color: #df1681;
      border: none;
      border-radius: 5px;
      padding: 5px 15px;
      margin-right: 10px;
      max-width: 200px;
      color: #ffffff;
    }

    .Container {
      cursor: pointer;
      margin-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 120px;
        height: 120px;
        object-fit: contain;

        @include xxsMobile {
          width: 90px;
          height: 90px;
        }
      }

      p {
        margin: 0;
        margin-left: 20px;
        max-width: 400px;
        color: $gray-color-font;
        font-size: 16px;
        -ms-word-break: break-word;
        word-break: break-word;
        white-space: pre-wrap;

        @include smMobile {
          margin-left: 10px;
          margin-top: 20px;
          width: 200px;
          -ms-word-break: break-word;
          word-break: break-word;
          white-space: pre-wrap;
        }

        @include xxsMobile {
          white-space: nowrap;
          width: 80px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .CrosIconContainer {
      display: flex;
      flex-direction: column;
      height: 100%;
      cursor: pointer;
      margin-right: 15px;

      @include smMobile {
        margin-right: unset;
      }

      img {
        width: 16px;
        height: 16px;
      }

      svg {
        font-size: 19px;
        color: $sizetext-color;
        margin-top: 10px;
      }
    }

    // border-bottom: 2px solid #d2d2d2;
  }

  .IconWrapper {
    margin-top: 5px;

    a {
      text-decoration: none;
      color: inherit;
    }

    .IconContainer {
      cursor: pointer;
      margin-top: 9px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      float: left;

      p {
        padding-left: 5px;
        margin-top: 3px;
        font-size: 18px;
        font-weight: 400;
        line-height: 18px;
        color: $pink-color;
        margin: 0;
      }
    }

    .HideContainer {
      margin-top: 9px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      p {
        padding-left: 5px;
        margin-top: 3px;
        font-size: 18px;
        font-weight: 400;
        line-height: 18px;
        color: $sizetext-color;
        margin: 0;
      }
    }
  }
}
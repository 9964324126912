@import "../../../App.scss";
.LatestListingSection {
  margin-top: -100px;
  h1 {
    text-align: center;
    margin-bottom: 30px;
    color: white;
font-size: 24px;
font-family: $Poppins-Light;
line-height: 36px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
@include smallmobile{
  margin-bottom: 5px;
}
  }
  .ListingCards {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 20px;
    @include max-width-1300{
      grid-template-columns: repeat(3, 1fr);
    }
    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }
    @include xxsMobile {
      grid-template-columns: 1fr;
    }
    .CardMain {
      .CardImg {
        width: 100%;
        height: 175px;
        cursor: pointer;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 15px;
        }
        .PropertyStatus {
          bottom: 10px;
          left: 10px;
          padding: 5px;
          position: absolute;
          font-size: 11px;
          border-radius: 5px;
          color: $White-color;
          background-color: $pink-color;
        }
      }
      .CardContentWrap {
        margin: 15px 0px;
        h5 {
          font-size: 16px;
          font-family: $Poppins-SemiBold;
          margin: 0;
          cursor: pointer;
        }
        span {
          font-size: 16px;
          font-weight: 400;
          cursor: pointer;
        }
        p {
          font-size: 16px;
          font-family: $Poppins-SemiBold;
          margin: 0;
          margin: 10px 0px;
        }
        .CardLink {
          font-size: 16px;
          font-weight: 400;
          color: $pink-color;
          margin: 0;
          cursor: pointer;
          white-space: nowrap;
          width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

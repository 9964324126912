@import "../../App.scss";
$mainpink: #DF1881;
$subpink: #FF5FB3;
$maingray: #CECECE;
$subgray: #6E7072;

$startpointmobile: 769px;
$startpointtablet: 991px;
$medpointtablet: 1199px;
$smallscreen: 1200px;
$mediumscreen: 1400px;

section,
footer,
main,
.page-content {
  width: 100%;
  position: relative;
}

.container {
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  box-sizing: border-box;
  margin: 0px auto;
  position: relative;
  z-index: 5;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
  margin: 0px auto;
  position: relative;
  z-index: 5;
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
  margin: 0px auto;
  position: relative;
  z-index: 5;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  margin: 25px;
}

.action_buttons {
  display: flex;
  gap: 12px;
}

.reverse {
  flex-direction: row-reverse;
}

li {
  list-style: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

#menuToggle {
  display: none;
}

@media screen and (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media screen and (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media screen and (min-width: 992px) {
  .container {
    max-width: 960px
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px
  }
}

@media screen and (min-width: 1400px) {
  .container {
    max-width: 1320px
  }
}

@media screen and (min-width: 1600px) {
  .container {
    max-width: 1620px
  }
}

@media screen and (min-width: 2400px) {
  .container {
    max-width: 1800px
  }
}

body {
  font-family: 'Poppins-Regular', sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 1.2em;
  color: $subgray;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  margin: 0;
}

a {
  text-decoration: none !important;
  color: #ffffff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins-Regular', sans-serif;
  z-index: 2;
  position: relative;
  line-height: 1.3em;
  font-weight: 700;
}

h1 {
  font-size: 4em;
}

h2 {
  font-size: clamp(30px, 1.5vw, 32px);
  margin-bottom: 0.8em;
  color: #D31775;
}

h3 {
  font-size: 2.4em;
  margin-bottom: 0.8em;
}

h4 {
  font-size: 1.8em;
  margin-bottom: 0.8em;
}

// p {
//   padding: 3px 0 1rem 0;
// }

p {
  font-family: 'Poppins-Regular', sans-serif;
  font-weight: 400;
  line-height: 1.6em;
}

a {
  color: #ffffff;
  font-family: 'Poppins-Regular', sans-serif;
  font-weight: 400;
  text-decoration: none;

}

.button {
  border-radius: 9999px;
  display: inline;
  padding: 10px 18px;
  background: $maingray;
  color: $subgray;
  font-size: 1em;
  font-weight: 600;
  transition: background 0.3s ease, color 0.3s ease;

  svg {
    margin-right: 5px;
  }

  &:hover {
    background: $mainpink;
    color: #ffffff;
  }
}

.pinkbutton {
  border-radius: 9999px;
  display: inline-block;
  padding: 15px 20px;
  background: $mainpink;
  color: #ffffff;
  font-size: 0.8em;
  font-weight: 600;
  transition: background 0.3s ease, color 0.3s ease;
  font-size: 1.25em;

  svg {
    margin-right: 5px;
  }

  &:hover {
    background: $subpink;
  }
}

.bigbutton {
  flex: 1 1 calc(25% - 15px);
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 25px 20px;
  background: $mainpink;
  color: #ffffff;
  font-weight: 800;
  transition: background 0.3s ease, color 0.3s ease;
  font-size: 1.20em;
  min-width: 150px;
  max-width: calc(25% - 15px);
  height: auto;

  svg {
    margin-right: 15px;
    font-size: 1.8em;
  }

  &:hover {
    background: $subpink;
  }
}

header {
  padding: 5px 0;
  box-shadow: 0 7px 72px 1px rgba(0, 0, 0, 0.2);

  .menu {
    a {
      color: $subpink;
    }
  }

  .account {
    display: flex;
    align-items: center;
    border: 1px solid $subpink;
    border-radius: 9999px;
    padding: 6px 12px;
    gap: 8px;

    a {
      display: flex;
      align-items: center;
      color: $subpink;

      svg {
        margin-right: 8px;
        width: 18px;
        height: 19px;
      }
    }
  }
}

section.subheader {
  padding-top: 50px;
  padding-bottom: 10px;

  .search {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 350px;

    input {
      border-radius: 9999px;
      padding: 12px 20px;
      font-size: 1em;
      font-weight: 600;
      border: none;
      outline: none;
      transition: background 0.3s ease, color 0.3s ease;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid $subgray;
      padding-right: 48px;
      min-width: 300px;
      height: 40px;

      &::placeholder {
        font-weight: 300;
      }
    }

    .search-button {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 40px;
      background: $mainpink;
      color: #ffffff;
      border: none;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1em;
      cursor: pointer;
      transition: background 0.3s ease;

      &:hover {
        background: darken($mainpink, 10%);
      }
    }
  }
}

section.eyecandy {
  aspect-ratio: 32/9;
  border-top: 1px solid $subgray;

  .image {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 32/9;
    }

    .container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .content {
        width: 35%;
        border-radius: 16px;
        background: rgba(255, 255, 255, 0.45);
        backdrop-filter: blur(7px);
        padding: 25px 35px;

        h2 {
          margin: 0;
        }

        p {
          color: $subgray;
        }
      }
    }
  }

  .buttons {
    position: absolute;
    z-index: 1000;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    padding: 23px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
    max-width: 90%;
    width: fit-content;
    align-items: stretch;
  }
}

section.woningcampagnes {
  margin-top: 75px;
  padding-bottom: 50px;

  h3 {
    font-size: 1.8em;
    color: $subpink;
    font-family: 'Poppins-Bold', sans-serif;
  }

  .grid {
    .addnewitem {
      background-color: #EDEDED;
      border-radius: 25px;
      box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.22);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 20px;

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        svg {
          font-size: 6em;
          color: $subgray;
        }

        p {
          margin: 0;
          font-size: 1.35em;
          color: $subgray;
          font-weight: 600;
        }
      }
    }

    .item {
      width: 100%;
      height: 100%;
      padding: 20px;
      border-radius: 25px;
      box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.12);
      text-align: center;

      p {
        color: $subgray;
      }


      .image {
        width: 100%;
        height: 80%;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          aspect-ratio: 3/2;
          border-radius: 8px;
        }

        .tags {
          position: absolute;
          bottom: 3%;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          gap: 5px;
          width: 100%;
          justify-content: center !important;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .tag {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            background-color: $subpink;
            color: #ffffff;
            padding: 5px 10px;
            border-radius: 4px;
            font-size: 0.9em;
            width: 25%;
            text-align: center;

            svg {
              font-size: 1.3em;
              margin-right: 5px;
            }

            span {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              text-align: left;
              width: 100%;

              .number {
                font-weight: bold;
                font-size: 1.1em;
              }

              .label {
                font-size: 0.9em;
              }
            }
          }
        }
      }

      .content {
        width: 100%;
        height: 20%;

        .flex {
          display: flex;
          gap: 25px;
          margin-bottom: 25px;
          margin-top: -10px;
        }

        .socialicons {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 15px;

          svg {
            font-size: 1.3em;
            color: $subgray;
          }
        }
      }
    }
  }
}

section.centertext {
  padding-top: 80px;

  .container {
    width: 60rem;

    h2 {
      color: #000;
      font-weight: 500;
      font-size: 1.8em;
    }
  }
}

section.box {
  padding: 25px 0;

  .container {
    width: 60rem;
    border-radius: 25px;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
    padding: 25px;

    span {
      margin-left: 15px;
      font-weight: 500;
    }

    .button {
      width: 100%;
      text-align: center;
      display: block;
    }

    h2 {
      color: #000;
      font-weight: 500;
      font-size: 1.8em;
      margin: 0 0 20px;
    }

    .grid-2 {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 20px;
      margin-top: 10px;

      .checkbox-wrapper {
        label {
          display: flex;
          align-items: center;
          padding: 10px 15px;
          border: 1px solid #989697;
          border-radius: 9999px;
          cursor: pointer;
          width: 100%;

          input[type="checkbox"] {
            appearance: none;
            width: 20px;
            height: 20px;
            border: 1px solid $subgray;
            border-radius: 4px;
            background-color: $maingray;
            cursor: pointer;
            transition: background-color 0.3s ease, border-color 0.3s ease;

            &:checked {
              background-color: $subpink;
            }
          }

          span {
            font-weight: 400;
            color: $subgray;
          }
        }
      }
    }

    .content {
      p {
        margin-bottom: 0;
      }

      .kaart {
        margin-bottom: 10px;
      }

      iframe {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }

      h2 {
        color: #000;
        font-weight: 500;
        font-size: 1.8em;
        margin: 0 0 20px;
      }

      .input-group {
        margin-bottom: 20px;

        span {
          display: block;
          margin-bottom: 5px;
          font-weight: 400;
          color: $subgray;
          margin-left: 15px;
        }

        input {
          padding: 10px 15px;
          border: 1px #989697 solid;
          border-radius: 9999px;
        }

        select {
          padding: 10px 15px;
          border: 1px #989697 solid;
          border-radius: 9999px;
        }
      }

      .full-width input {
        width: 100%;
      }

      .date-group {
        display: flex;
        justify-content: flex-start;
        gap: 20px;

        .half-width {
          width: 20%;

          input {
            width: 100%;
          }
        }
      }

      .new-group {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        >div {
          flex: 1;
          display: flex;
          flex-direction: column;

          &:first-child {
            flex: 2.75;
          }

          span {
            margin-bottom: 5px;
            font-weight: 500;
            color: $subgray;
            margin-left: 15px;
          }

          input {
            width: 100%;
            padding: 10px 15px;
            border: 1px #989697 solid;
            border-radius: 9999px;
          }
        }
      }
    }
  }
}

section.box.banner {
  .container {
    width: 65rem;

    .content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 20px;

      .banner {
        padding: 10px;
        border-radius: 8px;
        width: 100%;

        img {
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
        }

        &#banner-728x90 img {
          max-width: 728px;
          max-height: 90px;
        }

        &#banner-970x250 img {
          max-width: 970px;
          max-height: 250px;
        }

        &#banner-300x250 img {
          max-width: 300px;
          max-height: 250px;
        }

        &#banner-300x600 img {
          max-width: 300px;
          max-height: 600px;
        }

        &.fixed {
          justify-content: center;
          transition: justify-content 0.3s ease;
        }

        &.expanded {
          justify-content: flex-start;
        }

        &.hidden {
          display: none;
        }
      }

      .extend {
        display: block;
        text-align: center;
        margin-top: 15px;
        color: $subgray;
        font-weight: 500;
      }
    }
  }
}

footer {
  background-color: #1a1818;
  padding: 50px 0;
  border-bottom: 1px solid #ffffff;

  .container {
    justify-content: flex-start;
    align-items: baseline;
    gap: 70px;

    .block {
      h2 {
        font-size: 1em;
        color: #ffffff;
        font-weight: 600;
      }

      ul {
        padding: 0;

        a {
          font-size: 1em;
          line-height: 2.5em;
          font-weight: 300;
        }
      }
    }
  }
}

section.subfooter {
  background-color: #1a1818;
  padding: 35px 0;

  .container {
    align-items: center;

    p {
      margin: 0;
      padding: 0;
      color: #ffffff;
    }

    .copy {
      gap: 20px;
    }

    .socials {
      display: flex;
      gap: 15px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 40px;
        background-color: #ffffff;
        border-radius: 4px;
        transition: background-color 0.3s ease, transform 0.3s ease;

        svg {
          color: #000000;
          font-size: 1.2em;
        }

        &:hover {
          background-color: #f0f0f0;
          transform: scale(1.1);
        }
      }
    }

    .language {
      gap: 15px;
      display: flex;
    }
  }
}

#sidebar {
  position: fixed;
  top: 100px;
  right: 9rem;
  min-width: 280px;
  border-radius: 20px;
  padding: 25px;
  z-index: 100;
  height: fit-content;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
  background-color: #fff;

  h2 {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }

  span {
    display: block;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
  }

  p {
    font-size: 0.9rem;
    padding: 0;
    margin: 0;
    color: #555;
  }

  .campagneduur {
    padding: 5px 0;
  }

  .bereik {
    padding: 5px 0;
  }

  .sub {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    font-size: 1rem;

    p {
      margin: 0;
    }
  }

  .totaal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    p {
      margin: 0;
    }

    .main-price {
      color: #000;
      font-weight: bold;
      font-size: 1.2em;
    }

    .discount-price {
      color: $subpink;
      text-decoration: line-through;
    }
  }

  .pinkbutton {
    display: block;
    margin-top: 10px;
    text-align: center;
    color: #fff;
  }
}

.BannerContainer {
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.16);
  padding: 15px 15px;
  border-radius: 15px;
  min-height: 120px;

  .Banner {
    display: flex;
    align-items: top;

    .Left {
      width: 50%;
      display: inline-block;

      .BannerImageContainer {
        text-align: left;

        .BannerImage {
          border-radius: 5px;
          display: inline-block;
          margin-right: 10px;
          height: 80px;
          width: 120px;
        }
      }
    }

    .Middle {
      padding-right: 15px;
      width: 30%;
      display: inline-block;
      font-size: 12px;

      .EmptyDiv {
        margin-top: 10px;
        min-height: 16px;
      }

      .FilledDiv {
        margin-top: 10px;
        color: #505050;
        font-size: 10px;

        .BannerNumbers {
          img {
            margin-right: 10px;
          }
        }
      }
    }

    .Right {
      width: 20%;
      display: inline-block;
      text-align: right;
      padding-right: 15px;

      .BudgetType {
        color: #505050;
        font-size: 10px;
        margin-top: 10px;
      }

      .EditCampaign {
        position: relative;
        bottom: -30px;
        cursor: pointer;

        svg {
          width: 15px;
        }
      }
    }
  }

  .Overlay {
    transform: rotate(5deg);
    position: relative;
    top: 10px;
    left: -10%;
    height: 0px;
    text-align: center;

    * {
      box-sizing: border-box;
    }

    p {
      position: relative;
      display: inline-block;
      color: #df1681;
      padding: 15px;
      background-color: white;
      box-shadow: inset 0px 0px 0px 10px #df1681;
    }

    p:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url("../../assets/images/stamp.jpg");
      mix-blend-mode: lighten;
    }
  }
}

.IconWrapper {
  margin-top: 20px;
}

.MyLsitingSection {
  padding-top: 50px;
  padding-bottom: 50px;

  .AccountMainWrap {
    @include mobile {
      display: none;
    }
  }

  .AddListingBtn {
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.16);
    padding: 30px 13px;
    border-radius: 15px;
    background-color: $White-color;

    button {
      width: 100%;
      padding: 7% 2%;
      border-radius: 5px;
      background: $pink-color;
      border: none;
      font-size: 16px;
      font-weight: 400;
      color: $White-color;
    }
  }

  .BtnWrap {
    display: none;

    @include mobile {
      @include flex;
      margin-bottom: 20px;
    }

    button {
      background-color: $grayBG-color;
      padding: 10px 20px;
      border: none;
      border-radius: 10px;
      margin-right: 10px;
      width: 50%;
      @include flex;

      svg {
        font-size: 20px;
      }
    }
  }

  .LsitingSection {
    margin-bottom: 30px;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.16);
    padding: 30px 20px;
    border-radius: 15px;
    background-color: $White-color;

    .TitleWrap {
      display: flex;
      align-items: end;
      justify-content: space-between;

      @include mobile {
        display: unset;
        margin-bottom: 10px;
      }

      h1 {
        font-size: 32px;
        font-weight: 400;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
      }
    }
  }

  .ModalSize {
    min-width: 800px;
  }
}
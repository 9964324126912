@import "../../../App.scss";
.HighlightedListingSection {
  padding-top: 50px;
  padding-bottom: 50px;
  .HighlightedListingWrap {
    display: flex;
    @include mobile {
      flex-wrap: wrap;
    }
    .LeftSide {
      width: 25%;
      margin-right: 30px;
      @include mobile {
        width: 100%;
        margin-right: unset;
      }
      .FiltersPart {
        filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.16));
        @include mobile {
          display: none;
        }
      }
      .AdsWrap {
        display: flex;
        justify-content: center;
        max-height: 600px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .Banner300x600 {
        max-width: 300px; /* Set the maximum width for 300*600 */
        width: 100%;
        cursor: pointer;
      }

      .Banner160x600 {
        max-width: 160px; /* Set the maximum width for 160*600 */
        width: 100%;
        cursor: pointer;
      }
      .BtnWrap {
        display: none;
        @include mobile {
          @include flex;
          margin-bottom: 20px;
        }
        button {
          background-color: $grayBG-color;
          padding: 10px 20px;
          border: none;
          border-radius: 10px;
          margin-right: 10px;
          width: 50%;
          @include flex;
          svg {
            font-size: 20px;
          }
        }
      }
    }
    .RigthSide {
      width: 75%;
      @include mobile {
        width: 100%;
      }
      .bannerWrap {
        width: 100%;
        margin-bottom: 20px;
        img {
          width: 100%;
          cursor: pointer;
        }
      }
      .TitleWrap {
        display: flex;
        justify-content: space-between;
        @include smallmobile {
          display: unset;
        }
        h1 {
          font-size: 32px;
          font-family: $Poppins-SemiBold;
          @include tablet {
            font-size: 28px;
          }
        }
        .ListAndMap {
          @include flex;
          @include smallmobile {
            margin-top: 20px;
          }
          .ListBtn {
            font-size: 16px;
            font-weight: 400;
            color: $White-color;
            border: none;
            background-color: $pink-color;
            border-radius: 5px;
            padding: 15px;
            margin-right: 10px;
            @include smallmobile {
              width: 100%;
            }
            img {
              margin-right: 10px;
              width: 13px;
              height: 13px;
            }
          }
          :last-child {
            margin-right: unset;
          }
          .MapBtn {
            font-size: 16px;
            font-weight: 400;
            color: $btn-text-color;
            border: none;
            background-color: $grayBG-color;
            border-radius: 5px;
            padding: 15px;
            margin-right: 10px;
            @include smallmobile {
              width: 100%;
            }
            img {
              margin-right: 10px;
              width: 13px;
              height: 13px;
            }
          }
          :last-child {
            margin-right: unset;
          }
        }
      }
      .CardListing {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 2px solid $borderBottom-color;
        @include midmobile {
          grid-template-columns: 1fr;
        }
        .CardMain {
          cursor: pointer;
          width: 100%;
          height: 210px;
          position: relative;
          overflow: hidden;
          border-radius: 5px;
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.4;
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0) 0%,
              #000000 100%
            );
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          h3 {
            font-size: 32px;
            font-family: $Poppins-SemiBold;
            margin: 0;
            color: $White-color;
            position: absolute;
            top: 30px;
            left: 20px;
            z-index: 1;
            white-space: nowrap;
            width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          span {
            font-size: 16px;
            font-family: $Poppins-SemiBold;
            margin: 0;
            color: $White-color;
            position: absolute;
            top: 10px;
            left: 20px;
            z-index: 1;
            white-space: nowrap;
            width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          p {
            font-size: 16px;
            font-family: $Poppins-SemiBold;
            margin: 0;
            color: $White-color;
            position: absolute;
            bottom: 15px;
            left: 20px;
            z-index: 1;
            white-space: nowrap;
            width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .MapSection {
        margin-top: 20px;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 2px solid $borderBottom-color;
        width: 100%;
        height: 450px;
        .tooltipWrap {
          display: flex;
          flex-direction: column;
        }
      }
      .Pagination {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        .pageText {
          color: $btn-text-color;
          margin-right: 15px;
        }
        .PageWrap {
          display: flex;
          .page {
            margin-right: 10px;
            .Active {
              font-weight: 900;
              cursor: pointer;
            }
            .InActive {
              color: #1a1818;
              cursor: pointer;
            }
          }
        }
        .NextPageActive {
          font-weight: 900;
          cursor: pointer;
          cursor: pointer;
          margin-right: 10px;
          span {
            @include smMobile {
              display: none;
            }
          }
          :last-child {
            margin-right: unset;
          }
        }
        .NextPageInActive {
          color: #1a1818;
          cursor: pointer;
          margin-right: 10px;
          span {
            @include smMobile {
              display: none;
            }
          }
          :last-child {
            margin-right: unset;
          }
        }
      }
      .MiddleAdContainer {
        max-width: 970px;
        margin-bottom: 20px;
        img {
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }
}

@import "../../../App.scss";
.TemplateTitleSection {
  // background-image: url(../../../assets/images/HomeBackground.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 420px;
  position: relative;
  @include flex;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 50%);
  }
  @include midlarge {
    height: 320px;
  }
  @include tablet {
    height: 250px;
  }
  h1 {
    margin: 0;
    font-size: 64px;
    color: $White-color;
    z-index: 1;
    position: relative;
    font-weight: 600;
    @include midmobile {
      font-size: 42px;
      -ms-word-break: break-word;
      word-break: break-word;
      white-space: pre-wrap;
    }
  }
  img {
    position: absolute;
    z-index: 1;
    right: 2%;
    bottom: -1px;
    @include mobile {
      width: 20%;
      // height: 30%;
      right: 20px;
    }
  }
}

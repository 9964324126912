@import "../../App.scss";
.AddListingContainer {
	background-image: url(../../assets/icons/MyListingIconImage.svg);
	background-repeat: no-repeat;
	background-position: right 20% top 2%;
	.AddListHeading {
		margin-bottom: 40px;
		h1 {
			font-size: 32px;
			font-weight: 400;
			color: $primaryBlack-color;
		}
	}
}

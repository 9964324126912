@import "../style/mixin.scss";

* {
  padding: 0;
  margin: 0;
}

.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: #505050 !important;
  min-width: 30px !important;
  min-height: 30px !important;
  opacity: 1;
  cursor: pointer;

  &::before {
    font-weight: 600 !important;
  }
}

.errorText {
  @include error;
}

.visibilityHidden {
  visibility: hidden;
}

.basic-single {
  // width: 100% !important;
  // height: 50px !important;
}

.css-13cymwt-control {
  min-height: 100% !important;
  // background-color: #e7e7e7 !important;
  // border: unset !important;
  border: 1px solid grey;
  border-radius: 999px !important;
  padding: 5px;
  color: black;

  &:hover {
    border: 1px solid #505050 !important;
    border-radius: 999px !important;
    padding: 5px;
    color: black;
  }
}

.css-t3ipsp-control {
  border: 1px solid grey !important;
  border-radius: 999px !important;
  padding: 5px;
  color: black;
  box-shadow: unset !important;

  &:in-range {
    border: 1px solid grey !important;
    border-radius: 999px !important;
    padding: 5px;
    color: black;
  }

  &:active {
    border: 1px solid #505050 !important;
    border-radius: 999px !important;
    padding: 5px;
    color: black;
  }

  &:hover {
    border: 1px solid #505050 !important;
    border-radius: 999px !important;
    padding: 5px;
    color: black;
    box-shadow: unset !important;
  }

  &:focus {
    border: 1px solid #505050 !important;
    border-radius: 999px !important;
    padding: 5px;
    color: black;
    box-shadow: unset !important;
  }
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-radius: 5px 5px 0px 0px !important;
  background-color: #e7e7e7 !important;
  border: none !important;
  border-bottom: 1px solid #707070 !important;
}

.ck.ck-editor__main>.ck-editor__editable {
  height: 250px;
  border-radius: 0px 0px 5px 5px !important;
  background-color: #e7e7e7 !important;
  border: none !important;
  padding: 10px 20px !important;
}

.LoaderMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.react-multi-carousel-item {
  @include desktopsmall {
    display: flex;
    justify-content: center;
  }
}

.slick-prev {
  left: 0 !important;
  z-index: 1 !important;

  &::before {
    color: #505050 !important;
    opacity: 0.5 !important;
    font-size: 30px !important;
  }
}

.slick-next {
  right: 5px !important;
  z-index: 1 !important;

  &::before {
    color: #505050 !important;
    opacity: 0.5 !important;
    font-size: 30px !important;
  }
}

.slick-initialized .slick-slide {
  text-align: center !important;
}
@import "../../App.scss";

.ComingSoonSection {
	height: 100vh;
	// padding: 50px 0px;
	@include flex;
    flex-direction: column;
	text-align: center;
	.ImgWrap {
		width: 50%;
		height: 50%;
		margin: 0 auto;
		@include smMobile {
			width: 85%;
			// height: 85%;
		}
		img {
			width: 100%;
			height: 100%;
		}
	}
	h1 {
		font-size: 62px;
		color: $pink-color;
		font-family: $Poppins-SemiBold;
		
		text-align: center;
		margin: 30px 0px;
		@include smMobile {
			font-size: 42px;
		}
	}
	// button {
	// 	background-color: $pink-color;
	// 	color: $White-color;
	// 	border: none;
	// 	border-radius: 5px;
	// 	padding: 10px 20px;
	// 	min-width: 185px;
	// }
}

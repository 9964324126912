@import "../../App.scss";
.PageNotFoundMain {
	.PageNotFoundImgWrap {
		width: 40%;
		height: 40%;
		@include mobile {
			width: auto;
		}
		margin: 0 auto;
		padding: 20px 0px;
		img {
			width: 100%;
			height: 100%;
		}
	}
	h1 {
		color: $pink-color;
		font-size: 2rem;
		font-weight: 700;
		text-align: center;
		margin: 0;
		padding: 20px 0px;
		@include midmobile {
			font-size: 1.5rem;
		}
	}
}

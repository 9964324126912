@import "../../App.scss";
.EditListingContainer {
	background-image: url(../../assets/icons/IdealLogo.svg);
	background-repeat: no-repeat;
	background-position: right 20% top 2%;
	.EditListHeading {
		margin-bottom: 40px;
		h1 {
			font-size: 32px;
			font-weight: 400;
			color: $primaryBlack-color;
		}
	}
}

@import "../../../App.scss";
.ContainerMain {
	max-width: 1236px;
	width: 100%;
	margin: 0 auto;
	padding: 0px 10px;
	@include  desktopxxtralarge{
		max-width: 80%;
		padding: 0px 10px;
	}
	@include midlarge {
		max-width: 1026px;
		padding: 0px 10px;
	}
	@include tabletNew {
		max-width: 940px;
		padding: 0px 10px;
	}
	@include mobile {
		max-width: 1026px;
		padding: 0px 10px;
	}
	@include midmobile {
		max-width: 1026px;
		padding: 0px 10px;
	}
}

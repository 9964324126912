@import "../../../App.scss";
.HeaderWrap {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  position:relative;
  z-index: 1000;
  isolation: isolate;
  .HeaderMain {
    display: flex;
    align-items: center;
    z-index: 0;
    position:relative;
    .headerLogo {
      content: url(../../../assets/images/herocropperlogo.png);
      position: absolute;
      top: -17px;
      left: 35px;
      z-index: 9;
      cursor: pointer;
      @include midmobile {
        display: none;
      }
      @include minwidth577{
        display: none;
      }
           // @include mobile {
        //   display: none;
        // }
    }
    .HeaderLeftSide {
      @include flex;
      .LogoWrap {
        // width: 185px;
        // height: 105px;
        padding: 10px 0;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          @include smallmobile {
            width: unset;
          }
        }
        @include smallmobile {
          width: 155px;
          height: 70px;
        }
      }
    }
    .HeaderRightSide {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .NavLinks {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .NavList {
          @include flex;
          margin: 0;
          padding: 0;
          margin-left: 30px;
          list-style: none;
          li {
            a {
              font-size: 16px;
              margin-left: 30px;
              font-family: $Poppins-SemiBold;
              cursor: pointer;
              text-decoration: none;
              color: $black-color;
              @include mobile{
                margin-left: 0px;
              }
            }
          }
        }
        .NavAuthList {
          @include flex;
          margin: 0;
          padding: 0;
          justify-content: space-between;
          align-items: center;
          gap: 20px;
          .CountryList {
            font-size: 14px;
            // font-family: $Poppins-SemiBold;
            list-style: none;
            color: #FF5FB3;
            cursor: pointer;
          }
          .MyListingLink {
            @include flex;
            margin-right: 20px;
            img {
              width: 22px;
              height: 22px;
              margin-right: 10px;
            }
            p {
              font-size: 16px;
              font-weight: 400;
              margin: 0;
              @include smallmobile {
                display: none;
              }
            }
          }
          .LoginLink {
            @include flex;
            border: 1px solid #FF5FB3;
            border-radius: 30px;
            padding: 5px 10px;
            cursor: pointer;
            img {
              width: 22px;
              height: 22px;
              margin-right: 10px;
              @include smallmobile {
                margin-right: 0px;
              }
            }
            p {
              font-size: 16px;
              font-weight: 400;
              color: #FF5FB3;
              max-width: 100px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 0;
              @include smallmobile {
                display: none;
              }
            }
          }
          .menu_icon {
            display: none;
          }
        }
        @media screen and (max-width: 768px) {
          .NavList {
            display: none;
          }
        }
        @media screen and (max-width: 768px) {
          .menu_icon {
            display: block !important;
            margin-left: 20px;
            svg {
              font-size: 20px;
              color: $black-color;
            }
          }
          .NavList {
            display: flex;
            flex-direction: column;
            list-style: none;
            min-width: 200px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            position: absolute;
            z-index: 999 !important;
            background: $ofWhite-color;
            padding: 0;
            top: 100px;
            right: 0px;
            transition: all 0.5s ease-in-out;
            li {
              width: 100%;
              text-align: center;
              margin-left: 0 !important;
              font-family: $Poppins-SemiBold;
              padding: 10px 0px;
            }
            li:last-child {
              margin: 0;
            }
          }
          .NavList.close {
            left: -100%;
            transition: all 0.5s ease-in-out;
            display: none;
          }
        }
      }
      @include mobile {
        width: unset;
      }
    }
    @include mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
.NoboxShadow {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 1000;
  isolation: isolate;
  @include midmobile{
    box-shadow: unset;
  }
  .HeaderMain {
    display: flex;
    align-items: center;
    z-index: 1;
    position:relative;
    .headerLogo {
      content: url(../../../assets/images/herocropperlogo.png);
      position: absolute;
      top: -17px;
      left: 35px;
      z-index: 1;
      pointer-events: auto;
      cursor: pointer;
      @include midmobile {
        display: none;
      }
      img {
        // border: 2px solid red;
        // width: 100%;
        // height: 100%;
        // display: none;
      }
    }
    .HeaderLeftSide {
      cursor: pointer;
      @include flex;
      .LogoWrap {
        // width: 185px;
        // height: 105px;
        padding: 10px 0;
        img {
          width: 100%;
          height: 100%;
          @include smallmobile {
            width: unset;
          }
          @include minwidth577{
            display: none;
          }
        }
        @include smallmobile {
          width: 155px;
          height: 70px;
        }
      }
    }
    .HeaderRightSide {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .NavLinks {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .NavList {
          @include flex;
          margin: 0;
          list-style: none;
          padding: 0;
          margin-left: 30px;
          li {
            a {
              font-size: 16px;
              margin-left: 30px;
              font-family: $Poppins-SemiBold;
              cursor: pointer;
              text-decoration: none;
              color: $White-color;
              @include mobile{
                margin-left: 0px;
              }
            }
          }
        }
        .NavAuthList {
          @include flex;
          margin: 0;
          padding: 0;
          justify-content: space-between;
          align-items: center;
          gap: 20px;
          .CountryList {
            font-size: 14px;
            // font-family: $Poppins-SemiBold;
            list-style: none;
            color: #FF5FB3;
            cursor: pointer;
          }
          .MyListingLink {
            @include flex;
            margin-right: 20px;
            cursor: pointer;
            img {
              width: 22px;
              height: 22px;
              margin-right: 10px;
            }
            p {
              font-size: 16px;
              font-weight: 400;
              margin: 0;
              color: $White-color;
              @include smallmobile {
                display: none;
              }
            }
          }
          .LoginLink {
            @include flex;
            border: 1px solid #FF5FB3;
            border-radius: 30px;
            padding: 5px 10px;
            cursor: pointer;
            img {
              width: 22px;
              height: 22px;
              margin-right: 10px;
              @include smallmobile {
                margin-right: 0px;
              }
            }
            p {
              font-size: 14px;
              font-weight: 400;
              color: #FF5FB3;
              max-width: 100px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 0;
              @include smallmobile {
                display: none;
              }
            }
          }
          .menu_icon {
            display: none;
          }
        }
        @media screen and (max-width: 768px) {
          .NavList {
            display: none;
          }
        }
        @media screen and (max-width: 768px) {
          .menu_icon {
            display: block !important;
            margin-left: 20px;
            svg {
              color: $White-color;
              font-size: 20px;
            }
          }
          .NavList {
            display: flex;
            flex-direction: column;
            min-width: 200px;
            list-style: none;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            position: absolute;
            z-index: 999 !important;
            background: $ofWhite-color;
            padding: 0;
            top: 100px;
            right: 20px;
            transition: all 0.5s ease-in-out;
            li {
              width: 100%;
              text-align: center;
              margin-left: 0 !important;
              padding: 10px 0px;
              font-family: $Poppins-SemiBold;
              a {
                color: $black-color;
                margin: 0;
              }
            }
            li:last-child {
              margin: 0;
            }
          }
          .NavList.close {
            left: -100%;
            transition: all 0.5s ease-in-out;
            display: none;
          }
        }
      }
      @include mobile {
        width: unset;
      }
    }
    @include mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

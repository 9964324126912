@import '../../App.scss';


.blogLoaderWrap {
    height: 80vh;
    width: 100%;
    @include flex;
}

.blogSection {
    $self: &;
    padding: 40px 0;

    // Title styling
    .title {
        font-size: 24px;
        margin-bottom: 30px;
        font-weight: 500;
        color: $black-color;
        font-family: $Poppins-SemiBold;
    }

    // Blog grid container
    .blogGrid {
        display: grid;
        gap: 24px;
        margin-top: 24px;
        grid-template-columns: repeat(3, 1fr);
    }

    // Update media queries for responsive grid
    @media (min-width: 1441px) {
        .blogGrid {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    @media (max-width: 1024px) {
        .blogGrid {
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
        }
    }

    @media (max-width: 768px) {
        .blogGrid {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (max-width: 640px) {
        .blogGrid {
            grid-template-columns: 1fr;
            gap: 32px;
            max-width: 500px;
            margin: 24px auto 0;
        }
    }

    // Blog card styling
    .blogCard {
        background: #fff;
        border-radius: 8px;
        overflow: hidden;
        padding: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        // transition: transform 0.2s ease-in-out;

        &:hover {
            // transform: translateY(-5px);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        }

        // Image container
        .imageWrapper {
            width: 100%;
            height: 200px;
            overflow: hidden;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform 0.3s ease;
                border-radius: 8px;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }

        // Content area
        .content {

            h2 {
                font-size: 18px;
                margin: 18px 0 12px 0;
                color: $black-color;
                font-weight: 500;
                line-height: 27px;
                font-family: $Poppins-SemiBold;
            }

            p {
                font-size: 14px;
                color: #6E7072;
                margin-bottom: 16px;
                line-height: 26px;
                font-weight: 400;
            }

            .cardReadMore {
                display: inline-block;
                color: $black-color;
                text-decoration: none;
                font-size: 14px;
                border-radius: 30px;
                border: 1px solid $black-color;
                padding: 10px 15px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    @media (max-width: 480px) {
        .blogGrid {
            margin: 16px auto 0;
        }

        .blogCard {
            margin: 0 12px;

            .imageWrapper {
                height: 200px;
            }

            .content {
                padding: 20px;
            }
        }
    }
}

.searchBanner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../assets/images/searchBanner.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 20px;

    .searchContent {
        max-width: 600px;
        width: 100%;

        h1 {
            color: $White-color;
            font-size: 64px;
            margin-bottom: 24px;
            font-weight: 600;
            // font-family: $Poppins-Bold;
        }

        .searchForm {
            display: flex;
            gap: 12px;
            max-width: 500px;
            margin: 0 auto;

            input {
                flex: 1;
                padding: 12px 16px;
                border: none;
                border-radius: 80px;
                font-size: 16px;
                outline: none;

                &::placeholder {
                    color: #666;
                }
            }

            button {
                background-color: #E6007E;
                color: white;
                border: none;
                border-radius: 100px;
                padding: 12px 24px;
                font-size: 16px;
                margin-left: -74px;
                cursor: pointer;
                transition: background-color 0.2s ease;

                &:hover {
                    background-color: darken(#E6007E, 10%);
                }
            }
        }
    }

    @media (max-width: 768px) {
        height: 250px;

        .searchContent {
            h1 {
                font-size: 28px;
            }

            .searchForm {
                // flex-direction: column;
                // gap: 8px;

                button {
                    width: 100%;
                }
            }
        }
    }

    @media (max-width: 480px) {
        height: 200px;

        .searchContent {
            h1 {
                font-size: 24px;
                margin-bottom: 16px;
            }
        }
    }
}

.noBlogResults {
    width: 100%;
    // text-align: center;
    // padding: 2rem;
    font-size: 1.2rem;
    color: #666;
}
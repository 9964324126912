@import "../../../App.scss";

.TemplateListingWrap {
  // max-height: 360px;
  overflow-x: scroll;
  border: 1.5px solid #e7e7e7;
  border-radius: 5px;
  padding: 10px;
  white-space: nowrap;
  display: none;

  // max-width: 825px;
  .TemplateScrollContainer {
    overflow: auto;
    white-space: nowrap;
    // padding: 5px 70px 5px 20px;
    background: transparent;
    height: 100%;

    .TemplateGridScroll {

      // display: inline-block;
      .TemplateCard {
        // max-width: 770px;        padding: 15px 0px;
        align-items: center;
        margin-right: 15px;
        margin-bottom: 15px;
        padding-right: 15px;
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 10px;

        @include smMobile {
          flex-wrap: wrap;
        }

        .TemplateId {
          margin-right: 5px;
        }

        .CardSelect {
          margin-right: 10px;

          .radioCustom {
            opacity: 0;
            position: absolute;
          }

          .radioCustom,
          .radioCustomLabel {
            display: inline-block;
            vertical-align: middle;
            margin: 5px;
            cursor: pointer;
          }

          .radioCustomLabel {
            position: relative;
          }

          .radioCustom+.radioCustomLabel:before {
            content: "";
            background: #fff;
            border: 1.5px solid #595f72;
            display: inline-block;
            vertical-align: middle;
            border-radius: 2px;
            padding: 7px;
            text-align: center;
          }

          .radioCustom+.radioCustomLabel:before {
            border-radius: 5px;
          }

          .radioCustom:checked+.radioCustomLabel:before {
            background: url(../../../assets/icons/RightIcon.svg);
            background-size: 10px;
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        .TemplatePreview {
          cursor: pointer;
          // max-width: 748px;
          // width: 748px;
          // height: 110px;
          border-radius: 5px;
          background-color: #e7e7e7;
          border: 1px solid #e7e7e7;
          padding: 10px;
          padding-bottom: 5px !important;

          @include smMobile {
            min-width: 100%;
            min-height: 100%;
            margin-bottom: 15px;
          }

          img {
            max-width: 728px;
            max-height: 90px;
            border-radius: 5px;
          }

          iframe {
            pointer-events: none;
          }
        }

        .TemplatePreviewSelected {
          cursor: pointer;
          // max-width: 748px;
          // width: 748px;
          // height: 110px;
          border-radius: 5px;
          background-color: #e7e7e7;
          border: 2px solid $pink-color;
          padding: 10px;
          padding-bottom: 5px !important;

          @include smMobile {
            min-width: 100%;
            min-height: 100%;
            margin-bottom: 15px;
          }

          img {
            max-width: 728px;
            max-height: 90px;
            border-radius: 5px;
          }

          iframe {
            pointer-events: none;
          }
        }

        .MainContentWrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 20px;
          width: 100%;

          @include xxsMobile {
            padding: 0px;
          }

          .iconWrap {
            cursor: pointer;

            svg {
              font-size: 25px;
              color: #505050;
            }

            .EditIconWrap {
              display: flex;
              flex-direction: column;

              img {
                width: 25px;
                height: 25px;

                @include xxsMobile {
                  width: 20px;
                  height: auto;
                }
              }

              svg {
                font-size: 25px;
                color: #505050;
              }
            }
          }

          .PropertyContent {
            @include smMobile {
              margin: 0;
            }

            h5 {
              font-size: 16px;
              font-weight: 400;
              margin: 0;
              cursor: pointer;
            }

            span {
              font-size: 16px;
              font-weight: 400;
              cursor: pointer;
            }

            .PriceText {
              margin: 10px 0px;
            }

            .PropertySize {
              display: flex;

              .Size {
                @include flex;
                margin-right: 20px;

                img {
                  width: 26px;
                  height: 20px;
                  margin-right: 10px;
                }

                p {
                  font-size: 16px;
                  font-weight: 300;
                  color: $sizetext-color;
                  margin: 0;
                }
              }

              .Capacity {
                @include flex;

                img {
                  width: 26px;
                  height: 20px;
                  margin-right: 10px;
                }

                p {
                  font-size: 16px;
                  font-weight: 300;
                  color: $sizetext-color;
                  margin: 0;
                }
              }
            }

            .CardLink {
              font-size: 16px;
              font-weight: 400;
              color: $pink-color;
              margin: 0;
              cursor: pointer;

              @include smMobile {
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
  }

  .InvisibleCard {
    opacity: 50%;
    display: none !important;
  }
}

.TemplateListingWrap:first-of-type {
  display: block;
  max-height: 220px;
  overflow-y: hidden;
}
@import "../../App.scss";
.accountSection {
	padding-top: 50px;
	padding-bottom: 50px;
	.AccountWrap {
		display: flex;
		@include mobile {
			flex-wrap: wrap;
		}
		.LeftSide {
			width: 25%;
			margin-right: 30px;
			@include mobile {
				width: 100%;
				margin-right: unset;
			}
			.AccountMainWrap {
				@include mobile {
					display: none;
				}
			}
			.BtnWrap {
				display: none;
				@include mobile {
					@include flex;
					margin-bottom: 20px;
				}
				button {
					background-color: $grayBG-color;
					padding: 10px 20px;
					border: none;
					border-radius: 10px;
					margin-right: 10px;
					width: 50%;
					@include flex;
					svg {
						font-size: 20px;
					}
				}
			}
		}
		.RigthSide {
			width: 75%;
			@include mobile {
				width: 100%;
			}
			.accountInfoSection {
				margin-bottom: 30px;
				box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.16);
				padding: 30px 20px;
				border-radius: 15px;
				background-color: $White-color;
				.accountInfoHeading {
					margin-bottom: 20px;
					h2 {
						font-size: 32px;
						font-family: $Poppins-SemiBold;
						line-height: 32px;
						font-style: normal;
						color: $primaryBlack-color;
					}
				}
				.accountMainWrap {
					display: flex;
					justify-content: space-between;
					@include midmobile {
						justify-content: center;
						flex-wrap: wrap;
					}
					.accountInfoContaint {						
						width: 60%;
						margin-right: 10px;
						@include midmobile {
							margin-right: unset;
							width: 100%;
						}
						.inputBox {
							// display: flex;
							// flex-direction: column;
							// padding-top: 20px;
							p {
								font-size: 16px;
								font-weight: 500;
							}
							input {
								border: none;
								border-radius: 5px;
								font-size: 14px;
								font-weight: 400;
								color: $primaryBlack-color !important;
								padding: 10px;
								width: 100%;
								background-color: $btn-color;
								margin-bottom: 10px;
							}
							input:focus {
								outline: none;
							}
							input:-webkit-autofill {
								-webkit-box-shadow: 0 0 0 30px white inset !important;
							}
						}
					}
					.imgContainer {
						max-width: 200px;
						max-height: 200px;
						margin-bottom: 20px;
						img {
							width: 100%;
							height: 100%;
							filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.16));
							object-fit: cover;
						}
						p {
							font-size: 16px;
							font-weight: 500;
							line-height: 16px;
							font-style: normal;
							text-align: left;
							color: $pink-color;
							padding-top: 10px;
							cursor: pointer;
						}
					}
				}
				.changeBtnWrap {
					padding: 30px 0;

					.saveChangeBtn {
						@include midmobile {
							width: 100%;
						}
						padding: 20px 40px;
						min-width: 195px;
						border-radius: 5px;
						background-color: $pink-color;
						color: $White-color;
						border: none;
						font-size: 16px;
						font-weight: 400;
						line-height: 16px;
						font-style: normal;
						cursor: pointer;
					}
				}
			}
		}
	}
}

@import "../../../App.scss";
.OtpSection {
  display: flex;
  justify-content: center;
  .OtpContaint {
    padding: 20px;
    margin-bottom: 50px;
    max-width: 350px;
    width: 100%;
    @include smallmobile {
      width: 100%;
    }
    .OtpHeading {
      text-align: center;
      display: flex;
      justify-content: center;
      h2 {
        color: $gray-color-font;
        padding: 30px 0;
        font-family: $Poppins-Bold;
        margin-bottom: 0;
        font-size: 1rem;
      }
    }
    .otpInput {
      input {
        border: none;
        border-bottom: 2px solid #e8e8e8;
      }
      input:focus {
        outline: none;
        border-bottom: 3px solid #e8e8e8;
      }
    }
    .VerifyBtnWrap {
      padding: 30px 0;

      .VerifyBtn {
        padding: 20px 40px;
        border-radius: 5px;
        background-color: $pink-color;
        color: $White-color;
        border: none;
        font-size: 1rem;
        cursor: pointer;
        @include smallmobile {
          width: 100%;
        }
      }
    }
    .resendOtpBtn {
      font-weight: 900;
      text-align: center;
      margin: 10px 0px;
      button {
        background-color: $grayBG-color;
        padding: 10px 20px;
        border-radius: 10px;
      }
    }
  }
}

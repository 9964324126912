@import "../fonts/font.scss";
// fonts
$Poppins-Regular: "Poppins-Regular";
$Poppins-Bold: "Poppins-Bold";
$Poppins-SemiBold: "Poppins-SemiBold";
$Poppins-Medium: "Poppins-Medium";
$Poppins-Light: "Poppins-ExtraLight";
$Poppins-300:"Poppins-Light";
$Poppins-100:"Poppins-Thin";
// color
$primaryBlack-color: #1a1818;
$ofWhite-color: #f9f8fd;
$White-color: #ffffff;
$black-color: #000000;
$pink-color: #df1681;
$purple-color: #9a1f60;
$border-color: #e8e8e8;
$gray-color-font: #505050;
$btn-color: #e7e7e7;
$grayBG-color: #e4e4e4;
$borderBottom-color: #d2d2d2;
$sizetext-color: #707070;
$btn-text-color: #979797;
$light-black-color: #505050;
$card-border: #dddddd;
$green-color: #1dca06;
$red-color: #ef2626;
$grey-color: #0c0c0c;
$light-grey-color: #f6f6f6;
$light-white-color: #fbfbfb;

// fontColor

// media q
$xxs-min-width: 426px;
$xxxs-max-width: 475px;
$xxs-max-width: 576px;
$xs-min-width: 577px;
$xs-max-width: 768px;
//max-width changed from 767 to 768 for about section
$sm-min-width: 768px;
$sm-max-width: 991px;
$md-min-width: 992px;
$mdd-min-width: 1025px;
$mdd-max-width: 1024px;
$md-max-width: 1199px;
$lg-min-width: 1200px;
$max-width-1300: 1300px;
$lg-max-width: 1440px;
$xlg-min-width: 1441px;
$xxlg-min-width: 1800px;
$xxlg-max-width: 1920px;
$xlg-max-width: 1081px;
$mid-max-width: 1290px;

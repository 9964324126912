@import "../../App.scss";
.LoaderMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.ViewListingMain {
  padding-top: 50px;
  padding-bottom: 50px;
  .ImgSection {
    .ListingImages {
      display: flex;
      @include tablet {
        flex-wrap: wrap;
      }
      .MainImg {
        width: 50%;
        margin-right: 10px;
        @include tablet {
          width: 100%;
          margin-right: unset;
          margin-bottom: 10px;
        }
        .MainImgWrap {
          width: 100%;
          height: 410px;
          cursor: pointer;
          @include smallmobile {
            height: 210px;
          }
          position: relative;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .VideoWrap {
            width: 100%;
            height: 100%;
            position: relative;
            &::before {
              content: "";

              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0.4;
              background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0%,
                #000000 100%
              );
            }
            iframe {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            video {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            span {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-60%, -60%);
              background: rgba(0, 0, 0, 0.5);
              padding: 10px;
              border-radius: 50%;
              padding-left: 15px;
              img {
                width: 25px;
                height: 25px;
                margin: 0 auto;
              }
            }
          }
          .UnderOption {
            top: 10px;
            left: 10px;
            padding: 5px;
            position: absolute;
            font-size: 11px;
            border-radius: 5px;
            color: $White-color;
            background-color: $pink-color;
          }
        }
      }
      .OtherImg {
        width: 50%;
        @include tablet {
          width: 100%;
          margin-bottom: 10px;
        }
        .OtherImgWarp {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 10px;
          grid-row-gap: 10px;
          .ImgWrap {
            width: 100%;
            height: 200px;
            cursor: pointer;
            @include smallmobile {
              height: 120px;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            .VideoWrap {
              width: 100%;
              height: 100%;
              position: relative;
              &::before {
                content: "";

                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0.4;
                background: linear-gradient(
                  180deg,
                  rgba(0, 0, 0, 0) 0%,
                  #000000 100%
                );
              }
              iframe {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              video {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-60%, -60%);
                background: rgba(0, 0, 0, 0.5);
                padding: 10px;
                border-radius: 50%;
                padding-left: 15px;
                img {
                  width: 25px;
                  height: 25px;
                  margin: 0 auto;
                }
              }
            }
          }
        }
      }
    }
    .ImageContent {
      display: flex;
      flex-wrap: wrap;
      .Content {
        padding: 10px 0px;
        display: flex;
        align-items: center;
        margin-right: 20px;
        cursor: pointer;

        img {
          margin-right: 10px;
        }
        p {
          font-size: 16px;
          font-weight: 300;
          color: $btn-text-color;
          margin: 0;
          margin-right: 5px;
          span {
            font-size: 16px;
            font-weight: 300;
            color: $black-color;
          }
        }
      }
    }
  }
  .lable {
    background-color: #829002;
    padding: 4px;
    font-size: 16px;
    color: $White-color;
    min-width: 80px;
    max-width: 76px;
    text-align: center;
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0 49%, 0% 0%);
    margin-bottom: 8px;
    height: 29px;
    margin-left: 10px;
  }
  .ListingDetailsSection {
    display: flex;

    border-radius: 15px;
    justify-content: space-between;
    @include mobile {
      flex-wrap: wrap;
    }
    .ListingDetail {
      width: 37%;
      padding-right: 10px;
      @include mobile {
        width: 50%;
      }
      @include midmobile {
        width: 100%;
        border-right: unset;
        padding-right: unset;
      }
      h1 {
        font-size: 32px;
        font-weight: 400;
        margin: 0;
        margin-bottom: 10px;
      }
      p {
        font-size: 16px;
        font-family: $Poppins-SemiBold;
        margin: 0;
        margin-bottom: 10px;
      }
      .PropertySize {
        display: flex;
        margin-bottom: 10px;
        @include xxsMobile {
          flex-wrap: wrap;
        }
        .Size {
          @include flex;
          margin-right: 20px;
          img {
            width: 26px;
            height: 20px;
            margin-right: 10px;
          }
          p {
            font-size: 16px;
            font-weight: 300;
            color: $sizetext-color;
            margin: 0;
          }
        }
        .Capacity {
          @include flex;
          margin-right: 20px;
          img {
            width: 26px;
            height: 20px;
            margin-right: 10px;
          }
          p {
            font-size: 16px;
            font-weight: 300;
            color: $sizetext-color;
            margin: 0;
          }
        }
        .SquareMeterIcon {
          @include flex;
          @include xxsMobile {
            margin-top: 10px;
          }
          img {
            width: 26px;
            height: 20px;
            margin-right: 10px;
          }
          p {
            font-size: 16px;
            font-weight: 300;
            color: $sizetext-color;
            margin: 0;
          }
        }
      }
      .PriceWarp {
        display: flex;
        p {
          font-size: 16px;
          font-weight: 400;
        }
      }

      .NoteWrap {
        background-color: #f6f6f6;
        padding: 20px;
        border-radius: 5px;
        width: 100%;
        margin-top: 10px;
        p {
          color: $sizetext-color;
          text-align: center;
          font-size: 16px;
          font-style: italic;
          font-weight: 300;
          margin: 0;
          -ms-word-break: break-word;
          word-break: break-word;
          white-space: pre-wrap;
          @include mobile {
            width: unset;
          }
        }
      }
    }
    .ListingOrgImg {
      border-right: 2px solid $borderBottom-color;
      width: 38%;
      padding: 0px 50px;
      @include mobile {
        width: 50%;
        border-right: unset;
        padding: 0px 20px;
      }
      @include midmobile {
        width: 100%;
        margin: 20px 0px;
        padding: unset;
      }
      h5 {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        margin-bottom: 15px;
      }
      .Inputbox {
        margin-bottom: 10px;
        input {
          border: none;
          background-color: $btn-color;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 400;
          color: $primaryBlack-color !important;
          padding: 15px 20px;
          width: 100%;
        }
        input:focus {
          outline: none;
        }
      }

      button {
        background-color: $black-color;
        color: $White-color;
        padding: 10px 0px;
        min-width: 100%;
        border: none;
        border-radius: 5px;
      }
    }
    .mainOrgWrap {
      padding: 0px 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include midmobile {
        width: 100%;
        padding: unset;
      }
      @include tabletNew {
        width: unset;
      }
      .ContactWrap {
        display: flex;
    padding: 25px;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f6f6;
    padding: 20px;
    border-radius: 5px;
    flex-direction: column;
    gap: 5px;

        @include midmobile {
          flex-wrap: wrap;
          margin-top: 14px;
          justify-content: unset;
        }

        @include tabletNew {
          margin-top: 30px;
          width: 100%;
        }
        .OrgMainWrap {
          .OrgMgWrap {
            min-width: 80px;
            max-width: 80px;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              cursor: pointer;
            }
          }
        }
        .OrgContent {
          margin-left: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
          @include tablet {
            margin-top: 10px;
          }
          h5 {
            font-size: 16px;
            font-family: $Poppins-SemiBold;
            margin: 0;

            max-width: 276px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
          }
          .linkWrap {
            display: flex;
    flex-direction: column;
    align-items: center;
            a {
              text-decoration: unset;
              font-size: 16px;
              font-weight: 400;
              margin: 0;
              color: $black-color;
              -ms-word-break: break-word;
              word-break: break-word;
              white-space: pre-wrap;
            }
          }
        }
        h6 {
          font-size: 16px;
          font-family: $Poppins-SemiBold;
          margin: 0;
          margin-bottom: 5px;
          color: $gray-color-font;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: $gray-color-font;
          margin: 0;
          margin-bottom: 5px;
        }
      }
      .ContactDetails {
        padding: 25px;
        margin-top: 30px;

        background-color: $light-grey-color;

        border-radius: 5px;
        @include midmobile {
          margin-top: 14px;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: $gray-color-font;
          margin: 0;
          margin-bottom: 5px;
        }
        .UserInfoWrap {
          display: flex;
          align-items: center;
          img {
            width: 14px;
            height: 14px;
            margin-right: 10px;
            margin-bottom: 5px;
          }
          a {
            text-decoration: unset;
            font-size: 16px;
            font-weight: 400;
            color: $gray-color-font;
            margin: 0;
            margin-bottom: 5px;
          }
        }
      }
    }

    .MapSection {
      width: 25%;
      padding-left: 10px;
      @include mobile {
        width: 100%;
        padding-left: unset;
        margin-top: 15px;
      }
      @include tablet {
        max-width: unset;
      }
    }
  }
  .viewListingImageContainer {
    display: flex;
    justify-content: space-between;
    .ImgWrap {
      margin: 10px;
    }
  }
  .SubListingDetailSection {
    margin-top: 50px;
    margin-bottom: 30px;
    .DescriptionAndContactWrap {
      display: flex;
      margin-bottom: 30px;
      flex-wrap: wrap;
      @include mobile {
      }
      .DescriptionWrap {
        width: 70%;
        @include tablet {
          width: 60%;
        }
        @include mobile {
          width: 100%;
        }
        h6 {
          font-size: 16px;
          font-family: $Poppins-SemiBold;
          margin: 0;
          margin-bottom: 15px;
          color: $gray-color-font;
        }
        .Content {
          max-width: 80%;
          @include mobile {
            max-width: unset;
          }
          p {
            font-size: 16px;
            font-weight: 400;
            margin: 0;
            -ms-word-break: break-word;
            word-break: break-word;
            white-space: pre-wrap;
            @include mobile {
              width: 100%;
              max-width: unset;
            }
          }
        }
        .ReadOrHide {
          color: $pink-color;
          text-align: center;
          margin-top: 10px;
          cursor: pointer;
        }
      }
      .ContactWrap {
        width: 30%;
        box-shadow: 3px 3px 10px 5px rgba(0, 0, 0, 0.16);
        padding: 20px;
        border-radius: 15px;
        height: 100%;
        @include tablet {
          width: 40%;
        }
        @include mobile {
          width: 100%;
          margin-top: 30px;
        }
        .OrgMainWrap {
          margin-bottom: 20px;
          .OrgMgWrap {
            margin-bottom: 10px;
            min-width: 80px;
            max-width: 80px;
            height: 80px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              cursor: pointer;
            }
          }
          .OrgContent {
            @include tablet {
              margin-top: 10px;
            }
            h5 {
              font-size: 16px;
              font-family: $Poppins-SemiBold;
              margin: 0;
              -ms-word-break: break-word;
              word-break: break-word;
              white-space: pre-wrap;
              cursor: pointer;
            }
            .linkWrap {
              display: flex;
              flex-direction: column;
              a {
                text-decoration: unset;
                font-size: 16px;
                font-weight: 400;
                margin: 0;
                color: $black-color;
                -ms-word-break: break-word;
                word-break: break-word;
                white-space: pre-wrap;
              }
            }
          }
        }
        h6 {
          font-size: 16px;
          font-family: $Poppins-SemiBold;
          margin: 0;
          margin-bottom: 5px;
          color: $gray-color-font;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: $gray-color-font;
          margin: 0;
          margin-bottom: 5px;
        }
        .UserInfoWrap {
          display: flex;
          align-items: center;
          img {
            width: 14px;
            height: 14px;
            margin-right: 10px;
            margin-bottom: 5px;
          }
          a {
            text-decoration: unset;
            font-size: 16px;
            font-weight: 400;
            color: $gray-color-font;
            margin: 0;
            margin-bottom: 5px;
          }
        }
      }
    }
    .FeaturesWrap {
      margin-bottom: 50px;
      h6 {
        font-size: 16px;
        font-family: $Poppins-SemiBold;
        margin: 0;
        margin-bottom: 15px;
        color: $gray-color-font;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        margin-bottom: 15px;
        max-width: 80%;
      }
      .AskingPrice {
        margin-bottom: 15px;
        p {
          font-size: 16px;
          font-weight: 400;
          margin: 0;
          margin-bottom: 5px;
          color: $gray-color-font;
        }
        .PriceWarp {
          display: flex;
          p {
            font-size: 16px;
            font-weight: 300;
            &:last-child {
              margin-left: 30px;
            }
          }
        }
      }
      .PricePerM {
        margin-bottom: 15px;
        p {
          font-size: 16px;
          font-weight: 300;
          margin: 0;
          margin-bottom: 5px;
          color: $gray-color-font;
        }
      }
      .DateWrap {
        margin-bottom: 15px;
        p {
          font-size: 16px;
          font-weight: 300;
          margin: 0;
          margin-bottom: 5px;
          color: $gray-color-font;
        }
      }
    }
    h6 {
      font-size: 16px;
      font-family: $Poppins-SemiBold;
      margin: 0;
      margin-bottom: 15px;
      margin-top: 15px;
      color: $gray-color-font;
    }
    .FeatureContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 34px;
    }
    .MainFeaturesWrap {
      display: flex;
      width: 100%;
      .LeftFeatureWrap {
        width: 50%;
        padding-right: 30px;
        @include smMobile {
          width: 100%;
        }
        height: 400px;
        overflow: hidden;

        @include xxsMobile {
          width: unset;
        }
        .contentWrap {
          table {
            font-size: 16px;
            width: 90%;
            text-align: left;

            tr {
              border-bottom: 2px solid $border-color;
            }

            tr:last-child {
              border-bottom: 0;
            }

            h6 {
              font-family: $Poppins-Medium;
              margin-bottom: 15px;
              color: $gray-color-font;
            }
            .ContentHeading {
              font-family: $Poppins-Regular;
              margin: 0;

              padding: 8px;
              padding-left: 0px;
              color: $pink-color !important;
            }
            .ContentValue {
              font-family: $Poppins-Regular;

              margin: 0;
              padding: 8px;
              color: $grey-color;
            }
          }
        }
      }
      .RightFeatureWrap {
        width: 50%;
        @include smMobile {
          width: 100%;
        }
        height: 400px;
        overflow: hidden;

        @include xxsMobile {
          width: unset;
        }
        .contentWrap {
          table {
            font-size: 16px;

            text-align: left;

            tr {
              border-bottom: 2px solid $border-color;
            }
            td {
            }

            tr:last-child {
              border-bottom: 0;
            }

            h6 {
              font-family: $Poppins-Medium;
              margin-bottom: 15px;
              color: $gray-color-font;
            }
            .ContentHeading {
              font-family: $Poppins-Regular;
              margin: 0;

              padding: 8px;
              padding-left: 0px;
              color: $pink-color !important;
            }
            .ContentValue {
              font-family: $Poppins-Regular;

              margin: 0;
              padding: 8px;
              color: $grey-color;
            }
            .CollapseFeatures {
              font-family: $Poppins-Regular;

              margin: 0;
              padding: 8px;
              color: $pink-color;
            }
          }
        }
      }
      @include xxsMobile {
        display: unset;
      }
    }
    .CollapseFeatures {
      display: flex;
      flex-direction: row;
      font-family: $Poppins-Regular;

      color: $pink-color;
      justify-content: center;
      cursor: pointer;
    }
    .ActivitySection {
      h6 {
        font-size: 16px;
        font-family: $Poppins-SemiBold;
        margin: 0;
        margin-bottom: 15px;
        margin-top: 24px;
        color: $gray-color-font;
      }
      .ActivityWrap {
        display: flex;
        .ListingActivity {
          display: flex;
          align-items: center;
          margin-right: 28px;
          img {
            width: 22px;
            height: 22px;
            margin-right: 10px;
          }
          p {
            font-size: 13px;
            font-weight: 500;
            color: $gray-color-font;
            margin: 0;
          }
        }
      }
      .Goback {
        margin-top: 30px;
        span {
          cursor: pointer;
          font-size: 16px;
          font-family: $Poppins-SemiBold;
          margin: 0;
        }
      }
    }
  }
  .FormAndAdsWrap {
    background-color: $grayBG-color;
  }
}

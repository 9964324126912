@import "../../../App.scss";
.authContainer {
  padding: 0px 50px;
  @include midmobile {
    padding: 0px 10px;
  }

  .authBackground {
    height: 100%;
    width: 100%;
    background-image: url(../../../assets/images/loginBackground.svg);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    position: relative;
  }
}

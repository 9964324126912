@import "../../App.scss";
.SingleOrganisationContainer {
	// margin-left: 10px;
	.TitleWrap {
		display: flex;
		align-items: end;
		justify-content: space-between;
		@include mobile {
			display: unset;
			margin-bottom: 10px;
		}
		h1 {
			font-size: 32px;
			font-weight: 400;
			font-family: $Poppins-SemiBold;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.MainTitle {
		font-size: 16px;
		font-weight: 600;
		line-height: 16px;
		color: #505050;
	}
	.Employee {
		width: 60%;
		@include midmobile {
			width: 100%;
		}
	}
	.OrganizationContainer {
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding: 10px 0px;
		.Container {
			display: flex;
			justify-content: center;
			align-items: center;
			p {
				margin: 0;
				font-size: 16px;
				color: $sizetext-color;
				font-weight: 400;
			}
			svg {
				color: $sizetext-color;
				margin-left: 10px;
			}
		}

		.CrosIconContainer {
			height: 100%;
			cursor: pointer;
		}
		border-bottom: 1px solid #505050;
	}
	.EmployeeListContainer {
		display: flex;
		margin-top: 20px;
		@include midmobile {
			display: unset;
		}
	}
	.IconWrapper {
		margin-top: 30px;
		.IconContainer {
			margin-top: 9px;
			cursor: pointer;
			display: flex;
			align-items: center;
			p {
				padding-left: 5px;
				margin: 0;
				margin-top: 3px;
				font-size: 18px;
				font-weight: 400;
				line-height: 18px;
				color: $pink-color;
			}
		}
	}
	.OrgImage {
		margin-left: 25px;
		@include midmobile {
			margin-top: 20px;
			@include flex;
		}
		.OrgImageWrap {
			width: 114px;
			height: 124px;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
}

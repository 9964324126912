@import "../../App.scss";
.ErroeMain {
	@include flex;
	flex-direction: column;
	height: 100vh;
	h1 {
		color: $pink-color;
		font-size: 6.5rem;
		font-weight: 700px;
		text-align: center;
		margin: 0;
		padding: 20px 0px;
		@include midmobile {
			font-size: 6rem;
		}
	}
	.ErrorImgWrap {
		width: 40%;
		height: 40%;
		@include mobile {
			width: auto;
		}
		margin: 0 auto;
		padding: 20px 0px;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.ContentWrap {
		text-align: center;
		padding: 20px 0px;
		h4 {
			font-size: 1.6rem;
			font-weight: 500;
			margin-bottom: 20px;
			@include midmobile {
				font-size: 1.5rem;
			}
			span {
				color: $pink-color;
			}
		}
		p {
			font-size: 18px;
			font-weight: 400;
			max-width: 700px;
			margin: 0 auto;
			margin-bottom: 10px;
			@include midmobile {
				font-size: 14px;
			}
		}
		button {
			background-color: $pink-color;
			color: $White-color;
			border: none;
			border-radius: 5px;
			padding: 10px 20px;
			min-width: 185px;
		}
	}
}

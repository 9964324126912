@import "../src/assets/style/common.scss";
@import "../src/assets/style/mixin.scss";
@import "../src/assets/style/variable.scss";
body {
  font-family: $Poppins-Regular !important;
}
.row,
.col {
  margin: 0;
  padding: 0;
}
// @media only screen and (max-width: 769px) and (min-width: 767px) {
//   .slick-slide {
//     width: 295px !important;
//   }
// }

.div-center{
  position : fixed;
  left : 50%; 
  top : 50%;
  transform: translate(-50%, -50%);
}
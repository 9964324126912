@import "../../../App.scss";
.AddsLayoutSection {
  background-color: $grayBG-color;
  position: relative;
  //   margin: 40px 0px;
  @include midmobile {
    display: none;
  }
  .backGroundImg {
    position: absolute;
    right: 30px;
    top: -120px;
    @include midlarge {
      width: 100px;
      height: 100px;
      right: 20px;
      top: -90px;
    }
    @media screen and (max-width: "1170px") {
      display: none;
    }
  }
  .AddLayoutContainer {
    .AddsLayoutWrap {
      //   display: grid;
      //   grid-template-columns: repeat(4, 1fr);
      //   grid-column-gap: 50px;
      //   padding: 30px 0px;
      //   @include mobile {
      //     grid-column-gap: 30px;
      //   }
      //   @include midmobile {
      //     grid-template-columns: repeat(2, 1fr);
      //     grid-column-gap: 20px;
      //     grid-row-gap: 20px;
      //   }
      //   @include xxsMobile {
      //     grid-template-columns: 1fr;
      //     grid-row-gap: 20px;
      //   }
      .AddImgWrap {
        width: 100%;
        height: 200px;
        cursor: pointer;
        @include midlarge {
          height: 140px;
        }
        @include mobile {
          height: 100px;
        }
        @include midmobile {
          height: 180px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          padding-top: 8px;
          padding-bottom: 8px;
        }
      }
    }
  }
}

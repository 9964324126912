@import "../../App.scss";
.LoaderWrap {
	height: 80vh;
	width: 100%;
	@include flex;
}
.TemplateContentSection {
	padding: 40px 0px;
	.AccordionSection {
		margin-bottom: 40px;
		padding: 0px 10px;
	}
	.DescriptionWrap {
		margin-bottom: 40px;
		padding: 0px 10px;

		p {
			font-size: 16px;
			font-weight: 400;
			margin: 0;
			margin-bottom: 15px;
		}
	}
	.ContactCardSection {
		margin-bottom: 40px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 20px;
		grid-row-gap: 20px;
		padding: 0px 10px;
		@include mobile {
			grid-template-columns: 1fr;
		}
		.CardMain {
			background-color: #cfcfcf;
			border-radius: 5px;
			padding: 20px;
			.CardTitleWrap {
				display: flex;
				img {
					width: 20px;
					height: 20px;
					margin-right: 15px;
				}
				h1 {
					font-size: 18px;
					color: #1a1818;
					margin: 0;
					margin-bottom: 15px;
					font-family: $Poppins-SemiBold;
				}
			}
			p {
				font-size: 16px;
				color: #1a1818;
				margin: 0;
			}
		}
	}
	.ImgWrap {
		display: flex;
		flex-wrap: wrap;
		max-width: 100%;
		margin-bottom: 40px;
		img {
			display: flex;
			flex: 1;
			width: 50%;
			max-width: 100%;
			// max-height: 435px;
			object-fit: contain;
			border-radius: 15px;
			padding: 10px;
		}
	}
}

@import "../../App.scss";
.SearchOrganisationMain {
  padding: 45px 0px;
  .SearchOrganisationWrap {
    margin-bottom: 40px;
    h1 {
      font-size: 32px;
      font-family: $Poppins-SemiBold;
      margin: 0;
      margin-bottom: 10px;
      @include smallmobile {
        font-size: 24px;
      }
    }
    span {
      font-size: 16px;
      font-weight: 500;
    }
    .SearchBased {
      display: flex;
      margin: 15px 0px;
      @include smallmobile {
        flex-wrap: wrap;
        width: 100%;
      }
      .SelectionBox {
        @include flex;
        cursor: pointer;
        flex-direction: column;
        border-radius: 5px;
        background-color: $btn-color;
        max-width: 235px;
        height: 160px;
        width: 100%;
        margin-right: 20px;
        &:last-child {
          margin-right: unset;
        }
        @include smallmobile {
          margin-right: unset;
          max-width: unset;
          width: 100%;
          margin-bottom: 15px;
        }
        img {
          margin-bottom: 20px;
        }
        p {
          font-size: 16px;
          margin: 0;
          font-family: $Poppins-SemiBold;
        }
      }
      .SelectionBoxActive {
        @include flex;
        cursor: pointer;
        flex-direction: column;
        border-radius: 5px;
        background-color: $btn-color;
        max-width: 235px;
        height: 160px;
        width: 100%;
        margin-right: 20px;
        border: 2px solid $pink-color;
        &:last-child {
          margin-right: unset;
        }
        @include smallmobile {
          margin-right: unset;
          max-width: unset;
          width: 100%;
          margin-bottom: 15px;
        }
        img {
          margin-bottom: 20px;
        }
        p {
          font-size: 16px;
          margin: 0;
          font-family: $Poppins-SemiBold;
        }
      }
    }
    .Inpotbox {
      margin: 50px 0px;
      max-width: 555px;
      width: 100%;
      .errorText {
        @include error;
      }
      p {
        font-size: 16px;
        font-weight: 600;
        line-height: 14px;
        color: $light-black-color;
      }
      input {
        padding: 10px 15px;
        border-radius: 5px;
        background-color: $btn-color;
        border: none;
        width: 100%;
      }
      input:focus {
        outline: none;
      }
    }
    .btnActive {
      padding: 20px 15px;
      border-radius: 5px;
      background: $pink-color;
      border: none;
      @include fontStyle;
      color: $White-color;
      min-width: 150px;
    }
    .btnInActive {
      padding: 20px 15px;
      border-radius: 5px;
      background: $btn-color;
      border: none;
      @include fontStyle;
      color: $black-color;
      min-width: 150px;
    }
  }
  .PropertyCardSection {
    padding: 20px 0px;
    border-top: 2px solid black;
    .DropDownWrap {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
      @include smMobile {
        flex-wrap: wrap;
      }
      .DropDown {
        display: flex;
        align-items: center;
        margin-right: 20px;
        font-family: $Poppins-Medium;
        @include smMobile {
          margin-right: unset;
        }
        &:last-child {
          margin-right: unset;
        }
        p {
          margin: 0;
          font-size: 16px;
          font-weight: 500;
          margin-right: 10px;
          width: 100%;
          white-space: nowrap;
        }
        select {
          border: none;
          font-size: 16px;
          font-weight: 400;
          -webkit-appearance: none;
          background-image: url(../../assets/icons/DropdownIcon.svg);
          background-position: right 0px center;
          background-repeat: no-repeat;
          padding-right: 15px;
          text-align: center;
        }
      }
    }
    .CardSection {
      max-width: 742px;
      width: 100%;
      .CardMain {
        width: 100%;
        padding: 20px;
        display: flex;
        align-items: center;
        box-shadow: 3px 3px 3px 3px rgba(152, 78, 78, 0.16);
        border-radius: 10px;
        margin-bottom: 20px;
        cursor: pointer;
        @include smMobile {
          flex-wrap: wrap;
        }
        .CardImg {
          cursor: pointer;
          max-width: 270px;
          min-width: 270px;
          width: 100%;
          height: 170px;
          border-radius: 5px;
          @include smMobile {
            min-width: 100%;
            min-height: 100%;
            margin-bottom: 15px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 5px;
          }
        }
        .CardContent {
          padding-left: 20px;
          width: 100%;
          overflow: hidden;
          @include smMobile {
            padding-left: unset;
          }
          .name {
            margin: 0;
            font-size: 16px;
            font-weight: 600;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          p {
            margin: 0;
            font-size: 16px;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .Pagination {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        .pageText {
          color: $btn-text-color;
          margin-right: 15px;
        }
        .PageWrap {
          display: flex;
          .page {
            margin-right: 10px;
            .Active {
              font-weight: 900;
              cursor: pointer;
            }
            .InActive {
              color: #1a1818;
              cursor: pointer;
            }
          }
        }
        .NextPageActive {
          font-weight: 900;
          cursor: pointer;
          cursor: pointer;
          margin-right: 10px;
          span {
            @include smMobile {
              display: none;
            }
          }
          :last-child {
            margin-right: unset;
          }
        }
        .NextPageInActive {
          color: #1a1818;
          cursor: pointer;
          margin-right: 10px;
          span {
            @include smMobile {
              display: none;
            }
          }
          :last-child {
            margin-right: unset;
          }
        }
      }
    }
  }
}

@import "../../App.scss";
.ConnectionContainer {
  height: 60vh;
  margin-left: 10px;
  .TitleWrap {
    display: flex;
    align-items: end;
    justify-content: space-between;
    @include mobile {
      display: unset;
      margin-bottom: 10px;
    }
    h1 {
      font-size: 32px;
      font-weight: 400;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      margin: 0;
    }
  }
  .media{
    display: flex;
    margin-top: 15px;
    .ImageContainer{
      width: 40px;
    }
    p{
      max-width: 300px;
      color: #505050;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
    }
  }
  .MainTitle {
    padding-top: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #505050;
  }
}

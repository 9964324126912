@import "../../../App.scss";
.CardListingWrap {
  .PropertyCard {
    padding: 30px 0px;
    display: flex;
    border-bottom: 2px solid $borderBottom-color;
    @include smMobile {
      flex-wrap: wrap;
    }
    .PropertyImg {
      cursor: pointer;
      max-width: 270px;
      width: 100%;
      height: 170px;
      border-radius: 5px;
      position: relative;
      @include smMobile {
        min-width: 100%;
        min-height: 100%;
        margin-bottom: 15px;
      }
      .PropertyStatus {
        bottom: 10px;
        left: 10px;
        padding: 5px;
        position: absolute;
        font-size: 11px;
        border-radius: 5px;
        color: $White-color;
        background-color: $pink-color;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }
    .MainContentWrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 20px;
      width: 100%;
      @include xxsMobile {
        padding: 0px;
      }
      .iconWrap {
        cursor: pointer;
        svg {
          font-size: 25px;
          color: #505050;
        }
        .EditIconWrap {
          display: flex;
          flex-direction: column;
          img {
            width: 22px;
            height: 22px;
            margin-bottom: 5px;
            @include xxsMobile {
              width: 20px;
              height: auto;
            }
          }
          svg {
            font-size: 25px;
            color: #505050;
            margin-bottom: 5px;
            @include xxsMobile {
              font-size: 20px;
            }
          }
        }
      }
      .PropertyContent {
        @include smMobile {
          margin: 0;
        }
        h5 {
          font-size: 16px;
          font-family: $Poppins-SemiBold;
          margin: 0;
          cursor: pointer;
        }
        span {
          font-size: 16px;
          font-weight: 400;
          cursor: pointer;
        }
        .PriceText {
          font-size: 20px;
          font-family: $Poppins-SemiBold;
          margin: 10px 0px;
        }
        .PropertySize {
          display: flex;
          flex-wrap: wrap;
          .PropertySizeCount {
            @include flex;
            margin-right: 20px;
            &:last-child {
              margin-right: unset;
            }
            img {
              width: 26px;
              height: 20px;
              margin-right: 10px;
            }
            p {
              font-size: 16px;
              font-weight: 300;
              color: $sizetext-color;
              margin: 0;
            }
          }
        }
        .CardLink {
          font-size: 16px;
          font-weight: 400;
          color: $pink-color;
          margin: 0;
          cursor: pointer;
          white-space: nowrap;
          width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          @include smMobile {
            margin-top: 20px;
          }
        }
      }
    }
  }
  .PropertyCardSearch {
    padding: 20px;
    display: flex;
    box-shadow: 3px 3px 3px 3px rgba(152, 78, 78, 0.16);
    border-radius: 10px;
    margin-bottom: 20px;
    @include smMobile {
      flex-wrap: wrap;
    }
    .PropertyImg {
      cursor: pointer;
      max-width: 270px;
      width: 100%;
      height: 170px;
      border-radius: 5px;
      position: relative;
      @include smMobile {
        min-width: 100%;
        min-height: 100%;
        margin-bottom: 15px;
      }
      .PropertyStatus {
        bottom: 10px;
        left: 10px;
        padding: 5px;
        position: absolute;
        font-size: 11px;
        border-radius: 5px;
        color: $White-color;
        background-color: $pink-color;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }
    .MainContentWrap {
      display: flex;
      justify-content: space-between;
      padding-left: 20px;
      width: 100%;
      @include xxsMobile {
        padding: 0px;
      }
      .iconWrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: end;
        svg {
          cursor: pointer;
          font-size: 25px;
          color: #505050;
        }
        .lable {
          padding: 10px;
          font-size: 16px;
          color: $White-color;
          min-width: 70px;
          max-width: 70px;
          text-align: center;
          border-radius: 5px;
          // clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0 49%, 0% 0%);
        }
      }
      .ActivityWrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: end;
        .ViewAndFavContainer {
          display: flex;
          width: max-content;
          .ListingActivity {
            display: flex;
            align-items: center;
            margin-right: 10px;
            img {
              width: 22px;
              height: 22px;
              margin-right: 10px;
            }
            p {
              font-size: 13px;
              font-weight: 500;
              color: $gray-color-font;
              margin: 0;
            }
          }
        }
        .lable {
          padding: 10px;
          font-size: 16px;
          color: $White-color;
          min-width: 70px;
          max-width: 70px;
          text-align: center;
          border-radius: 5px;
          // clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0 49%, 0% 0%);
        }
      }
      .PropertyContent {
        margin-top: 10px;
        @include smMobile {
          margin: 0;
        }
        h5 {
          font-size: 16px;
          font-family: $Poppins-SemiBold;
          margin: 0;
          cursor: pointer;
          white-space: nowrap;
          max-width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        span {
          font-size: 16px;
          font-weight: 400;
          cursor: pointer;
        }
        .PriceText {
          font-size: 20px;
          font-family: $Poppins-SemiBold;
          margin: 10px 0px;
        }
        .PropertySize {
          display: flex;
          flex-wrap: wrap;
          .PropertySizeCount {
            @include flex;
            margin-right: 20px;
            &:last-child {
              margin-right: unset;
            }
            img {
              width: 26px;
              height: 20px;
              margin-right: 10px;
            }
            p {
              font-size: 16px;
              font-weight: 300;
              color: $sizetext-color;
              margin: 0;
            }
          }
        }
        .CardLink {
          font-size: 16px;
          font-weight: 400;
          color: $pink-color;
          margin: 0;
          cursor: pointer;
          white-space: nowrap;
          width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          @include smMobile {
            margin-top: 20px;
          }
        }
      }
    }
  }
  .InvisibleCard {
    opacity: 50%;
  }
  .PropertyCardSearchSpace {
    margin-right: 20px;
    @include midmobile {
      margin-right: unset;
    }
  }
}

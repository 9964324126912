@import "../../../App.scss";
.AdsSection {
  margin-top: 50px;
  margin-bottom: 50px;
  .TextAds {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    .AdsWrap {
      margin: 50px 0px;
      h2 {
        font-size: 32px;
        font-weight: 400;
        margin-bottom: 20px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
      }
      img {
        max-width: 100%;
        height: auto;
        display: block;
      }
    }
    @include mobile {
      grid-template-columns: 1fr;
    }
  }
  .ImgAds {
    .ImgAdsWrap {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 50px;
      padding: 30px 0px;
      @include mobile {
        grid-column-gap: 30px;
      }
      @include midmobile {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
      }
      @include xxsMobile {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
      }
      .ImgWrap {
        width: 100%;
        height: 200px;
        cursor: pointer;
        @include midlarge {
          height: 140px;
        }
        @include mobile {
          height: 100px;
        }
        @include midmobile {
          height: 180px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

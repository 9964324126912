@import "../../../App.scss";
$mainpink: #DF1881;
$subpink: #FF5FB3;
$maingray: #CECECE;
$subgray: #6E7072;

$startpointmobile: 769px;
$startpointtablet: 991px;
$medpointtablet: 1199px;
$smallscreen: 1200px;
$mediumscreen: 1400px;

.grid_5 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    gap: 20px;
    width: 100%;
    box-sizing: border-box;
    margin: 0px auto;
    position: relative;
    z-index: 5;

    .addnewitem {
        background-color: #EDEDED;
        border-radius: 25px;
        box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.22);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px;

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;

            i {
                font-size: 6em;
                color: $subgray;
            }

            p {
                margin: 0;
                font-size: 1.35em;
                color: $subgray;
                font-weight: 600;
            }
        }
    }

    .item {
        width: 100%;
        height: 100%;
        padding: 20px;
        border-radius: 25px;
        box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.12);
        max-width: 320px;

        .image {
            width: 100%;
            height: 80%;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                aspect-ratio: 4/5;
                border-radius: 8px;
            }

            .tags {
                position: relative;
                bottom: 25%;
                left: 40%;
                transform: translateX(-40%);
                display: flex;
                gap: 10px;
                width: 100%;
                justify-content: center;

                .tag {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    background-color: $subpink;
                    color: #ffffff;
                    padding: 5px 10px;
                    border-radius: 4px;
                    font-size: 0.9em;
                    width: 40%;
                    text-align: center;

                    i {
                        font-size: 1.3em;
                        margin-right: 5px;
                    }

                    span {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        text-align: left;
                        width: 100%;

                        .number {
                            font-weight: bold;
                            font-size: 1.1em;
                        }

                        .label {
                            font-size: 0.9em;
                        }
                    }
                }
            }
        }

        .content {
            width: 100%;
            height: 20%;
            margin-top: 12px;

            .button {
                display: inline-block;
                margin-top: 10px;
                border-radius: 9999px;
                padding: 10px 18px;
                background: #cecece;
                color: #6e7072;
                font-size: 1em;
                font-weight: 600;
                transition: background .3s ease, color .3s ease;
            }

            h4 {
                margin: 0;
                font-size: 1.2em;
                color: $subgray;
            }
        }
    }

}
@import "../../App.scss";
.MembershiPricWrap {
  background-color: #f6f6f6;
  .MembershiPricSection {
    h1 {
      font-size: 64px;
      font-family: $Poppins-Bold !important;
      line-height: 64px;
      font-style: normal;
      text-align: center;
      padding: 20px 0px;
      @include mobile {
        font-size: 42px;
      }
      @include smMobile {
        font-size: 26px;
      }
    }
    .OptionSelectSection {
      margin: 50px 0px;
      h3 {
        font-size: 20px;
        font-family: $Poppins-SemiBold !important;
        line-height: 20px;
        font-style: normal;
        text-align: center;
        @include mobile {
          font-size: 16px;
        }
      }
      .ToggleSection {
        width: 100%;
        max-width: 430px;
        background-color: $White-color;
        border-radius: 50px;
        border: 2px solid $pink-color;
        display: flex;
        margin: 20px auto;
        .Option {
          width: 50%;
          padding: 20px;
          text-align: center;
          cursor: pointer;
          span {
            font-size: 16px;
            font-family: $Poppins-SemiBold;
          }
        }
        .active {
          width: 50%;
          padding: 20px;
          text-align: center;
          background-color: $pink-color;
          border-radius: 50px;
          cursor: pointer;
          span {
            font-size: 16px;
            font-family: $Poppins-SemiBold;
            color: $White-color;
          }
        }
      }
    }
    .OfferSection {
      h4 {
        font-size: 32px;
        font-family: $Poppins-SemiBold !important;
        line-height: 32px;
        text-align: center;
        color: $pink-color;
        margin: 20px 0px;
        margin-bottom: 50px;
        @include mobile {
          font-size: 16px;
        }
      }
      .CardSection {
        .MainSlider {
          .slick-prev {
            content: ">";
            left: 20px !important;
            background-color: red;
          }
          .MainCardWrap {
            margin: 10px;
            max-width: 250px;
            // min-width: 250px;
            width: 100%;
            padding: 20px;
            background-color: $White-color;
            border: 2px solid $card-border;
            border-radius: 5px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .CardContentWrap {
              .OfferTitleWrap {
                img {
                  width: 50px;
                  height: 60px;
                  margin: 0 auto;
                  margin-bottom: 25px;
                }
                h5 {
                  font-size: 16px;
                  font-family: $Poppins-SemiBold !important;
                  line-height: 32px;
                  font-style: normal;
                  text-align: center;
                }
              }
              h2 {
                font-size: 26px;
                font-family: $Poppins-SemiBold !important;
                font-style: normal;
                text-align: center;
                margin: 20px 0px;
                span {
                  font-size: 16px;
                }
              }
              p {
                font-family: 14px;
                font-weight: 400;
              }
              .OfferListWrap {
                border-top: 2px solid $card-border;
                border-bottom: 2px solid $card-border;
                padding: 20px 0px;
                text-align: left;
                ul {
                  list-style: none;
                  padding: unset;
                  margin: unset;
                  li {
                    font-size: 12px;
                    font-weight: 400;
                    -ms-word-break: break-word;
                    word-break: break-word;
                    white-space: pre-wrap;
                    display: flex;
                    align-items: start;
                    margin: 5px 0px;
                    img {
                      height: 12px;
                      width: 12px;
                      margin-right: 10px;
                      margin-top: 3px;
                    }
                  }
                }
              }
              .OfferPoinWrap {
                padding: 20px 0px;
                text-align: left;
                ul {
                  list-style-position: outside;
                  padding-left: 17px;
                  margin: unset;
                  li {
                    font-size: 12px;
                    font-weight: 400;
                    -ms-word-break: break-word;
                    word-break: break-word;
                    white-space: pre-wrap;
                    color: #505050;
                    margin: 5px 0px;
                    span {
                      width: 10px;
                      height: 10px;
                      background-color: #505050;
                    }
                  }
                }
              }
            }
            .BtnSection {
              .DiscountWrap {
                box-shadow: (3px 3px 5px rgba(0, 0, 0, 0.16));
                padding: 20px;
                border-radius: 15px;
                margin: 15px 0px;
                p {
                  font-size: 15px;
                  font-weight: 500;
                  margin: 0;
                  color: $pink-color;
                  &::before {
                    content: url(../../assets//icons//DiscountImg.svg);
                    vertical-align: middle;
                    margin-right: 10px;
                    height: 16px;
                    width: 15px;
                  }
                }
              }
              button {
                width: 100%;
                border-radius: 5px;
                border: none;
                background-color: $pink-color;
                color: $White-color;
                padding: 20px 50px;
                margin-top: 15px;
              }
            }
          }
          .GoldPlaneWrap {
            cursor: pointer;
            position: relative;
            margin: 10px 10px;
            max-width: 250px;
            // min-width: 250px;
            width: 100%;
            padding: 20px;
            background-color: $White-color;
            border: 2px solid $card-border;
            border-radius: 5px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .MostPopular {
              position: absolute;
              top: -9px;
              left: -8px;
              p {
                margin: 0;
                position: absolute;
                color: $White-color;
                transform: rotate(316deg);
                top: 40px;
                font-size: 9px;
                right: 39px;
              }
            }
            .CardContentWrap {
              .OfferTitleWrap {
                img {
                  width: 50px;
                  height: 60px;
                  margin: 0 auto;
                  margin-bottom: 25px;
                }
                h5 {
                  font-size: 16px;
                  font-family: $Poppins-SemiBold !important;
                  line-height: 32px;
                  font-style: normal;
                  text-align: center;
                }
              }
              .PriceDiv {
                margin: 15px 0;
                h2 {
                  font-size: 26px;
                  font-family: $Poppins-SemiBold !important;
                  font-style: normal;
                  text-align: center;
                }
                span {
                  font-size: 16px;
                  font-weight: 600;
                }
                h6 {
                  font-size: 14px;
                  color: $gray-color-font;
                  margin-top: 6px !important;
                }
              }
              p {
                font-family: 14px;
                font-weight: 400;
              }
              .OfferListWrap {
                border-top: 2px solid $card-border;
                // border-bottom: 2px solid $card-border;
                padding: 20px 0px;
                text-align: left;
                height: 45px;
                overflow: hidden !important;
                text-overflow: ellipsis !important;
                width: 100%;
                ul {
                  list-style: none;
                  padding: unset;
                  margin: unset;
                  li {
                    font-size: 12px;
                    font-weight: 400;
                    -ms-word-break: break-word;
                    word-break: break-word;
                    white-space: pre-wrap;
                    display: flex;
                    align-items: start;
                    margin: 5px 0px;
                    img {
                      height: 12px;
                      width: 12px;
                      margin-right: 10px;
                      margin-top: 3px;
                    }
                  }
                }
              }
              .OfferPoinWrap {
                padding: 20px 0px;
                text-align: left;
                ul {
                  list-style-position: outside;
                  padding-left: 17px;
                  margin: unset;
                  li {
                    font-size: 12px;
                    font-weight: 400;
                    -ms-word-break: break-word;
                    word-break: break-word;
                    white-space: pre-wrap;
                    color: #505050;
                    margin: 5px 0px;
                    span {
                      width: 10px;
                      height: 10px;
                      background-color: #505050;
                    }
                  }
                }
              }
            }
            .BtnSection {
              .DiscountWrap {
                box-shadow: (3px 3px 5px rgba(0, 0, 0, 0.16));
                padding: 20px;
                border-radius: 15px;
                margin: 15px 0px;
                p {
                  font-size: 15px;
                  font-weight: 500;
                  margin: 0;
                  color: $pink-color;
                  &::before {
                    content: url(../../assets//icons//DiscountImg.svg);
                    vertical-align: middle;
                    margin-right: 10px;
                    height: 16px;
                    width: 15px;
                  }
                }
              }
              button {
                width: 100%;
                border-radius: 5px;
                border: none;
                background-color: $pink-color;
                color: $White-color;
                padding: 20px 50px;
                margin-top: 15px;
              }
            }
          }
          .GoldPlaneWrapActive {
            border: 2px solid $pink-color;
          }
        }
      }
    }
  }
}

@import "../../App.scss";
.ViewOrgMain {
  .OrgImgWrap {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .OrgContent {
    margin: 20px 0px;
    box-shadow: 3px 3px 10px 5px rgba(0, 0, 0, 0.16);
    border-radius: 15px;
    padding: 20px;
    display: flex;
    width: 100%;
    @include tablet {
      flex-wrap: wrap;
      justify-content: center;
    }
    .OrgLogoWrap {
      max-width: 150px;
      width: 100%;
      height: 150px;
      padding: 10px;
      @include tablet {
        margin-bottom: 15px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .OrgContentWrap {
      margin-left: 30px;
      @include tablet {
        margin-left: 0;
      }
      h1 {
        font-size: 32px;
        font-weight: 400;
        margin: 0;
        margin-bottom: 15px;
        -ms-word-break: break-word;
        word-break: break-word;
        white-space: pre-wrap;
        @include smallmobile {
          font-size: 26px;
        }
      }
      p {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        color: $light-black-color;
        margin-bottom: 15px;
        max-width: 90%;
        width: 100%;
      }
      .OrgInfoWrap {
        display: flex;
        @include smallmobile {
          flex-wrap: wrap;
        }
        .OrgInfo {
          display: flex;
          align-items: center;
          margin-right: 30px;
          @include smallmobile {
            margin-right: unset;
            margin-bottom: 10px;
          }
          img,
          svg {
            width: 14px;
            height: 14px;
            margin-right: 10px;
          }
          a {
            text-decoration: unset;
            font-size: 16px;
            font-weight: 400;
            margin: 0;
            color: $black-color;
            -ms-word-break: break-word;
            word-break: break-word;
            white-space: pre-wrap;
          }
        }
      }
    }
  }
  .OrgMainInfo {
    margin-bottom: 40px;
    .InfoWrap {
      margin-bottom: 40px;
      h6 {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        margin-bottom: 10px;
        color: $light-black-color;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: $primaryBlack-color;
        -ms-word-break: break-word;
        word-break: break-word;
        white-space: pre-wrap;
        margin: 0 !important;
      }
      a {
        text-decoration: unset;
        font-size: 16px;
        font-weight: 400;
        color: $primaryBlack-color;
        -ms-word-break: break-word;
        word-break: break-word;
        white-space: pre-wrap;
        line-height: 3px;
      }
    }
    .DescriptionWrap {
      h6 {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        margin-bottom: 10px;
        color: $light-black-color;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        max-width: 70%;
        @include mobile {
          width: 100%;
          max-width: unset;
        }
      }
    }
  }
  .EmployeInfo {
    h6 {
      font-size: 16px;
      font-weight: 400;
      margin: 0;
      margin-bottom: 10px;
      color: $light-black-color;
    }
    .EmployeInfoWrap {
      display: flex;
      flex-wrap: wrap;
      .SingleEmployeInfo {
        display: flex;
        margin-right: 30px;
        @include tablet {
          flex-wrap: wrap;
          margin-bottom: 20px;
        }
        .EmployeImg {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          margin-right: 15px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        .EmployeDetails {
          .EmployeName {
            font-size: 14px;
            font-weight: 500;
            color: $pink-color;
            margin: 0;
          }
          p {
            font-size: 12px;
            font-weight: 400;
            margin: 0;
          }
        }
      }
    }
  }
  .Goback {
    margin: 50px 0px;
    span {
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      margin: 0;
    }
  }
}

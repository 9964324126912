@import "../../../App.scss";
.MyListingLayoutMain {
  .AddListingOption {
    margin-bottom: 25px;
    p {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      margin-bottom: 10px;
      color: $light-black-color;
    }
    .AddListingOptionBtnWrap {
      .MySelfBtn {
        background-color: $btn-color;
        border: none;
        border-radius: 5px;
        padding: 15px;
        margin-right: 10px;
        @include midmobile {
          width: 100%;
          margin-bottom: 10px;
        }
      }
      .OrganisationBtn {
        background-color: $pink-color;
        color: $White-color;
        border: none;
        margin-right: 10px;
        border-radius: 5px;
        padding: 15px;
        @include midmobile {
          width: 100%;
        }
      }
    }
  }
  .OrgSelect {
    margin-bottom: 25px;
    p {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      margin-bottom: 10px;
      color: $light-black-color;
    }
    .OrganizationContainer {
      cursor: pointer;
      .Org {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        margin-bottom: 10px;
        color: $light-black-color;
      }
      .OrgActive {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        margin-bottom: 10px;
        color: $pink-color;
      }
    }
    span {
      font-size: 16px;
      font-weight: 400;
      margin: 0;
      margin-bottom: 10px;
      color: $pink-color;
    }
  }
  .SellPropertyOptions {
    margin-bottom: 25px;
    p {
      font-size: 16px;
      font-family: $Poppins-SemiBold;
      margin: 0;
      margin-bottom: 10px;
      color: $light-black-color;
    }
    .SellPropertyOptionsBtn {
      width: 100%;
      .RentBtn {
        background-color: $btn-color;
        border: none;
        border-radius: 5px;
        padding: 15px 30px;
        margin-right: 10px;
        max-width: 200px;
        @include midmobile {
          width: 100%;
          max-width: unset;
          margin-bottom: 10px;
        }
        &:hover {
          background-color: $pink-color;
          color: $White-color;
        }
      }
      .RentBtnActive {
        background-color: $pink-color;
        border: none;
        border-radius: 5px;
        padding: 15px 30px;
        margin-right: 10px;
        max-width: 200px;
        color: $White-color;
        @include midmobile {
          width: 100%;
          max-width: unset;
          margin-bottom: 10px;
        }
        &:hover {
          background-color: $pink-color;
          color: $White-color;
        }
      }
    }
  }
  .PropertyFormMain {
    max-width: 550px;
    width: 100%;
    .PropertyAddress {
      margin-bottom: 20px;
      p {
        font-size: 16px;
        font-family: $Poppins-SemiBold;
        margin: 0;
        margin-bottom: 10px;
        color: $light-black-color;
      }
      .InputFildBox {
        p {
          font-size: 16px;
          font-weight: 400;
          margin: 0;
          margin-bottom: 10px;
          color: $light-black-color;
        }
        input {
          border: none;
          background-color: $btn-color;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 400;
          color: $primaryBlack-color !important;
          padding: 10px 20px;
          width: 100%;
        }
        input:focus {
          outline: none;
        }
      }
      .flexInput {
        display: flex;
        width: 100%;
        margin-top: 15px;
        .InputFildBox {
          width: 50%;
          select {
            border: none;
            background-color: $btn-color;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 400;
            color: $primaryBlack-color !important;
            padding: 10px 20px;
            width: 100%;
            -webkit-appearance: none;
            background-image: url(../../../assets/icons/DropdownIcon.svg);
            background-position: right 15px center;
            background-repeat: no-repeat;
            &:last-child {
              margin-bottom: 10px;
            }
          }
        }
      }
      .InputFildBox:last-child {
        margin-left: 20px;
      }
    }
    .PropertyAvailable {
      margin-bottom: 20px;
      p {
        font-size: 16px;
        font-family: $Poppins-SemiBold;
        margin: 0;
        margin-bottom: 10px;
        color: $light-black-color;
      }
      input {
        border: none;
        background-color: $btn-color;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 400;
        color: $primaryBlack-color !important;
        padding: 10px 20px;
        max-width: 200px;
        width: 100%;
        margin-bottom: 10px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
      input:focus {
        outline: none;
      }
    }
    .PropertyBedroomNum {
      margin-bottom: 20px;
      p {
        font-size: 16px;
        font-family: $Poppins-SemiBold;
        margin: 0;
        margin-bottom: 10px;
        color: $light-black-color;
      }
      input {
        border: none;
        background-color: $btn-color;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 400;
        color: $primaryBlack-color !important;
        padding: 10px 20px;
        max-width: 60px;
      }
      input:focus {
        outline: none;
      }
      .BedroomRnge {
        font-size: 20px;
        cursor: pointer;
        margin-left: 10px;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
      }
    }
    .PriceContainer {
      margin-bottom: 20px;
      .pricetitle {
        font-size: 16px;
        font-family: $Poppins-SemiBold;
        margin: 0;
        margin-bottom: 10px;
        color: $light-black-color;
        padding-bottom: 10px;
        border-bottom: 1px solid $borderBottom-color;
      }
      .MainPriceWrap {
        border-bottom: 1px solid $borderBottom-color;
      }
      .PriceWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .PropertyPriceWrap {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 15px;
          grid-row-gap: 15px;
          @include xxsMobile {
            grid-template-columns: 1fr;
          }
          .InputFildBox {
            p {
              font-size: 16px;
              font-weight: 400;
              margin: 0;
              margin-bottom: 10px;
              color: $light-black-color;
            }
            input {
              border: none;
              background-color: $btn-color;
              border-radius: 5px;
              font-size: 16px;
              font-weight: 400;
              color: $primaryBlack-color !important;
              padding: 10px 20px;
              width: 100%;
              -webkit-appearance: none;
            }
            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              margin: 0;
            }
            input:focus {
              outline: none;
            }
            select {
              border: none;
              background-color: $btn-color;
              border-radius: 5px;
              font-size: 16px;
              font-weight: 400;
              color: $primaryBlack-color !important;
              padding: 10px 20px;
              width: 100%;
              -webkit-appearance: none;
              background-image: url(../../../assets/icons/DropdownIcon.svg);
              background-position: right 15px center;
              background-repeat: no-repeat;
              &:last-child {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
    .ApplicableOption {
      margin-bottom: 20px;
      p {
        font-size: 16px;
        font-family: $Poppins-SemiBold;
        margin: 0;
        margin-bottom: 10px;
        color: $light-black-color;
      }
      .PropertyOptionLabel {
        display: flex;
        align-items: center;
        span {
          font-weight: 400;
          font-size: 16px;
          color: $primaryBlack-color;
          margin: 0;
        }
        .radioCustom {
          opacity: 0;
          position: absolute;
        }
        .radioCustom,
        .radioCustomLabel {
          display: inline-block;
          vertical-align: middle;
          margin: 5px;
          cursor: pointer;
        }
        .radioCustomLabel {
          position: relative;
        }
        .radioCustom + .radioCustomLabel:before {
          content: "";
          background: #fff;
          border: 1.5px solid #595f72;
          display: inline-block;
          vertical-align: middle;
          border-radius: 2px;
          padding: 7px;
          text-align: center;
        }
        .radioCustom + .radioCustomLabel:before {
          border-radius: 5px;
        }
        .radioCustom:checked + .radioCustomLabel:before {
          background: url(../../../assets/icons/RightIcon.svg);
          background-size: 10px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
    .PropertyDetailWrap {
      margin-bottom: 20px;
      .InputWrap {
        display: flex;
        width: 100%;
        align-items: end;

        @include midmobile {
          flex-wrap: wrap;
        }
        .InputFildBox {
          margin-right: 20px;
          @include midmobile {
            margin-right: unset;
          }
        }
        :last-child {
          margin-right: unset;
        }
      }
      .InputFildBox {
        width: 100%;
        margin-bottom: 10px;
        p {
          font-size: 16px;
          font-family: $Poppins-SemiBold;
          margin: 0;
          margin-bottom: 10px;
          color: $light-black-color;
        }
        input {
          border: none;
          background-color: $btn-color;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 400;
          color: $primaryBlack-color !important;
          padding: 10px 20px;
          width: 100%;
        }
        input:focus {
          outline: none;
        }
        .energyLabel {
          border: none;
          background-color: $btn-color;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 400;
          color: $primaryBlack-color !important;
          padding: 10px 20px;
          width: 105px;
          margin-bottom: 10px;
        }
        select {
          border: none;
          background-color: $btn-color;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 400;
          color: $primaryBlack-color !important;
          padding: 10px 20px;
          width: 100%;
          -webkit-appearance: none;
          background-image: url(../../../assets/icons/DropdownIcon.svg);
          background-position: right 15px center;
          background-repeat: no-repeat;
        }
      }
    }
    .AddQuote {
      margin-bottom: 20px;
      .InputFildBox {
        p {
          font-size: 16px;
          font-family: $Poppins-SemiBold;
          margin: 0;
          margin-bottom: 10px;
          color: $light-black-color;
        }
        textarea {
          border: none;
          background-color: $btn-color;
          border-radius: 5px;
          font-size: 16px;
          resize: none;
          font-weight: 400;
          color: #505050 !important;
          padding: 10px 20px;
          width: 100%;
          max-height: 100px;
          min-height: 100px;
          height: 100%;
        }
        textarea:focus {
          outline: none;
        }
      }
    }
    .PropertyDescription {
      margin-bottom: 20px;
      .InputFildBox {
        .DescriptionTitle {
          font-size: 16px;
          font-family: $Poppins-SemiBold;
          margin: 0;
          margin-bottom: 10px;
          color: $light-black-color;
        }
        textarea:focus {
          outline: none;
        }
      }
    }
    .UserInfodatails {
      margin-bottom: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 10px;
      @include midmobile {
        grid-template-columns: 1fr;
      }
      .InputFildBox {
        margin-right: 20px;
        p {
          font-size: 16px;
          font-family: $Poppins-SemiBold;
          margin: 0;
          margin-bottom: 10px;
          color: $light-black-color;
        }
        input {
          border: none;
          background-color: $btn-color;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 400;
          color: $primaryBlack-color !important;
          padding: 10px 20px;
          width: 100%;
          margin-bottom: 10px;
        }
        input:focus {
          outline: none;
        }
      }
    }
  }
  .saveButton {
    border-radius: 5px;
    background: $pink-color;
    border: none;
    color: $White-color;
    padding: 20px;
    min-width: 180px;
  }
  .divWrap {
    margin-bottom: 20px;
    @include smallmobile {
      width: 100%;
    }
    .SectionTitleWrap {
      display: flex;
      justify-content: space-between;
      align-items: end;
      span {
        margin-bottom: 10px;
        color: $sizetext-color;
        font-size: 10px;
        font-weight: 300;
        font-style: italic;
      }
    }
    .uploadMain {
      label {
        width: 100%;
        .PhotoUpload {
          width: 100%;
          height: 170px;
          border: none;
          background-color: $btn-color;
          border-radius: 5px;
          padding: 10px;
          overflow: auto;
          @include flex;
          .AllImgWrap {
            display: flex;
            flex-wrap: wrap;
          }
        }
        .DisplayAfterImageUpload {
          width: 100%;
          height: 170px;
          border: none;
          background-color: $btn-color;
          border-radius: 5px;
          padding: 10px;
          overflow: auto;
          .AllImgWrap {
            display: flex;
            flex-wrap: wrap;
            .UploadImg {
              position: relative;
              img {
                width: 60px;
                height: 60px;
                border-radius: 5px;
                margin-right: 10px;
                margin-bottom: 10px;
              }
              .VideoWrap {
                position: relative;
                video {
                  width: 60px;
                  height: 60px;
                  border-radius: 5px;
                  margin-right: 10px;
                  margin-bottom: 10px;
                  object-fit: cover;
                }
                span {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-62%, -75%);
                  img {
                    width: 25px;
                    height: 25px;
                    margin: 0 auto;
                  }
                }
              }
              span {
                cursor: pointer;
                position: absolute;
                top: -3px;
                right: 12px;
                svg {
                  background-color: $White-color;
                  border-radius: 50%;
                  padding: 3px;
                }
              }
            }
            .AfterUploadImageIcon {
              width: auto;
              height: auto;
              margin-right: 10px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    p {
      font-size: 16px;
      font-family: $Poppins-SemiBold;
      line-height: 14px;
      color: $light-black-color;
    }
    input {
      padding: 15px;
      border-radius: 5px;
      background-color: $btn-color;
      border: none;
      width: 100%;
    }
    input:focus {
      outline: none;
    }
    &:last-child {
      margin-left: 20px;
      @include smallmobile {
        margin-left: unset;
      }
    }
  }
  .SelectMainImg {
    margin-bottom: 20px;
    @include smallmobile {
      width: 100%;
    }
    p {
      font-size: 16px;
      font-family: $Poppins-SemiBold;
      line-height: 14px;
      color: $light-black-color;
    }
    .SelectImgWrap {
      width: 100%;
      height: 170px;
      border: none;
      background-color: $btn-color;
      border-radius: 5px;
      padding: 10px;
      overflow: auto;
      .MainImgWrap {
        display: flex;
        flex-wrap: wrap;
        .imgActive {
          width: 60px;
          height: 60px;
          border-radius: 5px;
          margin-right: 10px;
          margin-bottom: 10px;
          border: 1px solid $pink-color;
        }
        .imgDeactive {
          width: 60px;
          height: 60px;
          border-radius: 5px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.5;
          border: none !important;
          cursor: pointer;
        }
        .VideoWrap {
          position: relative;
          video {
            width: 60px;
            height: 60px;
            border-radius: 5px;
            margin-right: 10px;
            margin-bottom: 10px;
            object-fit: cover;
          }
          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-62%, -75%);
            img {
              width: 25px;
              height: 25px;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
  .UploadContent {
    display: flex;
    max-width: 535px;
    width: 100%;
    margin-bottom: 20px;
    @include smallmobile {
      flex-wrap: wrap;
    }
    .UploadWrap {
      width: 100%;
      margin-right: 20px;
      @include smallmobile {
        margin-right: unset;
        margin-bottom: 20px;
      }
      &:last-child {
        margin-right: 0px;
      }
      p {
        font-size: 16px;
        font-family: $Poppins-SemiBold;
        line-height: 14px;
        color: $light-black-color;
      }
      .MainWrap {
        label {
          width: 100%;
          .PhotoUpload {
            width: 100%;
            height: 170px;
            border: none;
            background-color: $btn-color;
            border-radius: 5px;
            padding: 10px;
            overflow: auto;
            @include flex;
            .AllImgWrap {
              display: flex;
              flex-wrap: wrap;
            }
          }
          .DisplayAfterImageUpload {
            width: 100%;
            height: 170px;
            border: none;
            background-color: $btn-color;
            border-radius: 5px;
            padding: 10px;
            overflow: auto;
            .AllImgWrap {
              display: flex;
              flex-wrap: wrap;
              .UploadImg {
                position: relative;
                img {
                  width: 60px;
                  height: 60px;
                  border-radius: 5px;
                  margin-right: 10px;
                  margin-bottom: 10px;
                }
                span {
                  cursor: pointer;
                  position: absolute;
                  top: -3px;
                  right: 12px;
                  svg {
                    background-color: $White-color;
                    border-radius: 50%;
                    padding: 3px;
                  }
                }
              }
              .AfterUploadImageIcon {
                width: auto;
                height: auto;
                margin-right: 10px;
                margin-bottom: 10px;
              }
            }
          }
        }
        input {
          padding: 15px;
          border-radius: 5px;
          background-color: $btn-color;
          border: none;
          width: 100%;
        }
        input:focus {
          outline: none;
        }
      }
    }
  }
}

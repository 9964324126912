@import "../../App.scss";
.LoaderWrap {
  height: 80vh;
  width: 100%;
  @include flex;
}
.FiledPaymentSection {
  padding: 80px 0px;
  .FiledPaymentContent {
    width: 100%;
    max-width: 985px;
    h1 {
      font-size: 32px;
      font-family: $Poppins-SemiBold;
      margin-bottom: 20px;
      @include mobile {
        font-size: 26px;
        -ms-word-break: break-word;
        word-break: break-word;
        white-space: pre-wrap;
      }
    }
    p {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 5px;
      -ms-word-break: break-word;
      word-break: break-word;
      white-space: pre-wrap span {
        color: $pink-color;
      }
    }
    span {
      color: $pink-color;
      cursor: pointer;
    }
    .FromSection {
      width: 100%;
      max-width: 600px;
      margin: 20px 0px;
      .InputFildBox {
        p {
          font-size: 16px;
          font-weight: 400;
          margin: 0;
          margin-bottom: 10px;
          color: $light-black-color;
        }
        input {
          border: none;
          background-color: $btn-color;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 400;
          color: $primaryBlack-color !important;
          padding: 15px 20px;
          width: 100%;
          margin-bottom: 10px;
        }
        input:focus {
          outline: none;
        }
        textarea {
          border: none;
          background-color: $btn-color;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 400;
          color: $primaryBlack-color !important;
          padding: 15px 20px;
          width: 100%;
          margin-bottom: 10px;
          min-height: 200px;
          max-height: 200px;
          resize: none;
        }
        textarea:focus {
          outline: none;
        }
      }
      .flexInput {
        display: flex;
        width: 100%;
        margin-top: 15px;
        @include xxsMobile {
          flex-wrap: wrap;
        }
        .InputFildBox {
          width: 50%;
          @include xxsMobile {
            width: 100%;
          }
        }
        .InputFildBox:last-child {
          margin-left: 20px;
          @include xxsMobile {
            margin-left: unset;
          }
        }
      }
      button {
        margin-top: 15px;
        background-color: $pink-color;
        color: $White-color;
        padding: 20px 0px;
        min-width: 200px;
        border: none;
        border-radius: 5px;
      }
    }
  }
}

// brokerDashboard.module.scss
@import "../../App.scss";

// Add these variables at the top
$mainpink: #DF1881;
$subpink: #FF5FB3;
$maingray: #CECECE;
$subgray: #6E7072;

$startpointmobile: 769px;
$startpointtablet: 991px;
$medpointtablet: 1199px;
$smallscreen: 1200px;
$mediumscreen: 1400px;

main {
  // float: left;
  // width: 100%;
  // position: relative;
  background-color: #ffffff;
}

.container {
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  box-sizing: border-box;
  margin: 0px auto;
  position: relative;
  z-index: 5;

  @media screen and (min-width: 576px) {
    max-width: 540px;
  }

  @media screen and (min-width: 768px) {
    max-width: 720px;
  }

  @media screen and (min-width: 992px) {
    max-width: 960px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 1140px;
  }

  @media screen and (min-width: 1400px) {
    max-width: 1320px;
  }
}

.subheader {
  padding-top: 80px;
  padding-bottom: 10px;
  background-color: #F6F6F6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Add shadow effect
  position: relative; // Add this to ensure shadow is visible
  z-index: 1;

  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 81px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 55px;
    flex-wrap: wrap;
  }

  .buttons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    .button {
      border-radius: 9999px;
      padding: 10px 18px;
      background: $maingray;
      color: $subgray;
      font-size: 14px;
      font-family: $Poppins-Medium;
      font-weight: 600;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      gap: 8px;
      transition: background 0.3s ease, color 0.3s ease;

      i {
        font-size: 1.2em;
      }

      &:hover {
        background: $mainpink;
        color: #fff;
      }
    }
  }

  .search {
    position: relative;
    width: 100%;
    max-width: 350px;

    input {
      width: 100%;
      height: 40px;
      border-radius: 9999px;
      padding: 12px 20px;
      padding-right: 48px;
      font-size: 1em;
      font-weight: 600;
      border: 1px solid #6e7072;
      outline: none;
      min-width: 300px;
      box-sizing: border-box;
      transition: all 0.3s ease;

      &::placeholder {
        font-weight: 300;
      }
    }

    .searchButton {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 40px;
      background: $mainpink;
      color: #fff;
      border: none;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1em;
      cursor: pointer;
      transition: background 0.3s ease;

      &:hover {
        background: #b11366;
      }
    }
  }
}

.eyecandy {
  position: relative;
  // aspect-ratio: 32/20;
  border-top: 1px solid #6e7072;

  .sliderWrapper {
    height: 100%;
    width: 100%;
    overflow: hidden;
    touch-action: pan-y pinch-zoom;
    user-select: none;

    .lightSlider {
      list-style: none;
      padding: 0;
      margin: 0;
      height: 100%;
      display: flex;
      width: 100%;

      li {
        height: 100%;
      }
    }
  }

  .image {
    // width: 100%;
    // height: 100%;
    // position: relative;
    // background-image: url(../../assets/images/house.jpg);
    // background-repeat: no-repeat;
    width: 100%;
    height: 500px;
    // background-image: url(../../assets/images/house.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    // img {
    //     width: 100%;
    //     height: 100%;
    //     object-fit: cover;
    // }

    .container {
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);

      .content {
        width: 100%;
        max-width: 600px;
        background: rgba(255, 255, 255, .45);
        backdrop-filter: blur(20px);
        border-radius: 16px;
        padding: 25px 35px;
        margin: 14px;

        h2 {
          margin: 0 0 15px;
          // font-size: 1.5em;
          color: #d31775;
          font-size: clamp(30px, 1.5vw, 32px);
          font-weight: 700;
        }

        p {
          margin: 0 0 20px;
          color: #414142;
        }

        .pinkbutton {
          cursor: pointer;
          display: inline-block;
          padding: 10px 20px;
          background: #df1881;
          color: #fff;
          text-decoration: none;
          border-radius: 9999px;
          font-weight: 600;
          transition: background 0.3s ease;

          &:hover {
            background: #b11366;
          }
        }
      }
    }
  }

}

.bigButtons {
  position: absolute;
  left: 50%;
  bottom: 10%;
  transform: translate(-50%, 100%);
  // left: 50%;
  // transform: translate(-50%, 50%);
  background: #fff;
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  padding: 23px;
  display: flex;
  // flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  max-width: 90%;
  width: fit-content;
  align-items: center;

  .bigbutton {
    flex: 1 1 calc(25% - 15px);
    border-radius: 16px;
    display: flex;
    align-items: center;
    padding: 25px 20px;
    background: $mainpink;
    color: #ffffff;
    font-weight: 800;
    font-size: 16.8px;
    line-height: 20px;
    transition: background 0.3s ease;
    width: 210px;
    display: flex;
    gap: 30px;
    height: 90px;

    // &:hover {
    //     color: #df1881;
    // }
  }

  // HousingCampaigns.scss
  $primary-color: #E6007E;
  $secondary-color: #f5f5f5;
  $text-color: #333;
  $border-radius: 8px;
  $spacing-unit: 1rem;

  // .housing-campaigns {
  //     padding: $spacing-unit * 2;

  //     .header {
  //         display: flex;
  //         justify-content: space-between;
  //         align-items: center;
  //         margin-bottom: $spacing-unit * 2;

  //         h2 {
  //             font-size: 1.5rem;
  //             color: $text-color;
  //         }

  //         .actions {
  //             display: flex;
  //             gap: $spacing-unit;

  //             .btn {
  //                 padding: 0.5rem 1rem;
  //                 border-radius: $border-radius;
  //                 text-decoration: none;
  //                 font-weight: 500;
  //                 transition: all 0.2s ease;

  //                 &-primary {
  //                     background-color: $primary-color;
  //                     color: white;
  //                 }

  //                 &-secondary {
  //                     background-color: $secondary-color;
  //                     color: $text-color;
  //                 }
  //             }
  //         }
  //     }

  //     .campaigns-grid {
  //         display: grid;
  //         grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  //         gap: $spacing-unit * 1.5;
  //         margin-bottom: $spacing-unit * 3;
  //     }

  //     .campaign-card {
  //         background: white;
  //         border-radius: $border-radius;
  //         overflow: hidden;
  //         box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  //         text-decoration: none;
  //         color: $text-color;
  //         transition: transform 0.2s ease;

  //         &:hover {
  //             transform: translateY(-2px);
  //         }

  //         &.new-campaign {
  //             background: $secondary-color;
  //             display: flex;
  //             align-items: center;
  //             justify-content: center;
  //             min-height: 200px;

  //             .new-campaign-content {
  //                 text-align: center;

  //                 .icon {
  //                     font-size: 2rem;
  //                     margin-bottom: $spacing-unit;
  //                 }

  //                 p {
  //                     color: $text-color;
  //                     font-weight: 500;
  //                 }
  //             }
  //         }

  //         .image-container {
  //             position: relative;

  //             img {
  //                 width: 100%;
  //                 height: 200px;
  //                 object-fit: cover;
  //             }

  //             .stats {
  //                 position: absolute;
  //                 bottom: $spacing-unit;
  //                 left: $spacing-unit;
  //                 display: flex;
  //                 gap: 0.5rem;

  //                 .stat-badge {
  //                     background: rgba(255, 255, 255, 0.9);
  //                     padding: 0.25rem 0.5rem;
  //                     border-radius: $border-radius;
  //                     font-size: 0.875rem;

  //                     .value {
  //                         font-weight: 600;
  //                         margin-right: 0.25rem;
  //                     }

  //                     .label {
  //                         color: rgba($text-color, 0.7);
  //                     }
  //                 }
  //             }
  //         }

  //         .card-footer {
  //             padding: $spacing-unit;
  //             display: flex;
  //             justify-content: space-between;
  //             align-items: center;
  //             border-top: 1px solid $secondary-color;

  //             .social-icons {
  //                 display: flex;
  //                 gap: 0.5rem;

  //                 .icon {
  //                     opacity: 0.7;
  //                 }
  //             }

  //             .manage-btn {
  //                 padding: 0.25rem 0.75rem;
  //                 border-radius: $border-radius;
  //                 background: $secondary-color;
  //                 border: none;
  //                 cursor: pointer;
  //                 font-size: 0.875rem;

  //                 &:hover {
  //                     background: darken($secondary-color, 5%);
  //                 }
  //             }
  //         }
  //     }

  //     .promo-banner {
  //         background: $primary-color;
  //         border-radius: $border-radius;
  //         padding: $spacing-unit * 2;
  //         display: flex;
  //         align-items: center;
  //         justify-content: space-between;
  //         color: white;
  //         overflow: hidden;
  //         position: relative;

  //         .promo-content {
  //             max-width: 50%;
  //             z-index: 1;

  //             h3 {
  //                 font-size: 1.5rem;
  //                 margin-bottom: $spacing-unit;
  //             }

  //             p {
  //                 opacity: 0.9;
  //             }
  //         }

  //         .promo-image {
  //             position: absolute;
  //             right: -5%;
  //             bottom: -10%;
  //             width: 40%;
  //             transform: rotate(-15deg);

  //             img {
  //                 width: 100%;
  //                 height: auto;
  //             }
  //         }
  //     }

  //     @media (max-width: 768px) {
  //         padding: $spacing-unit;

  //         .header {
  //             flex-direction: column;
  //             gap: $spacing-unit;
  //             align-items: flex-start;
  //         }

  //         .campaigns-grid {
  //             grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  //         }

  //         .promo-banner {
  //             flex-direction: column;
  //             text-align: center;
  //             padding: $spacing-unit * 1.5;

  //             .promo-content {
  //                 max-width: 100%;
  //                 margin-bottom: $spacing-unit * 2;
  //             }

  //             .promo-image {
  //                 position: relative;
  //                 right: 0;
  //                 bottom: 0;
  //                 width: 80%;
  //                 margin: 0 auto;
  //                 transform: rotate(-5deg);
  //             }
  //         }
  //     }
  // }
}

.parentBlock {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 28px;
  width: 100%;

  .propertyCard {
    width: 100%;
    max-width: none; // Remove max-width constraint
    height: 330px;
    border-radius: 25px;
    box-shadow: 0px 4px 22px 0px #0000001F;
    background-color: #fff;

    .cardImage {
      // width: 326px;
      height: 230px;
      border-radius: 8px;
      background-image: url(https://cdn-icons-png.freepik.com/256/5323/5323572.png?semt=ais_hybrid);
      width: calc(100% - 20px); // Adjust width to be relative to parent
      height: 230px;
      border-radius: 8px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      margin: 10px;
      position: relative;

      .bigButtons {
        position: absolute;
        left: 50%;
        bottom: 34%;
        transform: translate(-50%, 100%);
        border-radius: 0;
        box-shadow: none;
        // left: 50%;
        // transform: translate(-50%, 50%);
        // padding: 0px;
        display: flex;
        gap: 6px;
        justify-content: center;
        width: 100%;
        align-items: center;
        background-color: transparent;

        .bigbutton {
          // flex: 1 1 calc(25% - 15px);
          // border: 1px solid black;
          border-radius: 5px;
          display: flex;
          align-items: center;
          padding: 10px;
          background: $mainpink;
          color: #ffffff;
          font-weight: 800;
          // width: 80px;
          display: flex;
          gap: 15px;
          padding: 2px 10px;
          height: auto;
          text-decoration: none;

          // height: 42px;
          // width: 84px;
          // height: 40px;
          .infoContainer {
            display: flex;
            // border: 2px solid black;
            gap: 5px;
            align-items: center;
          }
        }
      }

    }

    .bottomButtons {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      align-items: center;
      margin: 0px 22px;

      .leftButton {
        display: flex;
        gap: 10px;

        img {
          cursor: pointer;
        }
      }

      .rightButton {
        .editButton {
          display: flex;
          align-items: center;
          gap: 8px;
          background-color: #EAEAEA;
          border: none;
          border-radius: 50px;
          padding: 8px 20px;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: darken(#EAEAEA, 5%);
          }

          img {
            width: 18px;
            height: 18px;
          }

          span {
            color: #4A4A4A;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }

  }
}


// styles.scss

// Variables
$primary-color: #E91E63;
$secondary-color: #333;
$border-radius: 8px;
$card-spacing: 20px;

.campaignListing {

  padding-top: 90px;
  padding-bottom: 50px;
  width: 100%; // Changed from 94%
  // max-width: 1200px; // Add max-width    margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  justify-content: center;

  .sectionTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px;

    h3 {
      font-size: 1.8em;
      color: $subpink;
      font-weight: 700;
      line-height: 1.3 rem;
    }

    .actionButtons {
      display: flex;
      gap: 12px;

      .actionBtn {
        padding: 8px 16px;
        border-radius: 20px;
        border: 1px solid #ddd;
        padding: 10px 18px;
        background: $maingray;
        color: $subgray;
        font-size: 1em;
        font-weight: 600;
        transition: background .3s ease, color .3s ease;
        cursor: pointer;

        &:hover {
          background: #f5f5f5;
        }
      }
    }
  }

  .bannersAdded {
    margin-top: 80px;

    img {
      border-radius: 25px;
      width: 100%;
      max-width: 1595px;
      height: 346px; // Fixed height
      margin-bottom: 60px;
      object-fit: cover; // Ensures image covers the area without distortion
      object-position: center; // Centers the image within the container
    }

    @media (max-width: 1600px) {
      img {
        height: 300px; // Slightly smaller height for smaller screens
      }
    }

    @media (max-width: 1200px) {
      img {
        height: 250px; // Even smaller height for mobile devices
      }
    }
  }
}

// .property-grid {
//     display: grid;
//     grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
//     gap: $card-spacing;
//     margin-bottom: 40px;
// }

// .add-campaign {
//     background: #f5f5f5;
//     border-radius: $border-radius;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     min-height: 200px;
//     cursor: pointer;
//     transition: background 0.2s;

//     &:hover {
//         background: #ebebeb;
//     }

//     .rocket-icon {
//         font-size: 32px;
//         margin-bottom: 12px;
//     }
// }

// .property-card {
//     border-radius: $border-radius;
//     overflow: hidden;
//     box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
//     background: white;

//     .property-image {
//         height: 200px;
//         background-size: cover;
//         background-position: center;
//         position: relative;

//         .property-stats {
//             position: absolute;
//             bottom: 12px;
//             left: 12px;
//             display: flex;
//             gap: 8px;

//             .stat-badge {
//                 background: $primary-color;
//                 color: white;
//                 padding: 4px 12px;
//                 border-radius: 16px;
//                 font-size: 14px;
//             }
//         }
//     }

//     .property-footer {
//         padding: 12px;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;

//         .social-icons {
//             display: flex;
//             gap: 8px;

//             .icon-btn {
//                 width: 32px;
//                 height: 32px;
//                 border-radius: 50%;
//                 border: 1px solid #ddd;
//                 background: white;
//                 cursor: pointer;
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;

//                 &:hover {
//                     background: #f5f5f5;
//                 }
//             }
//         }

//         .view-btn {
//             padding: 6px 16px;
//             border-radius: 16px;
//             background: #f5f5f5;
//             border: none;
//             cursor: pointer;

//             &:hover {
//                 background: #ebebeb;
//             }
//         }
//     }
// }

// .promo-banner {
//     background: $primary-color;
//     border-radius: $border-radius;
//     padding: 32px;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     color: white;

//     .promo-content {
//         h2 {
//             font-size: 24px;
//             margin-bottom: 12px;
//         }
//     }

//     .magazine-mockup {
//         width: 200px;

//         img {
//             width: 100%;
//             height: auto;
//             transform: rotate(-15deg);
//         }
//     }
// }

// Responsive adjustments
// @media (max-width: 768px) {
//     .property-grid {
//         grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
//     }

//     .promo-banner {
//         flex-direction: column;
//         text-align: center;

//         .magazine-mockup {
//             margin-top: 24px;
//         }
//     }
// }
.addCampaign {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F5F5F5;
  border-radius: 25px;
  padding: 40px 20px;
  cursor: pointer;
  transition: background-color 0.2s;
  gap: 15px;
  text-align: center;
  // width: calc(33.33% - 19px); // Match propertyCard width
  max-width: 367px;
  height: 330px;
  /* Make it square */
}

.addCampaign:hover {
  background-color: #EBEBEB;
}

.rocketIcon {
  font-size: 40px;
  color: #666666;
  /* If you want to use an actual icon image instead of emoji: */
  /* width: 40px;
    height: 40px; */
}

.addCampaign p {
  color: #666666;
  font-size: 18px;
  margin: 0;
}

//   .sliderWrapper {
//     width: 100%;
//     overflow: hidden;
//     touch-action: pan-y pinch-zoom;
//     user-select: none;
// }

// .lightSlider {
//     display: flex;
//     width: 100%;
//     padding: 0;
//     margin: 0;
//     list-style: none;
// }

// .active {
//     border: 2px solid #007bff;
// }

// /* Prevent text selection during drag */
// .sliderWrapper * {
//     user-select: none;
//     -webkit-user-drag: none;
// }

// /* Optional: Add smooth transition for non-dragging state */
// .lightSlider {
//     will-change: transform;
// }
.active {
  border: 2px solid #007bff;
}

/* Prevent text selection during drag */
.sliderWrapper * {
  user-select: none;
  -webkit-user-drag: none;
}

/* Optional: Add smooth transition for non-dragging state */
.lightSlider {
  will-change: transform;
}

// Add these media queries for small screens
@media screen and (max-width: 450px) {
  .subheader {
    // border: 2px solid black;
    padding-top: 25px;

    .container {
      .search {
        input {
          min-width: 100%; // Adjust from 300px to 100%
        }
      }

      .buttons {
        .button {
          font-size: 0.9em; // Smaller font size
          padding: 8px 14px; // Reduced padding
        }
      }
    }
  }

  .campaignListing {
    padding: 120px 10px 30px; // Reduced padding
    margin-top: 40px;

    .sectionTitle {
      margin: 12px; // Reduced margin
      flex-direction: column;
      gap: 15px;

      h3 {
        margin-top: 0; // Reset margin to prevent additional spacing
      }

      .actionButtons {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .actionBtn {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  .parentBlock {
    grid-template-columns: 1fr; // Single column for small screens
    gap: 20px;

    .propertyCard {
      height: auto; // Allow height to adjust based on content
      min-height: 330px;

      .cardImage {
        height: 200px; // Slightly reduced height

        .bigButtons {
          bottom: 20%;

          .bigbutton {
            padding: 2px 8px;
            font-size: 0.9em;

            .infoContainer {
              gap: 3px;

              img {
                width: 16px;
                height: 20px;
              }
            }
          }
        }
      }

      .bottomButtons {
        margin: 10px;
        flex-direction: column;
        gap: 15px;

        .leftButton {
          justify-content: center;
        }

        .rightButton {
          width: 100%;

          .editButton {
            width: 100%;
            justify-content: center;
          }
        }
      }
    }

    .addCampaign {
      height: auto;
      min-height: 250px;
      padding: 15px;

      .rocketicon {
        img {
          max-width: 80%;
          height: auto;
        }
      }

      p {
        font-size: 1.1em;
      }
    }
  }

  .eyecandy {
    .sliderWrapper {
      .image {
        height: 400px; // Reduced height for mobile
      }

      .content {
        padding: 15px;
        height: 43ch; // Added fixed height for mobile

        overflow-y: auto h2 {
          font-size: 1.5em;
        }

        p {
          font-size: 0.9em;
        }

        .pinkbutton {
          font-size: 1em;
          padding: 10px 15px;
        }
      }
    }

    .bigButtons {
      padding: 15px;
      grid-template-columns: 1fr; // Stack buttons vertically
      width: 90%;

      .bigbutton {
        width: 100%;
        height: auto;
        min-height: 70px;
        padding: 15px;

        img {
          width: 40px;
        }

        span {
          font-size: 14px;
        }
      }
    }
  }

  .bannersAdded {
    margin-top: 40px;

    img {
      height: auto;
      min-height: 200px;
      margin-bottom: 30px;
    }
  }
}
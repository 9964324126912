@import "../../../App.scss";
.ConfirmationWap {
	text-align: center;
	padding: 30px;
	h5 {
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 20px;
	}
	p {
		font-size: 14px;
		font-weight: 400;
	}
	.ConfirmationBtnWrap {
		button {
			background-color: transparent;
			border-radius: 5px;
			padding: 10px 20px;
			// border: 1px solid $userListing-color;
			// color: $userListing-color;
			border: 1px solid $light-black-color;
			width: 80px;
			&:last-child {
				margin-left: 20px;
			}
		}
	}
}
.UsedEmailWrap {
	text-align: center;
	padding: 20px;
	h5 {
		font-size: 16px;
		font-weight: 500;
	}
	p {
		font-size: 14px;
		font-weight: 400;
	}
	button {
		margin-top: 15px;
		background-color: transparent;
		border-radius: 5px;
		padding: 10px 20px;
		// color: $userListing-color;
		width: 100%;
	}
}

@import "../../../App.scss";
.AccountSection {
	margin-bottom: 30px;
	box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.16);
	padding: 30px 20px;
	border-radius: 15px;
	background-color: $White-color;
	@include mobile {
		box-shadow: unset;
		padding: unset;
	}
	h2 {
		line-height: 1rem;
		background-image: url(../../../assets/icons/createAccountIcon.svg);
		background-repeat: no-repeat;
		background-position: center;
		width: 140px;
		// height: 100px;
		color: $gray-color-font;
		padding: 25px 0;
		font-family: $Poppins-Bold;
		margin-bottom: 0;
		font-size: 1rem;
		text-align: center;
		margin: 0 auto;
	}
	.LinkWrap {
		margin-top: 20px;
		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			li {
				font-size: 16px;
				font-weight: 500;
				cursor: pointer;
				margin: 15px 0px;
				img {
					margin-right: 10px;
				}
			}
			.active{
				font-size: 16px;
				font-weight: 500;
				cursor: pointer;
				color:  $pink-color;
				margin: 10px 0px;
				img {
					margin-right: 10px;
				}
			}
		}
	}
}

@import "../../../App.scss";
$mainpink: #DF1881;
$subpink: #FF5FB3;
$maingray: #CECECE;
$subgray: #6E7072;

$startpointmobile: 769px;
$startpointtablet: 991px;
$medpointtablet: 1199px;
$smallscreen: 1200px;
$mediumscreen: 1400px;

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;
    width: 100%;
    box-sizing: border-box;
    margin: 0px auto;
    position: relative;
    z-index: 5;

    .addnewitem {
        background-color: #EDEDED;
        border-radius: 25px;
        box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.22);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px;
        max-width: 380px;

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;

            svg {
                font-size: 6em;
                color: $subgray;
            }

            p {
                margin: 0;
                font-size: 1.35em;
                color: $subgray;
                font-weight: 600;
            }
        }
    }

    .item {
        width: 100%;
        height: 100%;
        padding: 20px;
        border-radius: 25px;
        box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.12);
        text-align: center;
        max-width: 380px;

        .banner_title {
            max-height: 40px;
        }

        p {
            color: $subgray;
        }


        .image {
            width: 100%;
            height: 80%;
            position: relative;
            min-height: 260.4px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                aspect-ratio: 3/2;
                border-radius: 8px;
            }

            .tags {
                position: absolute;
                bottom: 3%;
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                gap: 5px;
                width: 100%;
                justify-content: center !important;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .tag {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                    background-color: $subpink;
                    color: #ffffff;
                    padding: 5px 10px;
                    border-radius: 4px;
                    font-size: 0.9em;
                    width: 25%;
                    text-align: center;

                    svg {
                        font-size: 1.3em;
                        margin-right: 5px;
                    }

                    span {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        text-align: left;
                        width: 100%;

                        .number {
                            font-weight: bold;
                            font-size: 1.1em;
                        }

                        .label {
                            font-size: 0.9em;
                        }
                    }
                }
            }
        }

        .content {
            width: 100%;
            height: 20%;

            .flex {
                display: flex;
                gap: 25px;
                margin-bottom: 25px;
                margin-top: -10px;
                justify-content: center;

                .button {
                    border-radius: 9999px;
                    display: inline;
                    padding: 10px 18px;
                    background: $maingray;
                    color: $subgray;
                    font-size: 1em;
                    font-weight: 600;
                    transition: background 0.3s ease, color 0.3s ease;

                    svg {
                        margin-right: 5px;
                    }

                    &:hover {
                        background: $mainpink;
                        color: #ffffff;
                    }
                }

            }

            .socialicons {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 15px;

                svg {
                    font-size: 1.3em;
                    color: $subgray;
                }
            }
        }
    }

}
@import "../../../App.scss";
.PropartySearchContainer {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 800px;
  background-color: #000000;

  background-image: linear-gradient(
      261.72deg,
      rgba(217, 217, 217, 0) 27.69%,
      rgba(0, 0, 0, 0.4) 57.08%
    ),
    var(--home-image-url);
  // @include midlarge {
  //   height: px;
  // }
  @include tablet {
    height: 950px;
  }
  @include smallmobile {
    background-image: var(--home-image-url);
    height: 855px;
  }
  // @include smMobile {
  //   height: 670px;
  // }
  .BackgroundShadow {
    height: 150px;
    background: #000000;
    background: linear-gradient(
      180deg,
      #000000 -50%,
      rgba(255, 255, 255, 0) 65%
    );
  }
  .mainsearchContainerwrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    .SearchContainerwrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 5px;
      grid-row-gap: 0px;
      @include tablet {
        grid-template-columns: repeat(1, 1fr); // Stack items in one column
        .SearchContainerdynamictexts {

          order: 2; // Move this item to the second position
        }
        .SearchSection {
          order: 1; // Move this item to the first position
        }
      }
      .SearchContainerdynamictexts {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width:90%;

        @include midlarge{
          width:unset;
        }
        @include mobile{
          padding-top: 6rem;
        }
        @include tablet {
          align-items: center;
        }
        @include smallmobile{
          padding-top: 13rem;
        }
        .SearchContainerdynamictextssubheading {
          font-size: 32px;
          // font-weight: 200;
          // line-height: 82px;
          color: #ffffff;
          text-align: left;
          font-family: $Poppins-100;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          margin-bottom: unset !important;
          @include tablet {
            text-align: center;
            font-size: 24px;
          }
          @include mobile {
            font-size: 20px;
            margin-bottom: 0px;
          }
        }
        .SearchContainerdynamictextsheading {
          font-size: 64px;
          font-weight: 600;
          line-height: 73px;
          text-align: left;
          font-family: $Poppins-SemiBold;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #ffffff;
          @include above991{
            margin-bottom: 4px;
          }
          @include tablet {
            text-align: center;
            font-size: 40px;
            line-height: 28px;
          }
          @include mobile {
            font-size: 32px;
            line-height: 36px;
          }
        }
        .SearchContainerdynamictextsdetails {
          font-size: 14px;
          // font-weight: 400;
          line-height: 26px;
          text-align: left;
          font-family: $Poppins-Light;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #ffffff;
          @include tablet {
            text-align: center;
            line-height: 20px;
          }
          @include mobile {
            font-size: 10px;
          }
        }
        .searchbuttonwrapper {
          display: flex;
          gap: 20px;
          .searchbutton1 {
            padding: 10px 20px;
            background: #df1881;
            border-radius: 30px;
            border: none;
            font-family: $Poppins-Bold;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #ffffff;
            cursor: pointer;
            a{
              text-decoration: none;
              color: #ffffff;
            }
            @include mobile{
              padding: 10px 12px;
            }
          }
          .searchbutton2 {
            padding: 10px 20px;
            background: white;
            border-radius: 30px;
            border: none;
            font-family:$Poppins-300;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color:#DF1881;
            cursor: pointer;
            a{
              text-decoration: none;
              color:#DF1881;
            };
            @include mobile{
              padding: 10px 12px;
            }
          }
          @include smallmobile{
            // gap: 10px;
            flex-direction: column;
            justify-content: center;
            gap:10px;
          }
        }
      }
      .SearchSection {
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;
        height: 455px;
        @include midlarge {
          height: 410px;
        }
        @include tablet {
          height: 355px;
        }
        @include mobile {
          height: unset;
        }
        @include smallmobile{
          flex-direction: unset;
        }
        .SearchWrap {
          background-color: #ffffff;
          border-radius: 15px 15px 0px 0px;
          width: 100%;
          position: relative;
          &::before {
            // content: url(../../../assets/images/HomeCardImage.svg);
            position: absolute;
            top: -80px;
            left: 35px;
            @include tablet {
              top: -85px;
              left: 15px;
            }
            img {
              width: 100%;
              height: 100%;
            }
            @include mobile {
              display: none;
            }
          }
          .ButtonWrap {
            display: flex;
            padding: 0 20px;
            .BtnItem {
             font-family: $Poppins-Light;
              padding: 15px;
              border: none;
              border-radius: 5px;
              max-width: 90px;
              width: 100%;
              margin-right: 20px;
              color:#6E7072;
              background-color: transparent;
              transition: all 0.2s ease;
              &:hover{
                color: $pink-color;
                font-size: 18px;
              font-weight: 700;
              line-height: 27px;
              }
              @include smallmobile {
                margin: unset;
                margin-right: 10px;
              }
              
            }
            .BtnItemActive {
              font-size: 18px;
              font-weight: 700;
              line-height: 27px;
              padding: 15px;
              border: none;
              border-radius: 0px;
              max-width: 90px;
              width: 100%;
              margin-right: 20px;
              color: $pink-color;
              background-color: transparent;
              border-bottom: 2px solid $pink-color;
              @include smallmobile {
                margin: unset;
                margin-right: 10px;
              }
            }
            @include smallmobile {
              justify-content: space-between;
            }
          }
          @include smallmobile {
            // padding: 20px;
          }
          .propertyrelativewrapper{
            position: relative;
            .SearchPropertyWrapper{
              // position: absolute;
              // width: 100%;
              // /* padding: 10px; */
              // background: rgba(255, 255, 255, 0.5);
              // backdrop-filter: blur(45px);
            }
          }
        }
        .TextWrap {
          margin-top: 60px;
          margin-bottom: 30px;
          cursor: pointer;
          @include mobile {
            margin-top: 40px;
          }
          p {
            color: $White-color;
            margin: 0;
            font-size: 16px;
            font-family: $Poppins-SemiBold;
          }
        }
      }
    }
  }
}

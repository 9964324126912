.ContainerRoom {
  margin: 15px;
  height: auto;
  // p {
  //   border: 1px solid black;
  //   text-wrap: balance;
  // }
  .BedroomRnge {
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

}
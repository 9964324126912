@import '../../../App.scss';

.blogSection {
    padding: 4rem 0;
}

.headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    .title {
        font-size: 24px;
        font-weight: 500;
        color: $black-color;
        font-family: $Poppins-Medium;
    }

    .readMore {
        color: #666;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.blogGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    cursor: pointer;

    @media (min-width: 1441px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 480px) {
        grid-template-columns: 1fr;
    }
}

.blogCard {
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    padding: 8px;

    // &:hover {
    //     transform: translateY(-5px);
    // }

    .imageWrapper {
        width: 100%;
        height: 244px;
        overflow: hidden;

        img {
            width: 320px;
            height: 244px;
            object-fit: cover;
            border-radius: 8px;
        }
    }

    .content {

        h2 {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 0.75rem;
            font-family: $Poppins-SemiBold;
            color: $black-color;
            margin-top: 18px;
        }

        p {
            font-size: 14px;
            color: #6E7072;
            line-height: 26px;
            font-weight: 400;
            margin-bottom: 1rem;
            font-family: $Poppins-300;
        }

        .cardReadMore {
            display: inline-block;
            color: $black-color;
            text-decoration: none;
            font-size: 0.875rem;
            border-radius: 30px;
            border: 1px solid $black-color;
            padding: 10px 15px;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
@import "../../App.scss";
.LoaderWrap {
	height: 80vh;
	width: 100%;
	@include flex;
}
.FiledPaymentSection {
	padding: 80px 0px;
	.FiledPaymentContent {
		width: 100%;
		max-width: 985px;
		h1 {
			font-size: 32px;
			font-family: $Poppins-SemiBold;
			margin-bottom: 20px;
			@include mobile {
				font-size: 26px;
				-ms-word-break: break-word;
				word-break: break-word;
				white-space: pre-wrap;
			}
		}
		p {
			font-size: 16px;
			font-weight: 400;
			margin-bottom: 5px;
			-ms-word-break: break-word;
			word-break: break-word;
			white-space: pre-wrap span {
				color: $pink-color;
			}
		}
        img{
            width: 130px;
            height: 130px;
            margin: 20px 0px;
        }
        button {
            margin-top: 15px;
            background-color: $pink-color;
            color: $White-color;
            padding: 20px 0px;
            min-width: 200px;
            border: none;
            border-radius: 5px;
        }
	}
}

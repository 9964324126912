@import "../../../App.scss";
.FiltersSectionMain {
  padding: 30px 15px;
  border-radius: 15px;
  margin-bottom: 30px;
  background-color: $White-color;
  max-height: 1450px;
  height: 100%;
  overflow: hidden;
  &:hover {
    overflow: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background-color: #dfdfdf;
      border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #bebcbd;
      border-radius: 5px;
    }
  }

  @include mobile {
    padding: unset;
    max-height: unset;
    height: unset;
  }
  .FilterContainer {
    // max-width: 200px;
    .FilterCount {
      border-bottom: 2px solid $borderBottom-color;
      padding-bottom: 20px;
      .NumberOfFilters {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          font-size: 16px;
          font-weight: 500;
          color: $gray-color-font;
          span {
            font-size: 16px;
            font-weight: bold;
            margin-right: 10px;
          }
        }
        .ClearFilter {
          font-size: 16px;
          font-weight: 500;
          color: $gray-color-font;
          cursor: pointer;
        }
      }
      button {
        width: 100%;
        background-color: $gray-color-font;
        border: none;
        border-radius: 5px;
        color: $White-color;
        padding: 20px 0px;
      }


      .FilterButton {
        
       display: none;

        @include mobile {
          display: block;
          width: 100%;
          background-color: $pink-color;
          border: none;
          border-radius: 5px;
          color: $White-color;
          padding: 20px 0px;
          margin-top: 1rem;
        }

      }

    }
    .FilterWrap {
      border-bottom: 2px solid $borderBottom-color;
      padding: 20px 0px;
      display: flex;
      justify-content: space-between;
      .arrowWrap {
        cursor: pointer;
      }
      h5 {
        font-size: 16px;
        font-family: $Poppins-SemiBold;
        margin: 0px;
        cursor: pointer;
      }
      .inputbox {
        margin: 10px 0px;
        display: flex;
        align-items: center;
        span {
          font-weight: 400;
          font-size: 16px;
          color: $primaryBlack-color;
          margin: 0;
        }
        .radioCustom {
          opacity: 0;
          position: absolute;
        }
        .radioCustom,
        .radioCustomLabel {
          display: inline-block;
          vertical-align: middle;
          margin-right: 7px;
          cursor: pointer;
        }
        .radioCustomLabel {
          position: relative;
        }
        .radioCustom + .radioCustomLabel:before {
          content: "";
          background: #fff;
          border: 1.5px solid #595f72;
          display: inline-block;
          vertical-align: middle;
          border-radius: 2px;
          padding: 7px;
          text-align: center;
        }
        .radioCustom + .radioCustomLabel:before {
          border-radius: 5px;
        }
        .radioCustom:checked + .radioCustomLabel:before {
          background: url(../../../assets/icons/RightIcon.svg);
          background-size: 10px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
    .searchDescription {
      padding: 20px 0px;
      h5 {
        font-size: 16px;
        font-weight: 400;
      }
      input {
        margin-top: 10px;
        padding: 5px 10px;
        width: 100%;
        border-radius: 10px;
        border: 1px solid $borderBottom-color;
      }
    }
  }
}

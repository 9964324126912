@import "../../../App.scss";
.ListingSideBar {
  margin-bottom: 30px;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.16);
  padding: 30px 20px;
  border-radius: 15px;
  background-color: $White-color;
  @include mobile {
    box-shadow: unset;
    padding: unset;
  }
  .TypeOfListingWrap {
    border-bottom: 2px solid $borderBottom-color;
    margin-bottom: 20px;
    h5 {
      font-size: 16px;
      font-family: $Poppins-SemiBold;
    }
    .inputbox {
      margin: 10px 0px;
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        font-weight: 400;
        font-size: 16px;
        color: $primaryBlack-color;
        -ms-word-break: break-word;
        word-break: break-word;
        white-space: pre-wrap;
      }
      .radioCustom {
        opacity: 0;
        position: absolute;
      }
      .radioCustom,
      .radioCustomLabel {
        display: inline-block;
        vertical-align: middle;
        margin: 5px;
        cursor: pointer;
      }
      .radioCustomLabel {
        position: relative;
      }
      .radioCustom + .radioCustomLabel:before {
        content: "";
        background: #fff;
        border: 1.5px solid #595f72;
        display: inline-block;
        vertical-align: middle;
        border-radius: 2px;
        padding: 7px;
        text-align: center;
      }
      .radioCustom + .radioCustomLabel:before {
        border-radius: 5px;
      }
      .radioCustom:checked + .radioCustomLabel:before {
        background: url(../../../assets/icons/RightIcon.svg);
        background-size: 10px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
  .PriceRange {
    h5 {
      font-size: 16px;
      font-family: $Poppins-SemiBold;
    }
    .inputBox {
      position: relative;
      width: 100%;
      margin-bottom: 10px;
      input {
        background-color: $btn-color;
        color: $black-color;
        border-radius: 5px;
        border: none;
        padding: 15px 10px;
        width: 100%;
        @include smallmobile {
          margin-bottom: 10px;
        }
      }
    }
  }
}

@import "../../App.scss";
.dream-homes {
  width: 100%;
  .hero {
    padding: 100px 0px; 
    background:
      url("../../assets/images/abroadheroimage.png");
    background-size: cover;
    background-position: center;
    mix-blend-mode: multiply;
    display: flex;
    justify-content: center;
    align-items: center;
    .hero-content {
      width: 90%;
      max-width: 880px;
      text-align: center;
      h1 {
        font-family: $Poppins-SemiBold;
        font-size: 64px;
        line-height: 73px;
        color: white;
        @include tablet{
            font-size: 48px;
            line-height: 56px;
        }
        @include mobile{
            font-size: 32px;
            line-height: 38px;
        }
      }
      .hero-subtext{
        font-family: $Poppins-Light;
        font-size: 32px;
        font-weight: 100;
        text-align: center;
        color: white;
        @include tablet{
            font-size: 24px;
        }
        @include mobile{
            font-size: 16px;
        }
      }
      .hero-search-wrapper{
        display: flex;
        justify-content: center;
          .search-bar {
            position: relative;
            width: 100%;
            max-width: 600px;
    
            input {
              width: 100%;
              padding: 1rem;
              padding-right: 160px;
              border: none;
              border-radius: 50px;
              font-size: 1rem;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              transition: all 0.3s;
              &::placeholder {
                color: #999;
              }
              &:focus {
                outline: none;
                border-color: #DF1881 !important;
                box-shadow: 0 4px 8px rgba(255, 0, 208, 0.1);
              }
            }
    
            button {
              position: absolute;
              right: 0px;
              top: 49%;
              height: 100%;
              transform: translateY(-49%);
              padding: 15px 35px;
              background-color: #DF1881;
              color: white;
              border: none;
              border-radius: 50px;
              font-weight: bold;
              font-size: 18px;
              cursor: pointer;
              transition: background-color 0.2s;
              &:hover {
                background-color: darken(#DF1881, 5%);
              }
            }
          }
      }
    }
  }

  .cards-wrapper {
    display: flex;
    justify-content: center;
    .destinations {
      margin-top: -60px;
      display: grid;
      grid-template-columns: var(--grid-template-columns, repeat(3, 1fr));
      gap: 2rem;
      padding: 2rem 0px;
      .no-data-message {
        width: 100%;
        text-align: center;
        padding: 2rem;
        font-size: 1.2rem;
        color: #666;
      }
      &.single-card {
        .destination-card {
          width: 100%;
          // Remove the full height stretch but maintain aspect ratio
          aspect-ratio: 16 / 9; // You can adjust this ratio as needed
          margin: 0 auto; // Center the card
          max-width: 800px; // Optional: limit maximum width
        }
        @include mobile{
          grid-template-columns: repeat(1,1fr);
        }
      }

      .destination-card {
        position: relative;
        border-radius: 24px;
        overflow: hidden;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s;
        background: white;
        aspect-ratio: 1 / 1;

        &:hover {
          transform: translateY(-4px);

          .card-image img {
            transform: scale(1.1);
          }
        }

        .card-image {
          height: 100%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.3s;
          }
        }

        .card-content {
          padding: 1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgba(255, 255, 255, 0.1);
          backdrop-filter: blur(10px);
          border-top: 1px solid rgba(255, 255, 255, 0.2);

          h3 {
            margin: 0;
            font-size: 1.25rem;
            color: white;
            text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
          }

          .more-info {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: none;
            background-color: white;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background-color 0.2s;

            &:hover {
              background-color: rgba(255, 255, 255, 0.3);
            }

            svg {
              width: 32px;
              height: 32px;
              color: #df1881 !important;
            }
          }
        }
      }
      @include tablet{
        grid-template-columns: repeat(2,1fr);
      }
      @include smallmobile{
        grid-template-columns: repeat(1,1fr);
      }
    }
  }
}

@media (max-width: 768px) {
  .dream-homes {
    .hero {
      padding: 50px 0px;

      .hero-content {

        .search-bar {
          flex-direction: column;

          button {
            // width: 100%;
          }
        }
      }
    }
  }
}

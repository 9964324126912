@import"../../../App.scss";
.headercontainerMain{
    padding: 21px 50px; 
    background-color: white;
    @include tablet{
        padding: 21px 10px;
    }
    @include midmobile{
        padding: 10px 10px;
        background-color: unset;
    } 
    @include xxsMobile{
        padding: 10px 20px;
        background-color: unset;
    }
}
@import "../../App.scss";
.VerificationPage {
  height: 50vh;
  @include flex;
  flex-direction: column;
  .VerificationContainer {
    text-align: center;
    h3 {
      font-size: 40px;
      margin-bottom: 30px;
      color: $primaryBlack-color !important;
      // span {
      // 	color: $pink-color;
      // }
      &::after {
        content: " .";
        animation: dots 2s steps(5, end) infinite;
      }

      @keyframes dots {
        0%,
        20% {
          color: rgba(0, 0, 0, 0);
          text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        40% {
          color: $pink-color;
          text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        60% {
          color: $pink-color;
          text-shadow: 0.25em 0 0 $pink-color, 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        80%,
        100% {
          color: $pink-color;
          text-shadow: 0.25em 0 0 $pink-color, 0.5em 0 0 $pink-color;
        }
      }
    }
  }
  .verified {
    font-size: 40px;
    color: $green-color;
    img {
      width: 35px;
      height: 35px;
    }
  }
  .Invalid {
    font-size: 40px;
    color: $red-color;
    img {
      width: 35px;
      height: 35px;
    }
  }
}

@import "../../../App.scss";
.BoxMain {
  // -webkit-backdrop-filter: blur(10px);
  // backdrop-filter: blur(10px);
  // background-color: rgba(255, 255, 255, 0.5);
  position: relative;
  z-index: 1;
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .MainWrap {
    // display: flex;
    padding: 20px 20px 30px 20px;
    width: 100%;
    border-radius: 0px 0px 24px 24px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(7px);
    &.isHomePage {
      position: absolute;
      z-index: 2;
    }
    @include tablet {
      flex-wrap: wrap;
    }
    @include mobile {
      padding: 17px 10px 17px 10px;
    }
    @include smallmobile {
      padding: 17px 8px 17px 8px;
    }
    .LeftSide {
      position: relative;
      width: 100%;
      // margin-right: 10px;
      margin-bottom: 20px;
      @include tablet {
        width: 100%;
        margin-right: unset;
      }
      .SearchBox {
        @include flex;
        .searchInput {
          background-color: $btn-color;
          color: $black-color;
          border-radius: 5px;
          border: none;
          padding: 15px 10px;
          width: 100%;
          -webkit-appearance: none;
          @include smallmobile {
            margin-right: 10px;
          }
        }
        .selectHomeBgColor {
          color: $black-color;
          border-radius: 80px;
          border: none;
          padding: 10px 10px;
          margin-left: 10px;
          width: 120px;
          -webkit-appearance: none;
          background-color: #f0f0f0;
          // border: 2px solid #A1A1A1;
          background-image: url(../../../assets/icons/DropdownIcon.svg);
          background-position: right 15px center;
          background-repeat: no-repeat;
          &:focus {
            outline: none;
            box-shadow: none; // Removes black border
          }
          option {
            border-radius: 12px;
            padding: 8px 10px;
          }
          @include smallmobile {
            margin: unset;
          }
        }
        .selectBgColor {
          color: $black-color;
          border-radius: 5px;
          border: none;
          padding: 15px;
          margin-left: 10px;
          width: 100%;
          -webkit-appearance: none;
          background-color: red;
          background-image: url(../../../assets/icons/DropdownIcon.svg);
          background-position: right 15px center;
          background-repeat: no-repeat;
          @include smallmobile {
            margin: unset;
          }
        }
        @include tablet {
          margin-bottom: 20px;
        }
        // @include smallmobile {
        //   display: unset;
        // }
      }
      .LatestSearch {
        display: flex;
        margin-top: 20px;

        span {
          margin-right: 10px;
          font-size: 16px;
          font-weight: 500;
          color: $primaryBlack-color;
        }
        p {
          font-size: 16px;
          font-family: $Poppins-SemiBold;
          color: $black-color;
          margin: 0;
        }
        @include tablet {
          flex-wrap: wrap;
          margin: 20px 0px !important;
        }
      }
    }
    .RigthSide {
      width: 100%;
      @include tablet {
        width: 100%;
      }
      .PriceRangeHome {
        margin-bottom: 10px;
        margin-top: -34px;
        @include tablet {
          margin-top: 20px;
        }
        p {
          font-size: 16px;
          font-weight: 500;
          color: $black-color;
          margin: 0;
        }
      }
      .PriceRange {
        margin-top: 20px;
        p {
          font-size: 16px;
          font-weight: 500;
          color: $black-color;
          margin: 0;
        }
      }
      .PriceRangeBox {
        display: flex;
        align-items: center;
        @include smallmobile {
          display: unset;
        }
        .PriceRangeWrap {
          display: flex;
          margin-right: 10px;
          width: 70%;
          @include smallmobile {
            width: 100%;
            margin-bottom: 15px;
          }
          .inputBox {
            width: 100%;
            margin-right: 10px;
            input {
              background: #f0f0f0;
              border-radius: 100px;
              color: $black-color;
              border: none;
              padding: 10px 5px 10px 10px;
              width: 100%;
              &:focus {
                outline: none;
                box-shadow: none;
                border: none;
              }
            }
          }
          :last-child {
            margin-right: unset;
          }
        }
        .inputBox {
          position: relative;
          width: 30%;
          @include smallmobile {
            width: 100%;
          }
          button {
            padding: 10px 5px 10px 5px;
            background: #df1881;
            border-radius: 100px;
            border: none;
            text-align: center;
            color: $White-color;
            width: 100%;
          }
          img {
            position: absolute;
            display: none;
            top: 21px;
            right: 10px;
          }
        }
      }
    }
  }
}

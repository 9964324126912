@import "../../App.scss";
.LoaderMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.ViewListingMain {
  padding-top: 50px;
  padding-bottom: 50px;
  .ImgSection {
    .ListingImages {
      display: flex;
      @include tablet {
        flex-wrap: wrap;
      }
      .MainImg {
        width: 50%;
        margin-right: 10px;
        @include tablet {
          width: 100%;
          margin-right: unset;
          margin-bottom: 10px;
        }
        .MainImgWrap {
          width: 100%;
          height: 410px;
          cursor: pointer;
          @include smallmobile {
            height: 210px;
          }
          position: relative;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .UnderOption {
            top: 10px;
            left: 10px;
            padding: 5px;
            position: absolute;
            font-size: 11px;
            border-radius: 5px;
            color: $White-color;
            background-color: $pink-color;
          }
        }
      }
      .OtherImg {
        width: 50%;
        @include tablet {
          width: 100%;
          margin-bottom: 10px;
        }
        .OtherImgWarp {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 10px;
          grid-row-gap: 10px;
          .ImgWrap {
            width: 100%;
            height: 200px;
            cursor: pointer;
            @include smallmobile {
              height: 120px;
            }
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    p {
      font-size: 16px;
      font-weight: 300;
      color: $btn-text-color;
      margin: 10px 0px;
      span {
        font-size: 16px;
        font-weight: 300;
        color: $black-color;
      }
    }
  }
  .ListingDetailsSection {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    box-shadow: 3px 3px 10px 5px rgba(0, 0, 0, 0.16);
    border-radius: 15px;
    padding: 20px;
    .ListingDetail {
      @include mobile {
        width: 100%;
      }
      h1 {
        font-size: 32px;
        font-weight: 400;
        margin: 0;
        margin-bottom: 10px;
      }
      p {
        font-size: 16px;
        font-family: $Poppins-SemiBold;
        margin: 0;
        margin-bottom: 10px;
      }
      .PropertySize {
        display: flex;
        margin-bottom: 10px;
        @include xxsMobile {
          flex-wrap: wrap;
        }
        .Size {
          @include flex;
          margin-right: 20px;
          img {
            width: 26px;
            height: 20px;
            margin-right: 10px;
          }
          p {
            font-size: 16px;
            font-weight: 300;
            color: $sizetext-color;
            margin: 0;
          }
        }
        .Capacity {
          @include flex;
          margin-right: 20px;
          img {
            width: 26px;
            height: 20px;
            margin-right: 10px;
          }
          p {
            font-size: 16px;
            font-weight: 300;
            color: $sizetext-color;
            margin: 0;
          }
        }
        .SquareMeterIcon {
          @include flex;
          @include xxsMobile {
            margin-top: 10px;
          }
          img {
            width: 26px;
            height: 20px;
            margin-right: 10px;
          }
          p {
            font-size: 16px;
            font-weight: 300;
            color: $sizetext-color;
            margin: 0;
          }
        }
      }
      .PriceWarp {
        display: flex;
        p {
          font-size: 16px;
          font-weight: 400;
        }
      }
      .lable {
        background-color: #829002;
        padding: 10px;
        border-radius: 5px;
        font-size: 16px;
        color: $White-color;
        text-align: center;
        max-width: 65px;
        min-width: 65px;
        margin-bottom: 10px;
      }
      .NoteWrap {
        background-color: #f6f6f6;
        padding: 20px;
        border-radius: 5px;
        width: 100%;
        p {
          color: $sizetext-color;
          text-align: center;
          font-size: 16px;
          font-style: italic;
          font-weight: 300;
          margin: 0;
          width: 400px;
          -ms-word-break: break-all;
          word-break: break-all;
          white-space: pre-wrap;
          @include mobile {
            width: unset;
          }
        }
      }
    }
    .ListingOrgImg {
      @include flex;
      @include mobile {
        width: 100%;
        margin: 30px 0px;
      }
      .OrgMgWrap {
        margin-right: 15px;
        width: 100px;
        height: 100px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .OrgContent {
        h5 {
          font-size: 16px;
          font-family: $Poppins-SemiBold;
          margin: 0;
        }
        a {
          text-decoration: unset;
          font-size: 16px;
          font-weight: 400;
          margin: 0;
          color: $black-color;
        }
      }
    }
    .MapSection {
      max-width: 280px;
      width: 100%;
      @include tablet {
        max-width: unset;
      }
    }
  }
  .viewListingImageContainer {
    display: flex;
    justify-content: space-between;
    .ImgWrap {
      margin: 10px;
    }
  }
  .SubListingDetailSection {
    margin-top: 50px;
    margin-bottom: 30px;
    .DescriptionAndContactWrap {
      display: flex;
      margin-bottom: 30px;
      flex-wrap: wrap;
      @include mobile {
      }
      .DescriptionWrap {
        width: 70%;

        @include mobile {
          width: 100%;
        }
        h6 {
          font-size: 16px;
          font-family: $Poppins-SemiBold;
          margin: 0;
          margin-bottom: 15px;
          color: $gray-color-font;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          margin: 0;
          max-width: 80%;
          @include mobile {
            width: 100%;
            max-width: unset;
          }
        }
        .ReadOrHide {
          color: $pink-color;
          text-align: center;
          margin-top: 10px;
          cursor: pointer;
        }
      }
      .ContactWrap {
        width: 30%;
        @include mobile {
          width: 100%;
          margin-top: 30px;
        }
        h6 {
          font-size: 16px;
          font-family: $Poppins-SemiBold;
          margin: 0;
          margin-bottom: 10px;
          color: $gray-color-font;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: $gray-color-font;
          margin: 0;
          margin-bottom: 10px;
        }
        .UserInfoWrap {
          display: flex;
          align-items: center;
          img {
            width: 14px;
            height: 14px;
            margin-right: 10px;
            margin-bottom: 10px;
          }
          a {
            text-decoration: unset;
            font-size: 16px;
            font-weight: 400;
            color: $gray-color-font;
            margin: 0;
            margin-bottom: 10px;
          }
        }
      }
    }
    .FeaturesWrap {
      margin-bottom: 50px;
      h6 {
        font-size: 16px;
        font-family: $Poppins-SemiBold;
        margin: 0;
        margin-bottom: 15px;
        color: $gray-color-font;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        margin-bottom: 15px;
        max-width: 80%;
      }
      .AskingPrice {
        margin-bottom: 15px;
        p {
          font-size: 16px;
          font-weight: 400;
          margin: 0;
          margin-bottom: 5px;
          color: $gray-color-font;
        }
        .PriceWarp {
          display: flex;
          p {
            font-size: 16px;
            font-weight: 300;
            &:last-child {
              margin-left: 30px;
            }
          }
        }
      }
      .PricePerM {
        margin-bottom: 15px;
        p {
          font-size: 16px;
          font-weight: 300;
          margin: 0;
          margin-bottom: 5px;
          color: $gray-color-font;
        }
      }
      .DateWrap {
        margin-bottom: 15px;
        p {
          font-size: 16px;
          font-weight: 300;
          margin: 0;
          margin-bottom: 5px;
          color: $gray-color-font;
        }
      }
    }
    .ActivitySection {
      h6 {
        font-size: 16px;
        font-family: $Poppins-SemiBold;
        margin: 0;
        margin-bottom: 15px;
        color: $gray-color-font;
      }
      .ActivityWrap {
        display: flex;
        .ListingActivity {
          display: flex;
          align-items: center;
          margin-right: 30px;
          img {
            width: 22px;
            height: 22px;
            margin-right: 10px;
          }
          p {
            font-size: 13px;
            font-weight: 500;
            color: $gray-color-font;
            margin: 0;
          }
        }
      }
      .Goback {
        margin-top: 30px;
        span {
          cursor: pointer;
          font-size: 16px;
          font-family: $Poppins-SemiBold;
          margin: 0;
        }
      }
    }
  }
}

@import "../../App.scss";
.CreateOrgAccountMain {
  padding: 50px 0px;
  h1 {
    font-size: 32px;
    font-family: $Poppins-SemiBold;
    margin-bottom: 30px;
    @include mobile {
      font-size: 26px;
      -ms-word-break: break-word;
      word-break: break-word;
      white-space: pre-wrap;
    }
  }
  .FormTab {
    max-width: 600px;
    width: 100%;
    margin-bottom: 20%;
    .SectionPart {
      margin-bottom: 40px;
      h3 {
        font-size: 18px;
        font-family: $Poppins-SemiBold;
        margin: 20px 0px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        margin-bottom: 10px;
        color: $light-black-color;
      }
      .InputFildBox {
        margin-bottom: 20px;
        p {
          font-size: 16px;
          font-weight: 400;
          margin: 0;
          margin-bottom: 10px;
          color: $light-black-color;
        }
        input {
          border: none;
          background-color: $btn-color;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 400;
          color: $primaryBlack-color !important;
          padding: 15px 20px;
          width: 100%;
        }
        input:focus {
          outline: none;
        }
        .uploadMain {
          label {
            width: 100%;
            .PhotoUpload {
              width: 100%;
              height: 170px;
              border: none;
              background-color: $btn-color;
              border-radius: 5px;
              @include flex;
              .ImgWarp {
                width: 100px;
                height: 100px;
              }
              // img{
              //   width: 100px;
              //   height: 100px;
              // }
            }
          }
        }
        select {
          border: none;
          background-color: $btn-color;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 400;
          color: $primaryBlack-color !important;
          padding: 15px 20px;
          width: 100%;
          -webkit-appearance: none;
          background-image: url(../../assets/icons/DropdownIcon.svg);
          background-position: right 15px center;
          background-repeat: no-repeat;
        }
      }
      .flexInput {
        display: flex;
        width: 100%;
        margin-top: 15px;
        @include xxsMobile {
          flex-wrap: wrap;
        }
        .InputFildBox {
          width: 50%;
          @include xxsMobile {
            width: 100%;
          }
        }
        .InputFildBox:last-child {
          margin-left: 20px;
          @include xxsMobile {
            margin-left: unset;
          }
        }
      }
    }
    .PayNow {
      background-color: $btn-color;
      color: $black-color;
      padding: 20px 0px;
      min-width: 200px;
      border: none;
      border-radius: 5px;
    }
    .ActivePayNow {
      background-color: $pink-color;
      color: $White-color;
      padding: 20px 0px;
      min-width: 200px;
      border: none;
      border-radius: 5px;
    }
  }
}
